import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import { string } from 'yup';
import { topicChildren, topicListForStream } from '../../models/LocalLists';
const TagStats: FC = () => {
  let [topic, setTopic] = useState<string>('');
  let [stats, setStats] = useState<any>([[]]);
  let [xAxisTags, setXAxisTags] = useState<any>([]);
  let [error, setError] = useState<string>('');
  const getStats = () => {
    let xAxisTagsIn = topicChildren('JEE-NEET', topic);
    let yAxisTagsIn = ['cet', 'neet', 'mains', 'advanced', 'olympiads']
    setXAxisTags(xAxisTagsIn);
    console.log(xAxisTagsIn)
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/vital-stats/tags', { xAxisTags: xAxisTagsIn, yAxisTags: yAxisTagsIn }).then((reply) => {
      if (reply?.data) {
        setStats(reply.data)
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      Select Topic <select onChange={(event) => {
        const value = event.target.value;
        setTopic(value);
      }}>
        <option>--select--</option>
        {topicListForStream['JEE-NEET']['physics'].children.map((topic: string) => {
          return <option value={topic}>{topic}</option>
        })}
      </select>
      <button className='btn btn-primary' onClick={getStats}>Get Stats</button>
      <h3 className='text-center m-5'>No. of Question in a topic</h3>
      <hr />
      <table className='table table-bordered'>
        <thead>
          <td></td><td>CET</td><td>Neet</td><td>Mains</td><td>Advanced</td><td>Olympiads</td>
        </thead>
        <tbody>
          {xAxisTags.map((xAxis : any, index: number)=>{
            return <tr>
              <td>{xAxis}</td>
              {stats[index]?.map((yAxis : any, index1: number)=>{
                return <td>{yAxis}</td>
              })}
            </tr>
          })}
        </tbody>
      </table>
    </div >
  )
}

export { TagStats }
