import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { Modal } from 'react-bootstrap';

type ApiParams = { batchId: string };
export const Fees = () => {
  const { batchId } = useParams<ApiParams>();
  let [batch, setBatch] = useState<any>({});
  let [users, setUsers] = useState<any>([]);
  let [currentUser, setCurrentUser] = useState<string>('');
  let [batchFees, setBatchFees] = useState<number>(30000);
  let [batchFeesEdit, setBatchFeesEdit] = useState<boolean>(false);
  let [showModal, setShowModal] = useState<boolean>(false);
  let [userFees, setUserFees] = useState<any>({});
  let [success, setSuccess] = useState<string>('');
  let [error, setError] = useState<string>('');
  let validationSchema = Yup.object({
    users: Yup.string()
      .test(
        'needValidEmailList',
        'Enter comma separated list of of valid emails',
        function (items) {
          let isValid = true;
          items?.split(',').filter(item => item).forEach((item) => {
            if (!item.toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )) isValid = false;
          })
          return isValid;
        }
      ),
  });
  const updateFees = () => {
    let value = (document.getElementById('fees') as HTMLInputElement).value;
    let newUserFees = { ...userFees }
    if (!newUserFees[currentUser]) {
      newUserFees[currentUser] = {};
      newUserFees[currentUser].fees = [];
    }
    newUserFees[currentUser].fees.push({ pay: parseFloat(value), date: new Date().toDateString() })
    newUserFees[currentUser].totalPaid = newUserFees[currentUser].fees.reduce((a: any, b: any) => { return a + b.pay }, 0)
    console.log(newUserFees)
    setUserFees(newUserFees)
    setShowModal(false)
  }
  const initializePage = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatch(reply.data);
        //DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
    axios.get(process.env.REACT_APP_API_URL + '/batch-users/' + batchId).then((reply) => {
      if (reply?.data) {
        setUsers(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  useEffect(() => {
    initializePage();
  }, [])
  return (
    <div className='mt-1 bg-light shadow p-3'>
      <h3 className='text-center'>{batch?.name} - Fees</h3>
      <div>
        <div className='ps-5 p-2'>
          <h4 className='d-inline'>Batch Fees</h4>
          {batchFeesEdit && <span><input className='ms-2' type='text' onChange={(e: any) => { setBatchFees(e.target.value) }}></input><button onClick={() => { setBatchFeesEdit(!batchFeesEdit) }} className='btn btn-dark p-2 ms-1'>Save</button></span>}
          {!batchFeesEdit && <span><h4 className='d-inline ms-2'>- ₹{batchFees}</h4><button onClick={() => { setBatchFeesEdit(!batchFeesEdit) }} className='btn btn-dark p-2 ms-1'>Edit</button></span>}
        </div>
        <div className='row m-4 p-2 '>
          <div className='col-7'></div>
          <div className='col-2'><strong>Paid</strong></div>
          <div className='col-2'><strong>Remaining</strong></div>
        </div>
        {
          users?.map((user: any) => {
            return <div className='m-4 p-2 pb-6 shadow rounded row' style={{ fontSize: '14px', fontWeight: 'bold' }}>
              <div className='col-7'>{user.name}</div>
              <div className='col-2'>{userFees[user._id]?.totalPaid ? userFees[user._id].totalPaid : 0}</div>
              <div className='col-2'>{batchFees - (userFees[user._id]?.totalPaid ? userFees[user._id].totalPaid : 0)}</div>
              <div className='col-1'><button className='btn btn-info' onClick={() => { setCurrentUser(user._id); setShowModal(true) }}>Add</button></div>
            </div>
          })
        }
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content p-4">
          {userFees[currentUser]?.fees && <div className='p-2'>
            <h4 className='text-center'>Fees Paid</h4>
            <div className='row'>
              <div className='col-6'><strong>Amount</strong></div>
              <div className='col-6'><strong className='float-end'>Paid On</strong></div>
            </div>
            {
              userFees[currentUser].fees.map((f: any) => {
                return <div className='row'>
                  <div className='col-6'>{f.pay}</div>
                  <div className='col-6 float-end'><span className='float-end'>{f.date}</span></div>
                </div>
              })
            }
          </div>}
          <div className='p-2 d-inline mt-4'>
            Add Amount <input className='ms-2' id='fees'></input><button className='btn btn-dark ms-2 p-2' onClick={() => { updateFees() }}>Save</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}