import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getUserByToken, requestPassword } from '../core/_requests'
import { useAuth } from '../core/Auth'
import axios from 'axios'
import { DisplayLoader } from '../../../utils/loader'
import { useParams } from 'react-router-dom'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})
type ApiParams = { token: string };
export function VerifyEmailLink() {
  const { token } = useParams<ApiParams>();
  const [state, setState] = useState('')
  const [error, setError] = useState('')
  const { saveAuth, setCurrentUser, currentUser, logout } = useAuth()
  useEffect(() => {
    DisplayLoader(true, 'Please wait while we are verifying')
    axios.get(process.env.REACT_APP_API_URL + '/verify-email-verification-link/' + token).then((data) => {
      if (data?.data?.error) {
        setError(data.data.error);
        setState('resendlink');
      } else if (data?.data?.message) {
        setState('success')
      } else {
        setError("Unknown Error. Try logging in again")
      }
      DisplayLoader(false,'');
    }).catch(() => {
      setError("Unknown Error. Try logging in again")
      DisplayLoader(false,'');
    })
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      DisplayLoader(true, '')
      setError('');
      try {
        const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/send-email-verification-link/', {email : values.email})
        if (auth.error) {
          setError(auth.error);
        } else {
          setState('linksent');
        }
        DisplayLoader(false, '')
      } catch (e) {
        setError("Unknown Error")
        DisplayLoader(false, '')
      }
    },
  })
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Title */}
      {error && error !== '' && (
        <div className='mb-lg-15 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}
      {state === 'success' &&
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Email Verified</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {'Login again to continue'}
          </div>
          {/* end::Link */}
        </div>
      }
      {state === 'resendlink' && <>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </>}
      {state === 'linksent' &&
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Verification link sent.</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {'A verification link has been sent to your registered email. Please follow the link to verify your account and continue.'}
          </div>
          {/* end::Link */}
        </div>
      }
      <button
        type='button'
        id='kt_login_signup_form_cancel_button'
        className='btn btn-lg btn-light-primary w-100 mb-5 mt-10'
        onClick={() => { logout();window.location.pathname = '/auth/login' }}
      >
        Go To Login Page
      </button>
    </form>
  )
}
