import { ChangeEvent, useState } from "react";
import { Section, ValidationSchema } from "../../models/Section";
import { ViewSection } from "../../modules/components/display/ViewSection";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { DisplayLoader } from "../../utils/loader";
import { Modal } from "react-bootstrap";
import { EditSection } from "../../modules/components/display/EditSection";
import tagMap from "./tagObject";
import { setsEqual } from "chart.js/dist/helpers/helpers.core";
import { useParams } from "react-router-dom";
type ApiParams = { id: string };
export const CreateSectionsFromJSON = () => {
  const { id } = useParams<ApiParams>();
  let [sections, setSections] = useState<any>([]);
  let [allSectionTags, setAllSectionTags] = useState<any>([]);
  let [currentSection, setCurrentSection] = useState<any>({});
  let [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  let [isEditing, setIsEditing] = useState<boolean>(false);
  let [showModal, setShowModal] = useState<boolean>(false);
  let [challengeName, setChallengeName] = useState<any>([]);
  const scrollCurrentSectionToView = () => {
    setTimeout(() => {
      (document.getElementById('section' + currentSectionIndex) as HTMLElement).scrollIntoView();
    }, 100)
  }
  const tagCount = () => {
    let count: any = {}
    console.log("here")
    sections.forEach((section: any) => {
      if (section.tags.length > 0) {
        let tag = section.tags[section.tags.length - 1]
        if (!count[tag]) count[tag] = {}
        if (!count[tag][section.type]) count[tag][section.type] = 0
        count[tag][section.type] = count[tag][section.type] + 1
      }
    })
    return count
  }
  const convertTo = (to: string, index: number, section: any) => {
    if (section.type == 'pair') {
      let newSection = { ...section }
      newSection.type = to;
      newSection.options = [];
      section.options.forEach((opt: string, index: number) => {
        if (index % 2 == 0) {
          if (!newSection.options[Math.round(index / 2)]) newSection.options[Math.round(index / 2)] = []
          newSection.options[Math.round(index / 2)][0] = opt
        } else {
          newSection.options[Math.round(index / 2) - 1][1] = opt
        }
      })
      let newSections = [...sections];
      newSections[index] = newSection;
      setSections(newSections)
    }
  }
  const addTagToAllSections = (e: any) => {
    let newSections = [...sections];
    let tags = [...allSectionTags];
    tags.push(e.target.value);
    newSections.forEach((section: any) => { section.tags = [...tags] })
    setAllSectionTags(tags)
    setSections(newSections);
  }
  const removeTagsFromAllSections = () => {
    let newSections = [...sections];
    let tags = [...allSectionTags];
    tags.pop();
    newSections.forEach((section: any) => { section.tags = [...tags] })
    setAllSectionTags(tags)
    setSections(newSections);
  }
  const addTag = (e: any, index: number, section: any) => {
    if (e.target.value == '') return
    let newSection = { ...section };
    console.log("here1")
    newSection.tags.push(e.target.value);
    let newSections = [...sections];
    newSections[index] = newSection;
    setSections(newSections)
  }
  const removeTag = (index: number, section: any) => {
    let newSection = { ...section };
    console.log("here1")
    newSection.tags.pop();
    let newSections = [...sections];
    newSections[index] = newSection;
    setSections(newSections)
  }
  const validateSection = (section: any) => {
    section.valid = true;
    if (section.type == 'text') {
      section.valid = false
    }
    if (section.type == 'mcq' || section.type == 'short') {
      if (!section.text || section.text == '' ||
        section.points == null || section.points == 0 ||
        section.options == null || section.options.length == 0 || !Array.isArray(section.options) ||
        section.answer == null || section.options[section.answer] == null) {
        section.valid = false;
      }
    }
    if (section.type == 'long') {
      if (!section.text || section.text == '' ||
        section.points == null || section.points == 0 ||
        section.answer == null || section.answer == '') {
        section.valid = false;
      }
    }
    if (section.type == 'slide') {
      if (!section.text || section.text == '') {
        section.valid = false;
      }
    }
    if (!section.valid) console.log(section.text)
    return section;
  }
  const preProcessSection = (section: any) => {
    let updatedSection: any = {}
    if (section.question_type) section.type = section.question_type
    if (section.question_text) section.question = section.question_text
    else if (section.text) section.question = section.text
    else if (section.questionText) section.question = section.questionText
    if (section.correct_answer) section.answer = section.correct_answer
    else if (section.correctAnswer) section.answer = section.correctAnswer
    updatedSection.text = section.question
    updatedSection.explanation = section.explanation;
    section.type = section.type.toLowerCase();
    if (section.type.includes('drag') && !(section.question.includes('arrange') || section.question.includes('Arrange') || section.question.includes('organize') || section.question.includes('Organize') || section.question.includes('order') || section.question.includes('Order') || section.question.includes('sequence') || section.question.includes('Sequence'))) {
      updatedSection.type = 'pair'
      updatedSection.options = [];
      let keys: any = []
      let values: any = []
      if (!section.answer && section.correct_order) {
        keys = section.items
        values = section.correct_order
      } else if (!section.answer && section.answers) {
        keys = section.answers
        values = section.options
      } else {
        if (!section.answer && section.pairs) section.answer = section.pairs
        if (Array.isArray(section.answer)) {
          section.answer = section.answer.reduce((acc: any, obj: any) => {
            return { ...acc, ...obj };
          }, {});
        }
        keys = Object.keys(section.answer)
        values = Object.values(section.answer)
      }
      updatedSection.options = keys.flatMap((key: any, index: any) => [key, values[index]]);
    } else if (section.type.includes('multiple') || section.type.includes('mcq')) {
      updatedSection.type = 'mcq'
      updatedSection.options = section.options;
      updatedSection.answer = section.options.indexOf(section.answer).toString();
    } else if (section.type.includes('sequence') || (section.type.includes('drag') && (section.question.includes('arrange') || section.question.includes('Arrange') || section.question.includes('organize') || section.question.includes('Organize')))) {
      updatedSection.type = 'verticalsequence'
      if (!section.options && (section.steps && section.correct_sequence)) {
        if (section.correct_sequence) {
          updatedSection.options = []
          section.correct_sequence.forEach((index: number) => {
            updatedSection.options.push(section.steps[index - 1]); // Subtract 1 because array indices start at 0
          });
        }
      } else if (section.correct_sequence) {
        updatedSection.options = section.correct_sequence
      } else if (section.sequence) {
        updatedSection.options = section.sequence
      } else if (section.correct_order) {
        updatedSection.options = section.correct_order
      } else if (section.steps) {
        updatedSection.options = section.steps
      } else if (!section.options && section.answer) {
        if (Array.isArray(section.answer)) {
          updatedSection.options = section.answer
        } else {
          updatedSection.options = section.answer.split(',')
        }
      } else {
        updatedSection.options = section.options;
      }
    } else if (section.type.includes('hangman')) {
      updatedSection.type = '7lives'
      updatedSection.text = section.answer;
      updatedSection.options = section.clues;
    } else if (section.type.includes('numerical')) {
      updatedSection.type = 'numerical'
      updatedSection.answer = section.answer
    } else if (section.type.includes('crossword')) {
      updatedSection = section;
      updatedSection.type = 'text'
    } else {
      updatedSection = section;
      updatedSection.type = 'text'
    }
    updatedSection.tags = []
    return processSection(updatedSection)
  }
  const processSection = (section: any) => {
    let newSection: any = {}
    newSection.ownerInstitutionId = "646dff1af4d030e48cd9680b"
    newSection.type = section.type;
    if (section.difficultyLevel) {
      newSection.difficultyLevel = section.difficultyLevel;
    }
    if (newSection.type == 'slide') {
      newSection.text = section;
      newSection.points = 0;
      return validateSection(newSection);
    }
    if (section.question) newSection.text = section.question;//.replace(/\d+. /, '');
    if (section.text) newSection.text = section.text;//.replace(/\d+. /, '');
    if (section.options) {
      if (Array.isArray(section.options)) {
        newSection.options = section.options;
      } else {
        newSection.options = Object.values(section.options).map((opt: any) => {
          return opt[opt.length - 1] == '*' ? opt.slice(0, opt.length - 1) : opt
        })
      }
    }
    if (section.group) newSection.group = section.group;
    if (section.answer) {
      if (newSection.options && newSection.options.indexOf(section.answer) != -1) {
        newSection.answer = newSection.options.indexOf(section.answer)
      } else if (newSection.options && newSection.options.indexOf(Object.values(section.answer)[0]) != -1) {
        newSection.answer = newSection.options.indexOf(Object.values(section.answer)[0]);
      } else if (newSection.options && newSection.options.indexOf(section.options[section.answer]) != -1) {
        newSection.answer = newSection.options.indexOf(section.options[section.answer]);
      } else if (newSection.options && section.options[section.answer] && section.options[section.answer][section.options[section.answer].length - 1] == '*' && newSection.options.indexOf(section.options[section.answer].slice(0, section.options[section.answer].length - 1)) != -1) {
        newSection.answer = newSection.options.indexOf(section.options[section.answer].slice(0, section.options[section.answer].length - 1));
      } else {
        newSection.answer = section.answer;
      }
    }
    if (section.answer) {
      newSection.explanation = section.explanation;
    }
    if (section.tags) {
      newSection.tags = section.tags;
    }
    if (section.source) {
      newSection.source = section.source;
    }
    if (newSection.type != 'slide') {
      newSection.points = 5;
    }
    return validateSection(newSection);
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    processFiles(files, 0, [])
  };
  const deleteSection = (sectionNo: number) => {
    let newSections = [...sections];
    newSections.splice(sectionNo, 1);
    setSections(newSections);
  };
  const setEdit = (sectionNo: number) => {
    let newSections = [...sections];
    if (!newSections[sectionNo].reviewStatus) {
      newSections[sectionNo].reviewStatus = -11;
    } else {
      delete newSections[sectionNo].reviewStatus;
    }
    setSections(newSections);
  };
  function moveElement(index: number, direction: string) {
    let array = [...sections]
    if (direction === 'up' && index > 0) {
      [array[index], array[index - 1]] = [array[index - 1], array[index]];
    } else if (direction === 'down' && index < array.length - 1) {
      [array[index], array[index + 1]] = [array[index + 1], array[index]];
    }
    setSections(array);
  }
  function addSection(index: number) {
    let array = [...sections]
    array.splice(index, 0, { tags: [], type: 'mcq' });
    setSections(array)
    setIsEditing(true); setCurrentSection(array[index]); setCurrentSectionIndex(index)
  }
  const saveSection = (values: any) => {
    let newSections = [...sections]
    newSections[currentSectionIndex] = values
    setIsEditing(false)
    scrollCurrentSectionToView();
    setSections(newSections);
  }
  const processFiles = (files: FileList, i: number, newSectionList: any) => {
    if (i == files.length) { setSections(newSectionList); console.log(newSectionList); return; }
    const file = files[i];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        let content: any = event.target?.result as string;
        let newSections: Section[] = []
        let nameAdd = ''
        /*
        switch (file.name.split('-')[1].split('.')[0]) {
          case 'slide':
            nameAdd = ' - Notes';
            break;
          case 'mcq':
            nameAdd = ' - Multiple Choice Questions (MCQs)';
            break;
          case 'short':
          case 'long':
            nameAdd = ' - Short Answer Questions';
            break;
        }
        */
        setChallengeName(file.name + nameAdd)
        //let updatedTags = ['NCERT', 'CBSE', '10th', 'Science'];
        //updatedTags.push(file.name.split('-')[0])
        //setTags(updatedTags)

        content = JSON.parse(content)
        content = Array.isArray(content) ? content : content.questions;
        content.forEach((c: any) => {
          let s = preProcessSection(c)
          if (s.valid != true) {
            s.type = 'text';
          } else {
            newSections.push(s);
          }
        })

        console.log(newSections.length + ":" + content.length);
        newSectionList = [...newSectionList, ...newSections]
        processFiles(files, i + 1, newSectionList)
      };
      reader.readAsText(file);
    }
  }
  const upload = () => {
    DisplayLoader(true, '')
    let input: any = { challengeName: challengeName, sections: sections };
    input.begin = (document.getElementById('beggining') as HTMLInputElement).checked;
    if (id) {
      input.id = id;
    }
    axios.post(process.env.REACT_APP_API_URL + '/bulk-upload/', input).then((reply) => {
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
    })
  }
  return (
    <>
      <button type="button" className="btn btn-primary m-2 p-2" style={{ position: 'fixed', top: '200px', left: '0px' }} onClick={() => { setShowModal(true); }}>C</button>
      <div style={{ display: isEditing ? 'none' : 'block' }}>
        <div className="ms-2 me-2">
          <input type="file" id="fileInput" onChange={handleChange} multiple></input>
        </div>
        <div className="text-center">{challengeName}</div>
        {/*
      <div>Tags - {tags.map((tag: string) => {
        return <span>{tag},</span>
      })}</div>
      <input type="text" id="tag" placeholder="New Tag"></input><button className="btn btn-dark" onClick={() => { addTag() }}>Add</button>*/}
        Add at the beggining <input id='beggining' type="checkbox"></input>
        Total Questions - <b>{sections.length}</b>
        {JSON.stringify(allSectionTags)}<button onClick={() => { removeTagsFromAllSections() }}>X</button>
        <select onChange={(e: any) => { addTagToAllSections(e); e.target.value = '' }}>
          <option value={''}>Select....</option>
          {Object.keys(allSectionTags.reduce((obj: any, key: any) => obj[key], tagMap)).map((k: any) => {
            return <option value={k}>{k}</option>
          })}
        </select>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={ValidationSchema({}, true)} initialValues={{}}
          onSubmit={() => { }}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {sections?.map((section: Section, index: number) => {
                return <>
                  <div className="mb-8" id={'section' + index}>
                    <strong>Section No {index + 1}</strong>
                    <button type="button" className="btn btn-primary m-2 p-2" onClick={() => { setIsEditing(true); setCurrentSection(section); setCurrentSectionIndex(index) }}>Edit</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { deleteSection(index) }}>Delete</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { addSection(index); }}>Add</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { moveElement(index, 'down') }}>Down</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { moveElement(index, 'up') }}>Up</button>
                  </div>
                  <div>Tags - {section.tags?.map((tag: string) => {
                    return <span>{tag},</span>
                  })}<button onClick={() => { removeTag(index, section) }}>X</button>
                    <select onChange={(e: any) => { addTag(e, index, section); e.target.value = '' }}>
                      <option value={''}>Select....</option>
                      {Object.keys(section.tags.reduce((obj: any, key: any) => obj[key], tagMap)).map((k: any) => {
                        return <option value={k}>{k}</option>
                      })}
                    </select>
                  </div>
                  {section.type == 'pair' && <select onChange={(e: any) => { convertTo(e.target.value, index, section) }}>
                    <option value={''}>Select...</option>
                    <option value={'dragdrop'}>Drag and Drop</option>
                    <option value={'matrix'}>Match</option>
                  </select>}
                  {section.type != 'text' && ViewSection(section, false, 1, errors, values, setFieldValue, section, true)}
                  {section.type == 'text' && JSON.stringify(section)}
                  <hr />
                </>
              })}
            </Form>
          )}
        </Formik>
        <button className="btn btn-success" onClick={() => { upload() }}>Upload</button>
      </div>
      <div style={{ display: isEditing ? 'block' : 'none' }}>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={ValidationSchema({}, true)} initialValues={currentSection}
          onSubmit={saveSection}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { setFieldValue('type', e.target.value) }}>
                <option value="">Choose the challenge type</option>
                <option value="slide">Slide</option>
                <option value="mcq">Multiple Choice Question (MCQ)</option>
                <option value="multiple">Multiple Choice Question with Multiple Answers</option>
                <option value="numerical">Numerical</option>
                <option value="short">Short Text</option>
                <option value="long">Long Text</option>
                <option value="pair">Pair Match</option>
                <option value="blanks">Fill in the blanks</option>
                <option value="matrix">Matrix Match</option>
                <option value="click">Click</option>
                <option value="crossword">Crossword</option>
                <option value="7lives">7 Lives Game</option>
                <option value="dragdrop">Drag and Drop</option>
                <option value="verticalsequence">Sequence (Vertical)</option>
                <option value="horizontalsequence">Sequence (Horizontal)</option>
              </Field>
              {EditSection(values, errors, setFieldValue)}
              <button type="button" onClick={() => { setIsEditing(false); scrollCurrentSectionToView(); }} className="btn btn-primary">Back</button>
              <button type="submit" className="btn btn-primary float-end">Save</button>
            </Form>)}
        </Formik>
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content p-4">{JSON.stringify(tagCount())}</div>
      </Modal>
    </>
  )
}