import { FC, useState } from 'react'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Field, Form, Formik, FormikValues } from 'formik'
import { DisplayLoader } from '../../utils/loader'
import axios from 'axios'
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay'


const CFALReports: FC = () => {

    const getReports = () => {
        DisplayLoader(true, '')

        axios.get(process.env.REACT_APP_API_URL + '/cfal-reports/').then((reply) => {
            console.log(reply.data)
            const blob = new Blob([reply.data], { type: 'text/csv' });

            // Create a link element for downloading
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.csv'); // File name for download

            // Append link to the body and click it to trigger download
            document.body.appendChild(link);
            link.click();

            // Clean up: remove the link and revoke the object URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            DisplayLoader(false, '')
        }, (error) => {
            //reject(error)//TODO
            DisplayLoader(false, '')
        });
    }
    return (
        <div className='text-center'>
            <h1>Reports</h1>
            <button type='button' className='btn btn-primary' onClick={getReports}>Get Reports</button>
        </div>
    )
}

export { CFALReports }
