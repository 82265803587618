export function generateOtp(username:string) {
  if(!username || username == '') return -1
  // Get the current time rounded to the nearest hour in milliseconds
  const currentTime = Math.round(Date.now() / 3600000);
  console.log(currentTime)
  // Combine the username and current time
  const combinedString = `${username}${currentTime}`;

  // Generate a unique number by iterating over the string characters
  let otpSeed = 253781;
  for (let i = 0; i < combinedString.length; i++) {
      otpSeed += combinedString.charCodeAt(i)*otpSeed;
  }

  // Create a 6-digit OTP by performing modulo operation
  const otp = otpSeed % 1000000;

  // Ensure the OTP is 6 digits by padding with leading zeros if necessary
  return otp.toString().padStart(6, '0');
}