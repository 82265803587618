import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Importer, ImporterField } from 'react-csv-importer';
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import * as Yup from 'yup'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';

type ApiParams = { batchId: string; sectionNo: string };
export const TimeTable = () => {
  const { batchId } = useParams<ApiParams>();
  let [showAddSession, setShowAddSession] = useState<boolean>(false);
  let [showUpdateSession, setShowUpdateSession] = useState<boolean>(false);
  let [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);
  let [showSendMessages, setShowSendMessages] = useState<boolean>(false);
  let [sessionEditingStatus, setSessionEditingStatus] = useState<string>('view');
  let [sessions, setSessions] = useState<any>([]);
  let [currentSession, setCurrentSession] = useState<any>({});
  let [batch, setBatch] = useState<any>({});
  let [managers, setManagers] = useState<any>([]);
  let [error, setError] = useState<string>('');
  let [uploadError, setUploadError] = useState<string>('');
  let validationSchema = Yup.object({
    name: Yup.string().required().label('Title'),
    manager: Yup.string().required().label('Host'),
    date: Yup.date().required().label('Date'),
    startTimeH: Yup.number().lessThan(12).moreThan(-1).required().label('Hour should be a number between 0 and 11'),
    startTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 0 and 59'),
    endTimeH: Yup.number().lessThan(12).moreThan(-1).required().label('Hour should be a number between 0 and 11'),
    endTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 0 and 59'),
  });
  const saveSession = (values: FormikValues) => {
    setError('');
    values.startTime = new Date(new Date(values.date).getTime() + (parseInt(values.startTimeH) + (values.startTimeAMPM === 'PM' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.startTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    values.endTime = new Date(new Date(values.date).getTime() + (parseInt(values.endTimeH) + (values.endTimeAMPM === 'PM' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.endTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    if (values.startTime > values.endTime) {
      setError('End time should be after start time')
      return;
    }
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/add-session/' + batchId, values).then((reply) => {
      if (reply?.data) {
        setShowAddSession(false);
        sessionList();
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const bulkUpload = (values: any, index: number) => {
    let value = values[index]
    if (value['teacher'] && value['topic'] && value['date'] && value['starttime'] && value['endtime']) {
      console.log(value)
      let input: any = {}
      input.manager = [value.teacher]
      input.name = value.topic;
      input.startTime = new Date(new Date(value.date).getTime() + (parseInt(value.starttime.split(":")[0])) * 60 * 60 * 1000 + (parseInt(value.starttime.split(":")[1])) * 60 * 1000);
      input.endTime = new Date(new Date(value.date).getTime() + (parseInt(value.endtime.split(":")[0])) * 60 * 60 * 1000 + (parseInt(value.endtime.split(":")[1])) * 60 * 1000);
      if (input.startTime > input.endTime) {
        setUploadError((uploadError === '' ? 'There is an error in lines - ' : (uploadError + ',')) + (index + 1));
        if (index < values.length - 1) {
          bulkUpload(values, index + 1)
        }
        return
      }
      axios.post(process.env.REACT_APP_API_URL + '/add-session/' + batchId, input).then((reply) => {
        if (reply?.data) {
          setShowAddSession(false);
          sessionList();
        } else {
          //TODO
        }
        if (index < values.length - 1) {
          bulkUpload(values, index + 1)
        }
      }).catch(() => {
        setUploadError((uploadError === '' ? 'There is an error in lines - ' : (uploadError + ',')) + (index + 1));
        if (index < values.length - 1) {
          bulkUpload(values, index + 1)
        }
      })
    } else {
      setUploadError((uploadError === '' ? 'There is an error in lines - ' : (uploadError + ',')) + (index + 1));
      if (index < values.length - 1) {
        bulkUpload(values, index + 1)
      }
    }

  }
  const updateSession = (values: FormikValues) => {
    setError('');
    values.startTime = new Date(new Date(values.date).getTime() + (parseInt(values.startTimeH) + (values.startTimeAMPM === 'PM' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.startTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    values.endTime = new Date(new Date(values.date).getTime() + (parseInt(values.endTimeH) + (values.endTimeAMPM === 'PM' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.endTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    if (values.startTime > values.endTime) {
      setError('End time should be after start time')
      return;
    }
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/update-session/' + values.id, values).then((reply) => {
      if (reply?.data) {
        setShowUpdateSession(false);
        sessionList();
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const deleteSession = (values: FormikValues) => {
    setError('');
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/delete-session/' + values.id, values).then((reply) => {
      if (reply?.data) {
        setShowUpdateSession(false);
        sessionList();
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  useEffect(() => {
    batchInit();
    sessionList();
    managerList();
  }, [])
  const batchInit = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-min/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatch(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const sessionList = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/session-list/' + batchId).then((reply) => {
      if (reply?.data) {
        setSessions(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const managerList = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/manager-list/').then((reply) => {
      if (reply?.data) {
        setManagers(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  return (
    <div className='mt-1 bg-light shadow p-3' style={{ maxWidth: '630px', margin: "auto" }}>
      <h3 className='text-center mb-20'>{batch ? (batch.name + ' - ') : ''}Time Table
        <div className='d-block'>
          <button className='float-end m-3 btn btn-primary p-2' onClick={() => { setShowBulkUpload(true) }}>Bulk Upload</button>
          <a href={'/attendance-report/'+batchId} className='float-end m-3 btn btn-primary p-2'>Attendance Report</a>
        </div>
      </h3>
      <div className='d-block'>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView='timeGridWeek'
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,dayGridDay'
          }}
          events={
            sessions.map((session: any) => {
              let newSession: any = {}
              newSession.start = session.startTime;
              newSession.end = session.endTime;
              newSession.manager = session.sessionManagers[0];
              newSession.attendanceTaken = session.attendanceTaken;
              newSession.title = session.name;
              newSession.id = session._id;
              if (session.attendanceTaken) {
                newSession.classNames = ['bg-success']
              } else {
                newSession.classNames = ['bg-danger']
              }
              return newSession;
            })
          }
          eventClick={function (info) {
            console.log(info.event.start ? (info.event.start.getFullYear() + '-' + ((info.event.start.getMonth() + 1) < 10 ? ('0' + (info.event.start.getMonth() + 1)) : ((info.event.start.getMonth() + 1))) + '-' + info.event.start.getDate()) : '')
            setCurrentSession({
              date: info.event.start ? (info.event.start.getFullYear() + '-' + ((info.event.start.getMonth() + 1) < 10 ? ('0' + (info.event.start.getMonth() + 1)) : ((info.event.start.getMonth() + 1))) + '-' + ((info.event.start.getDate() < 10 ? ('0' + info.event.start.getDate()) : info.event.start.getDate()))) : '',
              startTimeH: info.event.start?.getHours(),
              startTimeM: info.event.start?.getMinutes(),
              endTimeH: info.event.end?.getHours(),
              endTimeM: info.event.end?.getMinutes(),
              name: info.event.title,
              id: info.event.id,
              manager: info.event.extendedProps.manager,
              attendanceTaken: info.event.extendedProps.attendanceTaken
            })
            setShowUpdateSession(true);
            setSessionEditingStatus('view');
            /*
            info.jsEvent.preventDefault(); // don't let the browser navigate
        
            if (info.event.url) {
              window.open(info.event.url);
            }
            */
          }}
          dateClick={function (info) {
            console.log(info.dateStr.split('T')[0])
            setCurrentSession({
              date: info.dateStr.split('T')[0], startTimeH: new Date(info.dateStr).getHours(), startTimeM: new Date(info.dateStr).getMinutes()
              , endTimeH: new Date(info.dateStr).getHours() + 1, endTimeM: new Date(info.dateStr).getMinutes()
            })
            setShowAddSession(true)
          }}
        />
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showAddSession} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowAddSession(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Add Session</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
              validationSchema={validationSchema} initialValues={currentSession} onSubmit={saveSession}>
              {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Title</label></div>
                  <div>
                    <Field type='text' className='form-control form-control-lg mb-1'
                      name='name' placeholder='Title' />
                    {ErrorDisplay('name', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                  <Field type='date' className='form-control form-control-lg mb-1'
                    name='date' placeholder='Title' />
                  <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                  <div>
                    <div className='col-3 col-md-2 d-inline-block me-2'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='startTimeH' placeholder='hh' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='startTimeM' placeholder='mm' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field as="select" name="startTimeAMPM" className="form-select mb-1">
                        <option value='am'>AM</option>
                        <option value='pm'>PM</option>
                      </Field>
                    </div>
                    {ErrorDisplay('startTimeH', errors)}
                    {ErrorDisplay('startTimeM', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>End Time</label></div>
                  <div>
                    <div className='col-3 col-md-2 d-inline-block me-2'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='endTimeH' placeholder='hh' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='endTimeM' placeholder='mm' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field as="select" name="endTimeAMPM" className="form-select mb-1">
                        <option value='am'>AM</option>
                        <option value='pm'>PM</option>
                      </Field>
                    </div>
                    {ErrorDisplay('endTimeM', errors)}
                    {ErrorDisplay('endTimeH', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Host</label></div>
                  <div>
                    <Field as="select" name="manager" className="form-select mb-1">
                      <option value=''>--Select--</option>
                      {managers.map((manager: any) => <option value={manager.username}>{manager.name}</option>)}
                    </Field>
                    {ErrorDisplay('manager', errors)}
                  </div>
                  {error && error !== '' && <div className='text-danger'>*{error}</div>}
                  <button className="btn btn-primary float-right">Add</button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showUpdateSession} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowUpdateSession(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Session</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            {sessionEditingStatus === 'edit' && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
              validationSchema={validationSchema} initialValues={currentSession} onSubmit={updateSession}>
              {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Title</label></div>
                  <div>
                    <Field type='text' className='form-control form-control-lg mb-1'
                      name='name' placeholder='Title' />
                    {ErrorDisplay('name', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                  <Field type='date' className='form-control form-control-lg mb-1'
                    name='date' placeholder='Title' />
                  <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                  <div>
                    <div className='col-3 col-md-2 d-inline-block me-2'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='startTimeH' placeholder='hh' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='startTimeM' placeholder='mm' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field as="select" name="startTimeAMPM" className="form-select mb-1">
                        <option value='am'>AM</option>
                        <option value='pm'>PM</option>
                      </Field>
                    </div>
                    {ErrorDisplay('startTimeH', errors)}
                    {ErrorDisplay('startTimeM', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>End Time</label></div>
                  <div>
                    <div className='col-3 col-md-2 d-inline-block me-2'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='endTimeH' placeholder='hh' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field type='text' className='form-control form-control-xs mb-1'
                        name='endTimeM' placeholder='mm' number />
                    </div>
                    <div className='col-3 col-md-2 d-inline-block'>
                      <Field as="select" name="endTimeAMPM" className="form-select mb-1">
                        <option value='am'>AM</option>
                        <option value='pm'>PM</option>
                      </Field>
                    </div>
                    {ErrorDisplay('endTimeM', errors)}
                    {ErrorDisplay('endTimeH', errors)}
                  </div>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Host</label></div>
                  <div>
                    <Field as="select" name="manager" className="form-select mb-1">
                      <option value=''>--Select--</option>
                      {managers.map((manager: any) => <option value={manager.username}>{manager.name}</option>)}
                    </Field>
                    {ErrorDisplay('manager', errors)}
                  </div>
                  {error && error !== '' && <div className='text-danger'>*{error}</div>}
                  <button className="btn btn-primary float-right">Update</button>
                  <button type='button' onClick={() => { setSessionEditingStatus('delete') }} className="btn btn-danger float-end">Remove</button>
                </Form>
              )}
            </Formik>}
            {(sessionEditingStatus === 'view' || sessionEditingStatus === 'delete') &&
              <>
                <table className='table table-bordered  m-2 mb-5'>
                  <tbody>
                    <tr>
                      <td>Name</td><td>{currentSession?.name}</td>
                    </tr>
                    <tr>
                      <td>Time</td><td>{currentSession?.startTimeH}:{currentSession?.startTimeM} {currentSession?.startTimeAMPM} - {currentSession?.endTimeH}:{currentSession?.endTimeM} {currentSession?.endTimeAMPM}</td>
                    </tr>
                    <tr>
                      <td>Status</td><td>{currentSession?.attendanceTaken ? 'Recorded' : 'Unrecorded'}</td>
                    </tr>
                  </tbody>
                </table>
                {sessionEditingStatus === 'view' && <> <a className='btn btn-success float-end' href={'/attendance/' + currentSession.id}>
                  {currentSession?.attendanceTaken ? 'Check' : 'Take'} Attendance
                </a>
                  <button className='btn btn-primary' onClick={() => { setSessionEditingStatus('edit') }}>Edit</button>
                </>
                }
                {sessionEditingStatus === 'delete' && <>
                  <h4 className='text-center'>Are you sure you want to delete this?</h4>
                  <button className='btn btn-danger' onClick={() => { deleteSession(currentSession) }}>Yes</button>
                  <button className='btn btn-success float-end' onClick={() => { setSessionEditingStatus('edit') }}>No</button>
                </>
                }
              </>
            }
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showBulkUpload} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowBulkUpload(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Time Table Bulk Upload</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            <>
              <Importer
                dataHandler={async (rows, { startIndex }) => {
                  // required, may be called several times
                  // receives a list of parsed objects based on defined fields and user column mapping;
                  // (if this callback returns a promise, the widget will wait for it before parsing more data)
                  setUploadError('')
                  bulkUpload(rows, 0)
                }}
                defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                restartable={true} // optional, lets user choose to upload another file when import is complete
                onStart={({ file, preview, fields, columnFields }) => {
                  // optional, invoked when user has mapped columns and started import
                  //prepMyAppForIncomingData();
                }}
                onComplete={({ file, preview, fields, columnFields }) => {
                  // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                  //showMyAppToastNotification();
                }}
                onClose={({ file, preview, fields, columnFields }) => {
                  // optional, if this is specified the user will see a "Finish" button after import is done,
                  // which will call this when clicked
                  //goToMyAppNextPage();
                }}

              // CSV options passed directly to PapaParse if specified:
              // delimiter={...}
              // newline={...}
              // quoteChar={...}
              // escapeChar={...}
              // comments={...}
              // skipEmptyLines={...}
              // delimitersToGuess={...}
              // chunkSize={...} // defaults to 10000
              // encoding={...} // defaults to utf-8, see FileReader API
              >
                <ImporterField name="teacher" label="Teacher" />
                <ImporterField name="topic" label="Topic" />
                <ImporterField name="date" label="Date" />
                <ImporterField name="starttime" label="Start Time" />
                <ImporterField name="endtime" label="End Time" />
              </Importer>
              {uploadError && uploadError !== '' && <div className='text-danger'>*{uploadError}</div>}
            </>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showSendMessages} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowSendMessages(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Messages Regarding Absences</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            <table className='table table-bordered'>
              <tbody>
                <tr>
                  <td>From Date</td><td><input type='date' id='startdate'></input></td>
                </tr>
                <tr>
                  <td>To Date</td><td><input type='date' id='enddate'></input></td>
                </tr>
                <tr>
                  <td>Modes of Communication</td>
                  <td>
                    <input type='checkbox' id='email'></input><span className='ps-2 pe-4'>Email</span>
                    <input type='checkbox' id='sms'></input><span className='ps-2 pe-4'>SMS</span>
                    <input type='checkbox' id='whatsapp'></input><span className='ps-2 pe-4'>Whatsapp</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button className='float-end btn btn-primary'>Send</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}