import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'

const Content = ({children}: WithChildren) => {
  const {config, classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const appContentContainer = config.app?.content?.container
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid pt-2',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
    >
      <div className='d-block d-lg-none mt-15'><div className='mt-20'></div></div>
      <div id="loader-div" style={{display: "none"}}>
        <div className="d-flex align-items-center justify-content-center" style={{ background: "#ffffff30", position: "fixed", width: "100%", left: "0px", top: "0px", height: "100%", zIndex: "10000000000" }}>
          <div className="spinner-border text-primary" role="status">
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center text-center" style={{ background: "#ffffff00", position: "fixed", width: "100%", left: "0px", top: "50px", height: "100%", zIndex: "11" }}>
          <div id="loader-text" className="text-muted fs-6 fw-semibold m-4 p-3 rounded shadow" style={{ width: "100%", background: "white" }}>Loading...</div>
        </div>
      </div>
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          className={clsx('app-container', classes.contentContainer.join(' '), {
            'container-xxl': appContentContainer === 'fixed',
            'container-fluid': appContentContainer === 'fluid',
          })}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export {Content}
