import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { topicList, topicListForStream } from '../../models/LocalLists';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      borderWidth: 1,
    },
  ],
};

export const WeightagePU = () => {
  let [topicSelectionArray, setTopicSelectionArray] = useState<any>(['CET','physics']);
  return <div>
    <select >
      {
        ['CET', 'NEET', 'JEE-Mains', 'JEE-Advanced'].map((topic) => {
          return <option value={topic}>{topic}</option>
        })
      }
    </select>
    <select >
      {
        topicList('JEE-NEET', false, false)?.filter((topic: any) => { return (topicListForStream['JEE-NEET'][topic].parent == '') }).map((topic: any) => {
          return <option value={topic}>{topic}</option>
        })
      }
    </select>
    {
      topicSelectionArray.slice(1).map((topic : string, index: number) => {

      })
    }
    <div style={{ height: "300px" }}>
      <Pie data={data} options={{ maintainAspectRatio: false }} />
    </div>
  </div>
}