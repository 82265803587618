import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import { Form, Formik } from 'formik';
import { ViewSection } from '../../modules/components/display/ViewSection';
import Latex from 'react-latex-next';
import SequenceVertical from '../../modules/components/display/displayquestiontypes/SequenceVertical';
import SequenceHorizontal from '../../modules/components/display/displayquestiontypes/SequenceHorizontal';
import DragDrop from '../../modules/components/display/displayquestiontypes/DragAndDrop';
import showAttempt from '../../utils/showAttempt';
import { Modal } from 'react-bootstrap';
type ApiParams = { id: string };
const OfflineReportsDetailed: FC = () => {
  const { id } = useParams<ApiParams>();
  let [report, setReport] = useState<any>({});
  let [showModal, setShowModal] = useState<boolean>(false);
  let [currentSection, setCurrentSection] = useState<any>({});
  let [currentAttempt, setCurrentAttempt] = useState<any>({});
  let [initValues, setInitValues] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);
  const processSections = (sectionin: any, attemptin: any) => {
    let section = {...sectionin}
    let attempt = {...attemptin}
    if ((section.type == 'blanks' || section.type == 'blanks-any') && section.text) {
      if(!section.text.match(/#blank([0-9])#/g)) return section
      const answerLength = section.text.match(/#blank([0-9])#/g).length;
      section.answer = section.text;
      for (let i = 0; i < answerLength; i++) {
        if (attempt) {
          section.text = section.text.replaceAll("#blank" + (i + 1) + "#", '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion" disabled>' + attempt.answer[i] + '</button>')
          section.answer = section.answer.replaceAll("#blank" + (i + 1) + "#", '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion" disabled>' + section.options[i] + '</button>')
        }
      }
    }
    return section
  }
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-offline-reports/' + id).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }

  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {report.batchList && <div>
        <h2 className='text-center'>{report.batchList[0].name} - {report.userDetails[report.session.teacherId]}</h2>
        <div className='text-center'><strong>{new Date(report.session.startTime).toLocaleDateString('en-GB')}</strong></div>
      </div>}
      {/* {report.batchList && <div className='pb-15'><Link to={'/class-reports-question/' + report.session._id} type='button' className='btn btn-dark p-2 m-2 float-end'><i className='fa fa-line-chart'></i> by Question</Link></div>} */}
      <div className="table-responsive p-2 rounded shadow bg-white">
        <table className="table table-bordered">
          <thead className='table-light'>
            <tr>
              <th style={{ position: 'sticky', left: 0, zIndex: 1, width: '150px', background: 'white' }}>Students</th>
              <th className="border-right-1 border-dark" style={{ position: 'sticky', zIndex: 1, left: '150px', background: 'white' }}>Total</th>
              {
                report?.session?.showOrder.map((question: any, index: number) => {
                  return <th className='text-nowrap text-center'>
                    {(() => {
                      let challenge = report.challenges.filter((c: any) => c._id == report.challengeInstances.filter((ci: any) => ci._id == question.challengeInstanceId)[0].challengeId)[0]
                      let section = report.sections.filter((section: any) => section._id == challenge.sections[question.sectionNo])[0]
                      let correct = Object.entries(report.session.groupData).filter((entry: any) => {
                        return entry[1]?.[challenge._id]?.[section._id]?.correct == true
                      })
                      let wrong = Object.entries(report.session.groupData).filter((entry: any) => {
                        return entry[1]?.[challenge._id]?.[section._id]?.correct == false
                      })
                      return <>
                        <button onClick={() => { setCurrentSection(processSections(section,section));setCurrentAttempt({...section,...{correct: true}});setInitValues(section);setShowModal(true) }} className='btn btn-default p-1' style={{fontWeight: 'bold'}}>Q {index + 1}</button>
                        <div><b>{correct.length}</b><span style={{ fontWeight: 'normal' }}>/{wrong.length}</span></div>
                      </>
                    })()}
                  </th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {report && report.session && Object.entries(report.session.groupData)
              .sort(([keyA, valueA], [keyB, valueB]) => { return (valueB as { totalPoints: number }).totalPoints - (valueA as { totalPoints: number }).totalPoints })?.map((data: any, index: number) => {
                return <tr>
                  <td style={{ position: 'sticky', left: 0, zIndex: 1, width: '150px', background: 'white' }}><b>{data[1].students?.map((student: any) => {
                    return report.userDetails[student]
                  }).join(', ')}</b></td>
                  <td className="border-right-1 border-dark" style={{ position: 'sticky', left: '150px', zIndex: 1, background: 'white' }}><b>{data[1].totalPoints}</b></td>
                  {
                    report?.session?.showOrder.map((question: any, index: number) => {
                      return <th className='text-nowrap text-center' style={{ fontWeight: 'normal' }}>
                        {(() => {
                          let challege = report.challenges.filter((c: any) => c._id == report.challengeInstances.filter((ci: any) => ci._id == question.challengeInstanceId)[0].challengeId)[0]
                          let section = report.sections.filter((section: any) => section._id == challege.sections[question.sectionNo])[0]
                          let attempt = data[1]?.[challege._id]?.[section._id] || {}
                          if (attempt.correct == true) {
                            return <button onClick={() => { setCurrentSection(processSections(section, attempt));setInitValues(attempt);setCurrentAttempt(attempt);setShowModal(true) }} className='btn btn-default p-1' style={{fontWeight: 'bold'}}><i className='fa fa-check bg-success text-light p-1'></i></button>
                          } else if (attempt.correct == false) {
                            return <button onClick={() => { setCurrentSection(processSections(section, attempt));setInitValues(attempt);setCurrentAttempt(attempt);setShowModal(true) }} className='btn btn-default p-1' style={{fontWeight: 'bold'}}><i className='fa fa-times bg-danger text-light p-1'></i></button>
                          } else {
                            return '-'
                          }
                        })()}
                      </th>
                    })
                  }
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Filter by Dates</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div>
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={{}} initialValues={initValues} onSubmit={() => { }}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    {ViewSection(currentSection, true, 1, errors, values, setFieldValue, currentAttempt, true)}
                  </Form>)}</Formik>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { OfflineReportsDetailed }
