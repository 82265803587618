import { FC, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikValues } from 'formik'
import { useAuth } from '../../modules/auth';
import { generateOtp } from '../../utils/generateOTP';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';
import { FirebaseUpload } from './FirebaseUpload';

export const Settings: FC = () => {
  const { currentUser } = useAuth()
  const [success, setSuccess] = useState('')
  const [password, setPassword] = useState('')
  const [institution, setInstitution] = useState<any>({})
  useEffect(() => {
    updatePassword()
    console.log(currentUser)
    if (currentUser?.role == 'Admin') {
      DisplayLoader(true, '');
      axios.get(process.env.REACT_APP_API_URL + '/institution/').then((reply) => {
        if (reply?.data) {
          setInstitution({ name: reply.data.name, logoUrl: reply.data.profileDetails?.logoUrl });
        } else {
          //TODO
        }
        DisplayLoader(false, '')
      }, (error) => {
        DisplayLoader(false, '')
        //reject(error)//TODO
      });
    }
  }, []);
  const updateInstitutionProfile = (values: FormikValues) => {
    DisplayLoader(true, '');
    axios.post(process.env.REACT_APP_API_URL + '/institution/', values).then((reply) => {
      if (reply?.data) {
        setInstitution({ name: reply.data.name, logoUrl: reply.data.profileDetails.logoUrl });
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const copy = async () => {
    navigator.clipboard.writeText(window.location.origin + '/join-organisation/' + currentUser?.institutionId);
    setSuccess('Link copied successfully. Kindly share it with individuals interested in joining your organization.')
  }
  const save = async (values: any) => {
  }
  const updatePassword = async () => {
    setPassword(generateOtp(currentUser?.username || '').toString())
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      <div className='shadow mb-4 rounded row m-2 p-4'>
        {
          currentUser?.role == 'Admin' && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            initialValues={institution} onSubmit={updateInstitutionProfile}>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
              <Form>
                {success && success !== '' && (
                  <div className='mb-lg-15 alert alert-success text-center'>
                    <div className='alert-text font-weight-bold'>
                      {success}
                    </div>
                  </div>
                )}
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Name of the Institution</label></div>
                  <Field type='text' className='form-control form-control-lg mb-1'
                    name='name' placeholder='Name' />
                </div>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Logo</label></div>
                  <div>
                    <img src={values?.logoUrl} style={{ maxWidth: '100px' }}></img>
                    <input id="file" name="file" type="file" onChange={(event) => {
                      DisplayLoader(true, '')
                      FirebaseUpload(event?.currentTarget?.files?.[0], 'institution/' + currentUser.institutionId + '/logo/' + event?.currentTarget?.files?.[0]?.name,
                        (url: string) => {
                          console.log(url)
                          setFieldValue("logoUrl", url);
                          DisplayLoader(false, '')
                        }
                      )
                    }} />
                  </div>
                </div>
                <div className='mb-15'>
                  <button className='btn btn-success float-end'>Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        }
      </div>
      <div className='shadow mb-4 rounded row m-2 p-4'>
        <div className='col-6'><h5>Admin Password</h5></div>
        <div className='col-6'>
          <div>{password}<button className='btn btn-primary p-2 ms-1' onClick={updatePassword}>Update</button></div>
        </div>
      </div>
      <div className='shadow mb-4 rounded row m-2 p-2'>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={{}} initialValues={{}} onSubmit={save}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {success && success !== '' && (
                <div className='mb-lg-15 alert alert-success text-center'>
                  <div className='alert-text font-weight-bold'>
                    {success}
                  </div>
                </div>
              )}
              <div className='bg-light rounded p-2 col-12 d-inline-block'>
                <div><label className='form-label fw-bolder text-dark fs-6'>Invite registered users to join your organisation.</label></div>
                <button type='button' className='btn btn-success' onClick={() => { copy() }}>Copy Invite Link</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}