/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { App } from '../App'
import { DashboardOffline } from '../pages/offline/DashboardOffline'
import { ChallengeOffline } from '../pages/offline/ChallengeOffline'
import { SettingsOffline } from '../pages/offline/SettingsOffline'
import { Simulation } from '../pages/offline/Simulation'
import { DeleteAccount } from '../pages/utils/DeleteAccount'
import { Expiry } from '../pages/offline/Expiry'
import { DisconnectedMessage } from '../pages/offline/DisconnectedMessage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const OfflineRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser && (!currentUser.expiry || (new Date(currentUser.expiry) > new Date())) ? (
            <>
              <Route element={<MasterLayout isRelative={true} />}>
                <Route path='lesson/:id/:sectionNo/' element={<ChallengeOffline />} />
                <Route path='lesson/:id/:sectionNo/:challengeInstancesIn' element={<ChallengeOffline />} />
                <Route path='disconnected-message/' element={<DisconnectedMessage />} />
              </Route>
              <Route element={<MasterLayout isRelative={false} />}>
                <Route path='auth/*' element={<Navigate to='/' />} />
                <Route path='settings' element={<SettingsOffline />} />
                <Route path='simulations/:challengeId/:sectionNo/:id' element={<Simulation />} />
                <Route path='delete-account' element={<DeleteAccount />} />
                <Route index element={<DashboardOffline />} />
              </Route>
              {/*<Route index element={<Navigate to='/' />} />*/}
            </>
          )
            : (currentUser && currentUser.expiry && (new Date(currentUser.expiry) <= new Date())) ? (
              <>
              <Route path='*' element={<Navigate to='/' />} />
              <Route path='/' element={<Expiry />} />
              <Route index element={<Expiry />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { OfflineRoutes }
