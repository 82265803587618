import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import SelectorPack from "@recogito/annotorious-selector-pack";
import { DisplayLoader } from '../../../utils/loader';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import '@recogito/annotorious/dist/annotorious.min.css';
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const AnnotatableImage = ({ section, updateOptions, updateAnswer, updateText }) => {
  const imgEl = useRef();
  const [anno, setAnno] = useState();
  const [tool, setTool] = useState('rect');
  const [status, setStatus] = useState('edit');
  const [text, setText] = useState('');
  const [image, setImage] = useState('');
  let annotorious = null;
  useEffect(() => {
    if (section.text) {
      console.log("section" + section.text)
      setText(section.text.split(':image:')[0])
      document.getElementById('question').value = section.text.split(':image:')[0];
      setImage(section.text.split(':image:')[1])
    }
  }, [])
  useEffect(() => {
    console.log("here")
    if (image == '') return;

    if (imgEl.current) {
      annotorious?.destroy()
      // Init
      annotorious = new Annotorious({
        image: imgEl.current,
        allowEmpty: true,
        disableEditor: true,
        widgets: []
      });
      SelectorPack(annotorious)
      if (section.options) {
        annotorious.setAnnotations(section.options);
        setTimeout(() => {
          annotorious.selectAnnotation(section.answer)
        }, 300)
      }
      /*
      Annotorious.SelectorPack(anno, {
        tools: ['ellipse', 'freehand']
      });
      */
      // Attach event handlers here
      annotorious.on('createAnnotation', annotation => {
        updateOptions(annotorious.getAnnotations())
      });
      annotorious.on('deleteAnnotation', annotation => {
        console.log(annotorious.getAnnotations())
        updateOptions(annotorious.getAnnotations())
        annotorious.selectAnnotation(section.answer)
      });

      annotorious.on('updateAnnotation', (annotation, previous) => {
        console.log('updated', annotorious.getAnnotations());
        updateOptions(annotorious.getAnnotations())
      });
      annotorious.on('selectAnnotation', annotation => {
        updateAnswer(0, annotation.id)
        console.log(annotation.id)
        // Here we simply do nothing on select, effectively preventing the default popup from showing.
        // However, the annotation will still be highlighted as selected by Annotorious.
      });
      annotorious.on('cancelSelected', annotation => {
        updateAnswer(0, null)
        console.log(annotation.id)
        // Here we simply do nothing on select, effectively preventing the default popup from showing.
        // However, the annotation will still be highlighted as selected by Annotorious.
      });
      annotorious.on('beforePopup', function (event) {
        event.preventDefault(); // Prevent the popup from showing
      });
      annotorious.on('deleteAnnotation', annotation => {
        console.log('deleted', annotation);
      });
      if (annotorious && section.options?.length > 0) {
        annotorious.setAnnotations(section.options);
      }
      setAnno(annotorious);
    }

    // Keep current Annotorious instance in state

    // Cleanup: destroy current instance
    return () => { annotorious?.destroy() };
  }, [image]);

  const toggleTool = (toolIn) => {
    console.log("here")
    if (toolIn === 'rect') {
      setTool('rect');
      anno.setDrawingTool('rect');
    }
    if (toolIn === 'circle') {
      setTool('circle');
      anno.setDrawingTool('circle');
    }
    if (toolIn === 'polygon') {
      setTool('polygon');
      anno.setDrawingTool('polygon');
    }
  }
  const changeImageLink = (evt, section) => {
    updateText(0, text + ':image:' + evt.target.value)
    setImage(evt.target.value);
  }
  const uploadImage = function (evt, section) {
    if (!evt.target.files[0]) return
    var file = evt.target.files[0];
    const storageRef = ref(storage, 'engage/sections/' + section._id + '/' + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    DisplayLoader(true, '')
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          DisplayLoader(false, '')
          updateText(0, text + ':image:' + downloadURL)
          setImage(downloadURL);
          /*
          cb(downloadURL, {
            title: file.name
          });
          */
        });
      }
    );

  };
  return (
    <div className='text-center'>
      <div><label className='form-label fw-bolder text-dark fs-6 mt-2'>Question</label></div>
      <input type='text' className='form-control form-control-lg mb-1' id='question' onChange={((evt) => { setText(evt.target.value); updateText(0, evt.target.value + ':image:' + image) })} />
      <div><label className='form-label fw-bolder text-dark text-center fs-6 mt-2'>Image</label></div>
      {image != '' &&
        <>
          <div>
            <button type='button' className={'btn m-2 p-2 ' + (tool == 'rect' ? 'btn-info' : 'btn-default border border-dark')} style={{ minWidth: '70px' }} onClick={() => { toggleTool('rect') }}><i class="fa-regular fa-square p-0" style={{ fontSize: '20px' }}></i><br /><span style={{ fontSize: '10px' }}>Rectangle</span></button>
            <button type='button' className={'btn m-2 p-2 ' + (tool == 'circle' ? 'btn-info' : 'btn-default border border-dark')} style={{ minWidth: '70px' }} onClick={() => { toggleTool('circle') }}><i class="fa-regular fa-circle p-0" style={{ fontSize: '20px' }}></i><br /><span style={{ fontSize: '10px' }}>Circle</span></button>
            <button type='button' className={'btn m-2 p-2 ' + (tool == 'polygon' ? 'btn-info' : 'btn-default border border-dark')} style={{ minWidth: '70px' }} onClick={() => { toggleTool('polygon') }}><i class="fa-solid fa-draw-polygon p-0" style={{ fontSize: '20px' }}></i><br /><span style={{ fontSize: '10px' }}>Polygon</span></button>
          </div>
          <img
            ref={imgEl}
            src={image}
          />
          {status == 'edit' && <div>
            <input type='text' placeholder='New Image Link' onChange={(e) => { changeImageLink(e, section) }} /><br />
            <input type='file' className='btn btn-info m-2 p-3' onChange={(e) => { uploadImage(e, section) }} />
          </div>
          }
          {status == 'add' && <>
            <button type='button' className='btn btn-info' onClick={() => { setStatus('edit') }}>Finish</button>
          </>
          }
        </>
      }
      {
        image == '' && <><input type='text' placeholder='New Image Link' onChange={(e) => { changeImageLink(e, section) }} /><input type='file' className='btn btn-info' onChange={(e) => { uploadImage(e, section) }} /></>
      }
    </div>
  );
}

export default AnnotatableImage;