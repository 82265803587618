import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import { DisplayLoader } from '../../utils/loader';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
type ApiParams = { id: string; sectionNo: string };
const StartLesson: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  let [isValid, setIsValid] = useState<boolean>(false);
  let [isTaken, setIsTaken] = useState<boolean>(false);
  let [attemptId, setattemptId] = useState<string>('');
  let [errorMessage, setErrorMessage] = useState<string>('');
  let validationSchema: any = Yup.object(
    {
      name: Yup.string().required().label('Name'),
    })
  let initValues = { name: '' }
  useEffect(() => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/check-valid-challenge/' + id).then((reply) => {
      console.log(reply)
      if (reply?.data?.redirect) {
        window.location.pathname = reply.data.redirect;
      } else {
        setErrorMessage('Invalid link');
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      setErrorMessage('Invalid link');
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }, []);
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {errorMessage === '' && <div className='text-center p-4'>
        Wait while you are being redirected
      </div>
      }
      {errorMessage !== '' && <div className='text-center p-4'>
        <div ><h5 className='text-danger'>{errorMessage}</h5></div>
        <a href='/' type='button' className='btn btn-dark'>GO HOME</a>
      </div>}
    </div>
  )
}

export { StartLesson }
