import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { EditSection } from '../../modules/components/display/EditSection'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { Present } from './Present';
import { SevenLives } from '../../modules/components/display/displayquestiontypes/7lives';
import { check } from 'prettier';
import { Reports } from '../reports/Reports';
import { ReportsList } from './ReportsList';
type ApiParams = { id: string; presentState: string };
const CreateChallenge: FC = () => {
  const { id, presentState } = useParams<ApiParams>();
  let [headingEdit, setHeadingEdit] = useState<boolean>(false);
  let [sectionState, setSectionState] = useState<number>(0);//0-list,1-preview,2-edit
  let [challenge, setChallenge] = useState<any>({});
  let [sections, setSections] = useState<any>({});
  let [search, setSearch] = useState<any>({ searchItems: [], skipCount: 0, selectedItems: [], more: false });
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(0);
  let [initValues, setInitValues] = useState<any>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  useEffect(() => {
    updateCurrentSection(challenge, sections)
  }, [currentSectionNo, sections])
  useEffect(() => {
    if (id) {
      updateChallenge();
    } else {
      createChallenge();
    }
    let filterQuestionTypeElements = document.getElementsByName('fillterqtype');
    for (let i = 0; i < filterQuestionTypeElements.length; i++) {
      (filterQuestionTypeElements[i] as HTMLInputElement).checked = true;
    }
  }, []);
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, false, (challenge && challenge.heading && challenge.heading !== '')));
  }
  const updateCurrentSection = (challenge: any, sections: any) => {
    if (challenge.sections && challenge.sections.length > 0 && challenge.sections[currentSectionNo]) {
      let data = sections[challenge.sections[currentSectionNo]] || {}
      data.heading = challenge.heading;
      setInitValues(data);
      updateValidationSchema(data);
    } else {
      let data = { heading: challenge.heading }
      setInitValues(data);
      updateValidationSchema(data);
    }
  }
  const updateChallenge = (sectionNo?: number) => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge/' + id).then((reply) => {
      setHeadingEdit(false);
      if (reply?.data) {
        setChallenge(reply.data.challenge);
        setSections(reply.data.sections);
        if (sectionNo) {
          setCurrentSectionNo(sectionNo)
        } else {
          updateCurrentSection(reply.data.challenge, reply.data.sections)
          if (reply.data.challenge.sections[currentSectionNo]) {

          } else {
            setCurrentSectionNo(0);
          }
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const createChallenge = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/').then((reply) => {
      if (reply?.data?.challengeId) {
        window.location.pathname = '/create-module/' + reply.data.challengeId;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const saveSection = (values: FormikValues) => {
    values.sectionId = values._id;
    values.challengeId = window.location.pathname.split("/")[2];
    setSectionState(0);
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge();
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const addSection = (sectionPosition: number) => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = sectionPosition;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/save-challenge/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(sectionPosition);
        setSectionState(2);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const deleteSection = (sectionPosition: number) => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = sectionPosition;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/delete-section/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(sectionPosition);
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const initializeSearch = () => {
    setSearch({ searchItems: [], skipCount: 0, selectedItems: [], more: false });
    (document.getElementById('searchText') as HTMLInputElement).value = '';
  }
  const generateSections = () => {
    let input: any = document.getElementById('fileForGeneration');
    let originalFile = input.files[0];
    let formData = new FormData();
    formData.append('file', originalFile);
    axios.post(process.env.REACT_APP_API_URL + '/generate-sections/', formData).then((reply) => {
    })
    //setSearch({ searchItems: [], skipCount: 0, selectedItems: [], more: false });
    //(document.getElementById('searchText') as HTMLInputElement).value = '';
  }
  const searchQuestions = (skipCount: number) => {
    let searchText = (document.getElementById('searchText') as HTMLInputElement).value;
    if (searchText.length < 3) return
    let filterQuestionTypeElements = document.getElementsByName('fillterqtype');
    let questionTypes = [];
    for (let i = 0; i < filterQuestionTypeElements.length; i++) {
      if ((filterQuestionTypeElements[i] as HTMLInputElement).checked) {
        questionTypes.push((filterQuestionTypeElements[i] as HTMLInputElement).value);
      }
    }
    axios.post(process.env.REACT_APP_API_URL + '/search-section/', { queryString: searchText, skipCount: skipCount, questionTypes: questionTypes }).then((reply) => {
      if (reply?.data && Array.isArray(reply.data)) {
        if (skipCount === 0) {
          let searchItems = [...search.selectedItems, ...reply.data]
          searchItems = searchItems.filter((item, pos) => searchItems.indexOf(item) === pos)
          setSearch({ searchItems: searchItems, skipCount: skipCount, selectedItems: search.selectedItems, more: reply.data.length < 10 ? false : true });
          (document.getElementById('searchList') as HTMLInputElement).scrollTo(0, 0);
        } else {
          let searchItems = [...search.searchItems, ...search.searchItems, ...reply.data]
          searchItems = searchItems.filter((item, pos) => searchItems.indexOf(item) === pos)
          setSearch({ searchItems: searchItems, skipCount: skipCount, selectedItems: search.selectedItems, more: reply.data.length < 10 ? false : true });
        }
        //updateChallenge(sectionPosition);
      } else {

      }
    }, (error) => {
      //reject(error)//TODO
    });
  }
  const addSelectedSections = () => {
    let values: any = {};
    values.challengeId = window.location.pathname.split("/")[2];
    values.sectionPosition = currentSectionNo;
    values.sections = search.selectedItems;
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/add-selected-sections/', values).then((reply) => {
      if (reply?.data) {
        updateChallenge(currentSectionNo);
        setSectionState(0);
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
        validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
        {({ errors, touched, values, setFieldValue, setErrors }) => (
          <Form>
            {
              challenge && !challenge.heading &&
              <div className='bg-light rounded p-2 col-12 d-inline-block'>
                <div><label className='form-label fw-bolder text-dark fs-6'>Challenge Name</label></div>
                <div className='col-10 d-inline-block'>
                  <Field type='text' className='form-control form-control-lg mb-1'
                    name='heading' placeholder='Challenge Name' />
                </div>
                <div className='col-2 d-inline-block text-center'>
                  <button className='btn btn-sm btn-dark'>Save</button>
                </div>
              </div>
            }
            {challenge && challenge.heading && <div>
              <div className='bg-light rounded p-2 col-8 d-inline-block'>
                <div><label className='form-label fw-bolder text-dark fs-6'>Challenge Name</label></div>
                <div className='col-11 d-inline-block'>
                  <Field type='text' className='form-control form-control-lg mb-1'
                    name='heading' placeholder='Challenge Name' disabled={!headingEdit} />
                </div>
                <div className='col-1 d-inline-block'>
                  {!headingEdit && <button type='button' className='btn btn-sm btn-default' onClick={() => { setHeadingEdit(true) }}><i className="fa fa-pencil"></i></button>}
                  {headingEdit && <button className='btn btn-sm btn-dark' onClick={() => { setHeadingEdit(true) }}><i className="fa fa-save"></i></button>}
                </div>
              </div>
              <div className='bg-light rounded text-center col-4 d-inline-block'>
                <ReportsList id={id ? id : ''} />
                <Present id={id ? id : ''} challenge={challenge} sections={sections} presentStateIn={parseInt(presentState || '0')} />
              </div>
              {ErrorDisplay('heading', errors)}
              <hr className='m-2' />
              {sectionState === 0 && <div className='col-12 d-inline-block bg-light p-1 align-top border border-left-0 border-top-0 border-bottom-0 border-dark'>
                {
                  challenge && challenge.sections && challenge.sections?.map((section: string, index: number) => {
                    if (sections[section]) {
                      return (
                        <div key={index}>
                          <button type='button' onClick={() => { setCurrentSectionNo(index); }} className={'shadow bg-light mb-1 rounded p-1 border ' + (index === currentSectionNo ? 'border-success border-3' : 'border-dark border-1')} style={{ width: "100%", textAlign: "left" }}>
                            <div className='text-center text-dark'><b>Section {index + 1}</b></div>
                            <div style={{ height: "250px", overflow: "hidden", maskImage: "linear-gradient(to bottom, black 80%, transparent 100%)", WebkitMaskImage: "linear-gradient(to bottom, black 80%, transparent 100%)" }}>
                              {ViewSection(sections[section], true, 1, errors, values, setFieldValue)}
                            </div>
                          </button>
                          <button type='button' onClick={() => { setCurrentSectionNo(index); setSectionState(1) }} className='btn btn-dark p-2' style={{ position: 'absolute', marginLeft: "-335px", marginTop: "-0px" }}>Preview</button>
                          <button type='button' onClick={() => { setCurrentSectionNo(index); setSectionState(2) }} className='btn btn-dark p-2' style={{ position: 'absolute', marginLeft: "-230px", marginTop: "-0px" }}>Edit Section</button>
                          <button type='button' className='btn btn-dark p-2' onClick={() => { setCurrentSectionNo(index); }} data-bs-toggle="modal" data-bs-target="#addModal" style={{ position: "absolute", marginLeft: "-100px", marginTop: "-0px" }}>Add Section</button>
                        </div>
                      )
                    } else {
                      return <></>
                    }
                  })
                }
                <div className='text-center'><button type='button' onClick={() => { setCurrentSectionNo(challenge?.sections ? challenge.sections.length : 0) }} className='btn btn-dark p-4' data-bs-toggle="modal" data-bs-target="#addModal" >Add Section</button></div>
              </div>}
              {sectionState === 1 && <div className='col-12 d-inline-block bg-light p-1 align-top border border-left-0 border-top-0 border-bottom-0 border-dark'>
                <button className='btn btn-dark' onClick={() => { setSectionState(0) }}>Back</button>
                <div className='text-center'><b>Section {currentSectionNo + 1}</b></div>
                {
                  ViewSection(sections[challenge.sections[currentSectionNo]], true, 1, errors, values, setFieldValue)
                }
              </div>}
              {id && sectionState === 2 && <div className='col-12 d-inline-block shadow bg-light rounded p-5 card-body'>
                <>
                  <div className='text-center text-dark'><b>Section {currentSectionNo + 1}</b></div>
                  <div>
                    <div className='d-inline-block me-2 row align-top mt-4'>
                      <label className='form-label fw-bolder text-dark fs-6'>Points</label>
                    </div>
                    <div className='d-inline-block text-center'><Field type='number' name='positivePoints' className='form-control d-inline mb-1' style={{ width: "75px", marginLeft: "5px" }} /><br />
                      <strong className={errors['positivePoints'] !== null ? 'text-danger' : ''} style={{ fontSize: '10px' }}>Positive</strong>
                    </div>
                    <div className='d-inline-block text-center'><Field type='number' name='negativePoints' className='form-control d-inline mb-1' style={{ width: "75px", marginLeft: "5px" }} /><br />
                      <strong className={errors['negativePoints'] !== null ? 'text-danger' : ''} style={{ fontSize: '10px' }}>Negative</strong>
                    </div>
                  </div>
                  <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { values.type = e.target.value; updateValidationSchema(values) }}>
                    <option value="">Choose the challenge type</option>
                    <option value="simulation">Simulation</option>
                    <option value="slide">Slide</option>
                    <option value="mcq">Multiple Choice Question (MCQ)</option>
                    <option value="multiple">Multiple Choice Question with Multiple Answers</option>
                    <option value="numerical">Numerical</option>
                    <option value="short">Short Text</option>
                    <option value="long">Long Text</option>
                    <option value="pair">Pair Match</option>
                    <option value="blanks">Fill in the blanks</option>
                    <option value="matrix">Matrix Match</option>
                    <option value="click">Click</option>
                    <option value="crossword">Crossword</option>
                    <option value="7lives">7 Lives Game</option>
                  </Field>
                  {ErrorDisplay('type', errors)}
                  {EditSection(values, errors, setFieldValue)}
                  <div className='mt-10'>
                    <button className='btn btn-dark float-end'>Save</button>
                    <button type="button" className="btn btn-danger float-start" data-bs-toggle="modal" data-bs-target="#deleteModal">Delete Section</button>

                  </div>
                </>
                {false && id && sectionState === 0 &&
                  <>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>Points:</label><h6 className='d-inline' style={{ marginLeft: "5px" }}>{sections[challenge.sections[currentSectionNo]]?.points}</h6>
                    </div>
                    {ViewSection(sections[challenge.sections[currentSectionNo]], true, 1, errors, values, setFieldValue)}
                    <div className='m-5'>
                      <button type='button' onClick={() => { setSectionState(2) }} className='btn btn-dark float-end'>Edit</button>
                    </div>
                  </>}
              </div>}
            </div>}
          </Form>
        )}
      </Formik>
      <div className="modal fade" id="deleteModal" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Section</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5>Are you sure that you want to delete this section?</h5>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" onClick={() => deleteSection(currentSectionNo)} data-bs-dismiss="modal">Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="addModal" aria-labelledby="addModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">Add Section</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <button type='button' className='btn btn-info m-3' onClick={() => { initializeSearch() }} data-bs-toggle="modal" data-bs-target="#selectModal" >Select a section from our collection</button>
              <button type='button' className='btn btn-dark m-3' onClick={() => { addSection(challenge?.sections ? challenge.sections.length : 0) }} data-bs-dismiss="modal">Create your own section</button>
              {/*<button type='button' className='btn btn-dark m-3' onClick={() => { generateSections() }} data-bs-toggle="modal" data-bs-target="#generateModal" >Generate sections from Text/PDF</button>*/}
              {/*<input type='file' id='fileForGeneration' onChange={()=>generateSections()}/>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="selectModal" aria-labelledby="selectModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="query">Search</span>
                </div>
                <input id="searchText" type="text" onChange={(e) => { searchQuestions(0) }} className="form-control" placeholder="Your query...." aria-label="Query" aria-describedby="query" />
              </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="searchList" className="modal-body">
              <div style={{ fontSize: "10px" }}>Type atleast 3 letters to start search</div>
              <div>
                <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                  validationSchema={validationSchema} initialValues={initValues} onSubmit={saveSection}>
                  {({ errors, touched, values, setFieldValue, setErrors }) => (
                    <Form>
                      <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle p-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                          Filter Section Types
                        </button>
                        <ul className="dropdown-menu pe-2" aria-labelledby="dropdownMenuButton">
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="simulation" id="checkbox1" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Simulation</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="slide" id="checkbox1" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Slide</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="mcq" id="checkbox2" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Multiple Choice Question (MCQ)</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="multiple" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Multiple Choice Question with Multiple Answers</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="numerical" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Numerical</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="pair" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Pair Match</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="short" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Short Text</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="long" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Long Text</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="blanks" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Fill in the blanks</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="matrix" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Matrix Match</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="click" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Click</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="crossword" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >Crossword</label>
                          </li>
                          <li className='mb-1'>
                            <input onClick={() => { searchQuestions(0) }} className="form-check-input" type="checkbox" value="7lives" id="checkbox3" name="fillterqtype" />
                            <label className="form-check-label ms-1" >7 Lives Game</label>
                          </li>
                        </ul>
                      </div>
                      {search?.searchItems?.map((item: any, index: number) => {
                        return <div className='p-2 rounded border border-dark'>
                          {search.selectedItems.indexOf(item) === -1 && <button type='button' onClick={() => { setSearch({ ...search, ...{ selectedItems: [...search.selectedItems, item] } }) }} className='btn btn-dark p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Select</button>}
                          {search.selectedItems.indexOf(item) !== -1 && <button type='button' onClick={() => { search.selectedItems.splice(index, 1); setSearch({ ...search, ...{ selectedItems: search.selectedItems } }) }} className='btn btn-success p-2' style={{ position: 'absolute', right: "25px", zIndex: 1000 }}>Selected</button>}
                          {ViewSection(item, true, 1, errors, values, setFieldValue)}
                        </div>
                      })}
                      <div>
                        {search.more && <button type='button' onClick={() => { searchQuestions(search.skipCount + 10) }} className='btn btn-dark'>More</button>}
                      </div>
                    </Form>)}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {search.selectedItems.length > 0 && <button type="button" className="btn btn-success" onClick={() => addSelectedSections()} data-bs-dismiss="modal">Add Sections</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="generateModal" aria-labelledby="generateModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="generateModalLabel">Custom Content</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <button type='button' className='btn btn-dark m-3' onClick={() => { addSection(challenge?.sections ? challenge.sections.length : 0) }} data-bs-dismiss="modal">Create your own section</button>
              <button type='button' className='btn btn-info m-3' onClick={() => { initializeSearch() }} data-bs-toggle="modal" data-bs-target="#selectModal" >Select a section from our collection</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateChallenge }
