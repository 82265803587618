import React, { useEffect, useRef, useState } from 'react';
import jsMind from 'jsmind';
import html2canvas from 'html2canvas';
import 'jsmind/style/jsmind.css'; // Ensure the path to CSS is correct
import { systemSort } from '../../../utils/systemSort';

interface MinMapProps {
  section: any;
  setFieldValue: any;
}

const MindMap: React.FC<MinMapProps> = ({ section, setFieldValue }) => {
  const jmContainerRef = useRef<HTMLDivElement | null>(null);
  const [jm, setJm] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [originalNodes, setOriginalNodes] = useState<any>([]);

  useEffect(() => {
    if (!jmContainerRef.current) return;

    const options = {
      container: jmContainerRef.current,
      theme: 'primary',
      editable: true,
    };

    const jmInstance = new jsMind(options);
    if (!section.answer) {
      section.answer = {
        "meta": {
          "name": "demo",
          "author": "olearno",
        },
        "format": "node_tree",
        "data": {
          "id": "root", "topic": "Root",
        }
      };
    }
    jmInstance.show(section.answer);
    setJm(jmInstance);

    // Select node handler
    jmInstance.add_event_listener((type: string, data: any) => {
      if (data.evt === 'select_node') {
        setSelectedNode(data);
      }
      if (data.evt === 'add_node' || data.evt === 'remove_node' || data.evt === 'update_node') {
        let answer: any = { ...jmInstance.get_data('node_tree') };
        console.log(answer)
        setFieldValue('answer', answer)
        let options: any = {}
        options.meta = answer.meta;
        options.format = answer.format;
        options.options = addedTopics(answer.data)
        options.data = {}
        options.data.id = answer.data.id
        options.data.topic = answer.data.topic
        setFieldValue('options', options)
      }
    });
    setOriginalNodes(findOriginalNodes(section.answer.data))
  }, []);
  const findOriginalNodes = (data: any) => {
    let originalNodes = [data.id]
    if (data.children) {
      for (let i = 0; i < data.children.length; i++) {
        originalNodes = [...originalNodes, ...findOriginalNodes(data.children[i])];
      }
    }
    return originalNodes;
  }
  const addedTopics = (data: any) => {
    let originalNodes = [data.topic]
    if (data.id == 'root') {
      originalNodes = []
    }
    if (data.children) {
      for (let i = 0; i < data.children.length; i++) {
        originalNodes = [...originalNodes, ...addedTopics(data.children[i])];
      }
    }
    return originalNodes;
  }
  const addNode = () => {
    if (selectedNode && jm) {
      const selectedNodeId = selectedNode.node;
      const newId = Date.now().toString(); // Simple unique ID generation
      jm.add_node(selectedNodeId, newId, 'Node');
    }
  };
  const deleteNode = () => {
    console.log(selectedNode)
    if (selectedNode && jm && originalNodes.indexOf(selectedNode.node) == -1) {
      jm.remove_node(selectedNode.node);
      setSelectedNode(null);
    }
  };

  return (
    <div>
      <div>
        <label className='form-label fw-bolder text-dark fs-6'>Use as question </label>
        <button className='btn btn-info p-2 m-1 text-center float-end' type='button' onClick={deleteNode} disabled={!selectedNode || selectedNode.id === 'root'}><i className="fa fa-trash p-0 m-0"></i></button>
        <button className='btn btn-info p-2 m-1 text-center float-end' type='button' onClick={addNode} disabled={!selectedNode || selectedNode.id === 'root'}><i className="fa fa-plus p-0 m-0"></i></button>
      </div>
      <div ref={jmContainerRef} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default MindMap;
