import { FC, useState } from 'react'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Field, Form, Formik, FormikValues } from 'formik'
import { DisplayLoader } from '../../utils/loader'
import axios from 'axios'
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay'


const Scholarship: FC = () => {
  let [error, setSetError] = useState<string>('');
  let [hasRedirected, setHasRedirected] = useState<boolean>(false);
  let validationSchema = Yup.object({
    phoneNo: Yup.string().required().label('Phone No').matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
    grade: Yup.number().required().label('Grade').min(0).max(12)
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
  });
  const submit = (values: FormikValues) => {
    DisplayLoader(true, '')
    setSetError('');
    console.log("here")
    axios.post(process.env.REACT_APP_API_URL + '/register-scholarship/', values).then((reply) => {
      if (reply?.data?.redirect) {
        DisplayLoader(false, '')
        setHasRedirected(true);
        window.open(reply?.data?.redirect, '_blank');
        window.location.pathname = '/'
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      setSetError(error.response.data.error);
      DisplayLoader(false, '')
    });
  }
  return (
    <>
      <div className='text-center'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.jpg')} className='h-65px' />
        <img alt='Logo' src={toAbsoluteUrl('https://auroscholar.com/assets/user/img/auro_logo.svg')} className='h-65px' />
      </div>
      {!hasRedirected && <div className='d-flex justify-content-center' >
        <div>
          <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            validationSchema={validationSchema} initialValues={{}} onSubmit={submit} cla>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
              <Form className='mt-15'>
                <label className='form-label fs-6 fw-bolder text-dark text-start'>Phone No (10 digits)</label>
                <Field type='text' className='form-control form-control-lg mb-1'
                    name='phoneNo' placeholder='Phone No'/>
                {ErrorDisplay('phoneNo', errors)}
                <label className='form-label fs-6 fw-bolder text-dark text-start mt-5'>Grade</label>
                <Field as="select" name="grade" className="form-select mb-1">
                  <option value="">Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Field>
                {ErrorDisplay('grade', errors)}
                {error && error !== '' && <div className='text-danger'>*{error}</div>}
                <button className='btn btn-primary mt-10 float-end'>Submit</button>
              </Form>)}
          </Formik>
        </div>
      </div>}
      {hasRedirected && <div className='d-flex justify-content-center' >
        <label className='form-label fs-6 fw-bolder text-dark text-start'>You have been successfully redirected to the login page</label>
      </div>}
      {/*<iframe src="https://auroscholar.com/?partner_source=Olearno_WEB" allow="camera *;microphone *"
  style={{width: "100vw", height: "100vh", position:"fixed", top:"0px", left:"0px", zIndex:"10000", backgroundColor:"white"}}></iframe>*/}
    </>
  )
}

export { Scholarship }
