import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import 'jsmind/style/jsmind.css'; // Ensure the path to CSS is correct

const Sample = () => {


  useEffect(() => {
    /*
    const myGlobe = Globe();
    let x = document.getElementById('globeViz')
    if (x) {
      myGlobe(x) .globeImageUrl('https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Equirectangular_projection_SW.jpg/1200px-Equirectangular_projection_SW.jpg')
    }
    */
  }, []);

  return (
    <div>
      <div id="globeViz"></div>
    </div>
  );
};

export default Sample;
