import * as Yup from 'yup'
import Select from 'react-select'
import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { Form, Formik, FormikValues, Field } from 'formik'
import { Link, useNavigate } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader'
import { topicListForStream } from '../../models/LocalLists';
import { ViewSection } from '../../modules/components/display/ViewSection';
import { EditSection } from '../../modules/components/display/EditSection';
import { Section, ValidationSchema } from '../../models/Section';
import { Modal } from 'react-bootstrap';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';

const GenerateChallenge: FC = () => {
  const navigate = useNavigate();
  let initialValues: any = {}
  let [pageStatus, setPageStatus] = useState<number>(0);
  let [batches, setBatches] = useState<any>([]);
  let [config, setConfig] = useState<any>({});
  let [sections, setSections] = useState<any>([]);
  let [replacementSections, setReplacementSections] = useState<any>([]);
  let [allSections, setAllSections] = useState<any>({});
  let [currentSection, setCurrentSection] = useState<any>({});
  let [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  let [isEditing, setIsEditing] = useState<boolean>(false);
  let [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  let [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
  let [showCreateChallengeModal, setShowCreateChallengeModal] = useState<boolean>(false);
  let [showReplacementQuestionModal, setShowReplacementQuestionModal] = useState<boolean>(false);
  let [hasInsufficientQuestions, setHasInsufficientQuestions] = useState<boolean>(false);
  let [subjectQuestionConfig, setSubjectQuestionConfig] = useState<any>([{ topics: [], noOfQuestions: 0, questionType: { value: 'mcq', label: 'MCQ' } }]);
  let [partTestQuestionConfig, setPartTestQuestionConfig] = useState<any>({ physicsTopics: [], chemistryTopics: [], mathematicsTopics: [], botanyTopics: [], zoologyTopics: [] });
  let [createChallengeError, setCreateChallengeError] = useState<string>('');
  let validationSchema = Yup.object({
    targetTest: Yup.string().required("This field is required"),
    testType: Yup.string().required("This field is required"),
  })
  let validationSchemaAssignment = Yup.object({
    heading: Yup.string().required().label('This field is required'),
    startDate: Yup.date().required().label('Start Date'),
    endDate: Yup.date().required().label('End Date'),
    startTimeH: Yup.number().lessThan(13).moreThan(0).required().label('Hour should be a number between 01 and 12'),
    startTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 00 and 59'),
    endTimeH: Yup.number().lessThan(13).moreThan(0).required().label('Hour should be a number between 01 and 12'),
    endTimeM: Yup.number().lessThan(60).moreThan(-1).required().label('Minute should be a number between 00 and 59'),
  })
  let [errors, setErrors] = useState<any>('');
  const scrollCurrentSectionToView = () => {
    setTimeout(() => {
      (document.getElementById('section' + currentSectionIndex) as HTMLElement).scrollIntoView();
    }, 500)
  }
  useEffect(() => {
    listBatches();
  }, [])
  const listBatches = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-list/').then((reply) => {
      if (reply?.data) {
        setBatches(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  const saveSection = () => {
    let newSections = [...sections]
    newSections[currentSectionIndex] = currentSection
    setIsEditing(false)
    scrollCurrentSectionToView();
    setSections(newSections);
  }
  const deleteSection = (sectionNo: number) => {
    let newSections = [...sections];
    newSections.splice(sectionNo, 1);
    setSections(newSections);
  }
  const replaceSection = (addToReplacementSections?: boolean) => {
    let fineTags: any = []
    console.log("here")
    if (config.testType == 'full' || config.testType == 'part') {
      if (config.targetTest == 'mains') {
        if (Math.floor(currentSectionIndex / 30) == 0) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['physicsTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['physics']
          }
        } else if (Math.floor(currentSectionIndex / 30) == 1) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['chemistryTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['chemistry']
          }
        } else if (Math.floor(currentSectionIndex / 30) == 2) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['mathematicsTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['mathematics']
          }
        }
      }
      if (config.targetTest == 'neet') {
        if (Math.floor(currentSectionIndex / 50) == 0) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['physicsTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['physics']
          }
        } else if (Math.floor(currentSectionIndex / 50) == 1) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['chemistryTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['chemistry']
          }
        } else if (Math.floor(currentSectionIndex / 50) == 2) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['botanyTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['botany']
          }
        } else if (Math.floor(currentSectionIndex / 50) == 3) {
          if (config.testType == 'part') {
            fineTags = [...fineTags, ...partTestQuestionConfig['zoologyTopics'].map((topic: any) => topic.value)]
          } else {
            fineTags = ['zoology']
          }
        }
      }
    }
    if (config.testType == 'subject') {
      let questionsMoved = 0;
      let index = 0;
      while ((subjectQuestionConfig[index].noOfQuestions + questionsMoved) <= currentSectionIndex) {
        index++
        questionsMoved += subjectQuestionConfig[index].noOfQuestions
      }
      fineTags = subjectQuestionConfig[index].topics[0].map((topic: any) => topic.value)
    }
    let usedSections = [...allSections]
    if (addToReplacementSections) {
      usedSections = [...replacementSections.map((s: Section) => s._id), ...allSections]
    }
    DisplayLoader(true, 'Finding questions to replace...')
    axios.post(process.env.REACT_APP_API_URL + '/replace-question/',
      { fineTags: fineTags, type: sections[currentSectionIndex].type, targetTest: config.targetTest, usedSections: usedSections, batchId: config.batch })
      .then((reply) => {
        if (reply?.data?.questions) {
          if (reply?.data?.error == 'Insufficient Questions') {
            setHasInsufficientQuestions(true)
          }
          if (addToReplacementSections) {
            setReplacementSections([...replacementSections, ...reply.data.questions])
          } else {
            setReplacementSections(reply.data.questions)
          }

          // setAllSections([...sections, ...reply.data.questions.map((section: any) => section._id)])
          setPageStatus(1);
          setShowReplacementQuestionModal(true)
          DisplayLoader(false, '')
        } else {
          setErrors('Unfortunately we were unable to generate for this configuration')
          //TODO
        }
      }, (error) => {
        //reject(error)//TODO
        setErrors('Something went wrong')
        DisplayLoader(false, '')
      });
  }
  const generateQuestionPaper = (values: any) => {
    setErrors('')
    setHasInsufficientQuestions(false)
    if (values.testType == 'subject') {
      let errorText = ''
      subjectQuestionConfig.forEach((element: any) => {
        if (element.topics.length == 0 || element.noOfQuestions == 0 || element.questionType.length == 0) {
          errorText = 'There is an error in the details of the test';
        }
      })
      if (errorText) {
        setErrors(errorText)
        return
      }
    } else if (values.testType == 'part') {
      let errorText = ''
      if (partTestQuestionConfig['physicsTopics'].length == 0 || partTestQuestionConfig['chemistryTopics'].length == 0 || (values.targetTest == 'mains' && partTestQuestionConfig['mathematicsTopics'].length == 0) || (values.targetTest == 'neet' && (partTestQuestionConfig['botanyTopics'].length == 0 || partTestQuestionConfig['zoologyTopics'].length == 0)))
        errorText = 'The topics for the test are not filled';
      if (errorText) {
        setErrors(errorText)
        return
      }
    }
    DisplayLoader(true, 'Generating Question Paper. Please wait.....')
    axios.post(process.env.REACT_APP_API_URL + '/question-paper-generator/',
      { config: values, subjectQuestionConfig: subjectQuestionConfig, partTestQuestionConfig: partTestQuestionConfig, batchId: values.batch })
      .then((reply) => {
        setConfig(values)
        if (reply?.data?.questions) {
          if (reply?.data?.error == 'Insufficient Questions') {
            setHasInsufficientQuestions(true)
          }
          setSections(reply.data.questions)
          setAllSections(reply.data.questions.map((section: any) => section._id))
          setPageStatus(1);
          DisplayLoader(false, '')
        } else {
          setErrors('Unfortunately we were unable to generate for this configuration')

          //TODO
        }
      }, (error) => {
        //reject(error)//TODO
        setErrors('Something went wrong')
        DisplayLoader(false, '')
      });
  }
  const createAssignmentInstance = (values: FormikValues) => {
    values.startTimeH = values.startTimeH === 12 ? 0 : values.startTimeH;
    values.endTimeH = values.endTimeH === 12 ? 0 : values.endTimeH;
    values.start = new Date(new Date(values.startDate).getTime() + (parseInt(values.startTimeH) + (values.startTimeAMPM === 'pm' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.startTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    values.end = new Date(new Date(values.endDate).getTime() + (parseInt(values.endTimeH) + (values.endTimeAMPM === 'pm' ? 12 : 0)) * 60 * 60 * 1000 + (parseInt(values.endTimeM) + new Date().getTimezoneOffset()) * 60 * 1000);
    if (new Date(values.end) < new Date(values.start)) {
      setCreateChallengeError('End Time should be after Start Time')
      return
    }
    if (config.testType == 'subject' && !values['totalTime']) {
      setCreateChallengeError('Total Time is required')
      return
    }
    if (config.testType == 'part' || config.testType == 'full') {
      if (config.targetTest == 'mains') {
        values.totalTime = 180
      }
      if (config.targetTest == 'neet') {
        values.totalTime = 200
      }
    }
    console.log("here-create + " + JSON.stringify(values))
    createChallengeInstance(values);
  }
  const createChallengeInstance = (values: FormikValues) => {
    DisplayLoader(true, 'Scheduing the test...')
    setCreateChallengeError('')
    axios.post(process.env.REACT_APP_API_URL + '/create-new-challenge-instance/',
      {
        ...{ batchId: config.batch, sections: sections, targetTest: config.targetTest },
        ...values
      }).then((reply) => {
        if (reply?.data?.challengeId) {
          navigate('/create-module/' + reply.data.challengeId)
        } else {
          //TODO
        }
        DisplayLoader(false, '')
      }, (error) => {
        DisplayLoader(false, '')
        //reject(error)//TODO
      });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow pb-15'>
      {pageStatus == 0 && <>
        <div><Link to='/generate-test' className='btn btn-info float-end p-2'>Single Topic Test</Link></div>
        {errors && <div className='bg-danger text-white mb-3 rounded p-5 text-center'>{'*' + errors}</div>}
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initialValues} onSubmit={generateQuestionPaper}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {/* <div>
                <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Name*</label></div>
                <div className='col-7 d-inline-block'><Field type='text' name='heading' className='form-control form-control mb-1' />
                  {errors['heading'] && errors['heading'] !== '' && <div className='text-danger'>{'*' + errors['heading']}</div>}
                </div>
              </div> */}
              <div>
                <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Batch</label></div>
                <div className='col-7 d-inline-block'>
                  <Field as="select" className='form-select form-select-sm form-select-solid fs-3 bg-white' name='batch'>
                    <option value={''}>--Select Batch--</option>
                    {batches.map((batch: any) => { return <option value={batch._id}>{batch.name}</option> })}
                  </Field>
                  {errors['targetTest'] && errors['targetTest'] !== '' && <div className='text-danger'>{'*' + errors['targetTest']}</div>}
                </div>
              </div>
              <div>
                <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Target Test*</label></div>
                <div className='col-7 d-inline-block'>
                  <Field as="select" className='form-select form-select-sm form-select-solid fs-3 bg-white' name='targetTest'>
                    <option value={''}>--Select Target Test--</option>
                    <option value={'mains'}>JEE Mains</option>
                    <option value={'neet'}>NEET</option>
                  </Field>
                  {errors['targetTest'] && errors['targetTest'] !== '' && <div className='text-danger'>{'*' + errors['targetTest']}</div>}
                </div>
              </div>
              <div>
                <div className='col-3 m-2 ms-4 d-inline-block me-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Test Type*</label></div>
                <div className='col-7 d-inline-block'>
                  <Field as="select" className='form-select form-select-sm form-select-solid fs-3 bg-white' name='testType'>
                    <option value={''}>--Select Test Type--</option>
                    <option value={'subject'}>Subject Test</option>
                    <option value={'part'}>Part Test</option>
                    <option value={'full'}>Full Length Test</option>
                  </Field>
                  {errors['testType'] && errors['testType'] !== '' && <div className='text-danger'>{'*' + errors['testType']}</div>}
                </div>
              </div>
              {
                values['testType'] == 'subject' && <div className='p-5 pb-15'>
                  <h4 className='text-center'>Test Details*</h4>
                  <table className='table table-bordered text-center'>
                    <thead>
                      <tr>
                        <th className='col-5'>Topics</th>
                        <th className='col-3'>Question Type</th>
                        <th className='col-3'>No. of Questions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjectQuestionConfig.map((config: any, index: number) => {
                        return <tr>
                          <td className='col-5'>
                            <Select value={config.topicList} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].topics = v.map((v1: any) => v); setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                              isMulti
                              options={[...topicListForStream['JEE-NEET']['Physics'], ...topicListForStream['JEE-NEET']['Chemistry'], ...(values.targetTest == 'mains' ? topicListForStream['JEE-NEET']['Mathematics'] : [...topicListForStream['JEE-NEET']['Botany'], ...topicListForStream['JEE-NEET']['Zoology']])].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                          </td>
                          <td className='col-3'>
                            <Select value={config.questionType} onChange={(v: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].questionType = v; setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                              options={[{ value: 'mcq', label: 'MCQ' }, { value: 'numerical', label: 'Numerical' }]} />
                          </td>
                          <td className='col-3'>
                            <input onChange={(e: any) => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig[index].noOfQuestions = e.target.value; setSubjectQuestionConfig(newSubjectQuestionConfig) }}
                              type='text' className='form-control' value={config.noOfQuestions} style={{ height: '40px' }}></input>
                          </td>
                          <td className='col-1'>
                            <button type='button' className='btn btn-danger button-sm' onClick={() => { let newSubjectQuestionConfig = [...subjectQuestionConfig]; newSubjectQuestionConfig.splice(index, 1); setSubjectQuestionConfig(newSubjectQuestionConfig) }}>
                              <i className='fa fa-times'></i>
                            </button>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  <button type='button' className='btn btn-primary float-end' onClick={() => { setSubjectQuestionConfig([...subjectQuestionConfig, ...[{ topics: [], noOfQuestions: 0, questionType: { value: 'mcq', label: 'MCQ' } }]]) }}>Add Questions</button>
                </div>
              }
              {
                values['testType'] == 'part' && <div>
                  <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Physics Topics*</label></div>
                  <div className='col-7 d-inline-block'>
                    <Select value={partTestQuestionConfig['physicsTopics']} isMulti
                      onChange={(v) => { setPartTestQuestionConfig({ ...partTestQuestionConfig, ...{ physicsTopics: v } }) }}
                      options={topicListForStream['JEE-NEET']['Physics'].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                  </div>
                  <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Chemistry Topics*</label></div>
                  <div className='col-7 d-inline-block'>
                    <Select value={partTestQuestionConfig['chemistryTopics']} isMulti
                      onChange={(v) => { setPartTestQuestionConfig({ ...partTestQuestionConfig, ...{ chemistryTopics: v } }) }}
                      options={topicListForStream['JEE-NEET']['Chemistry'].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                  </div>
                  {values['targetTest'] == 'mains' && <><div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Mathematics Topics*</label></div>
                    <div className='col-7 d-inline-block'>
                      <Select value={partTestQuestionConfig['matematicsTopics']} isMulti
                        onChange={(v) => { setPartTestQuestionConfig({ ...partTestQuestionConfig, ...{ mathematicsTopics: v } }) }}
                        options={topicListForStream['JEE-NEET']['Mathematics'].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                    </div></>}
                  {values['targetTest'] == 'neet' && <><div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Botany Topics*</label></div>
                    <div className='col-7 d-inline-block'>
                      <Select value={partTestQuestionConfig['botanyTopics']} isMulti
                        onChange={(v) => { setPartTestQuestionConfig({ ...partTestQuestionConfig, ...{ botanyTopics: v } }) }}
                        options={topicListForStream['JEE-NEET']['Biology'].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                    </div>
                    <div className='col-3 m-2 ms-4 d-inline-block me-5'><label className='form-label fw-bolder text-dark fs-6'>Zoology Topics*</label></div>
                    <div className='col-7 d-inline-block'>
                      <Select value={partTestQuestionConfig['zoologyTopics']} isMulti
                        onChange={(v) => { setPartTestQuestionConfig({ ...partTestQuestionConfig, ...{ zoologyTopics: v } }) }}
                        options={topicListForStream['JEE-NEET']['Biology'].map((topic: any) => { return { value: topic.toLocaleLowerCase(), label: topic } })} />
                    </div></>}
                </div>
              }
              <div className='text-center'>
                <button className='btn btn-primary'>Generate Question Paper</button>
              </div>
            </Form>)}
        </Formik>
      </>}
      {pageStatus == 1 && <><div className='mt-3' style={{ display: isEditing ? 'none' : 'block' }}>
        <button type='button' onClick={() => { setPageStatus(0) }} className='btn btn-primary'>Change Question Configuration</button>
        <h3 className='text-center'>Questions</h3>
        {hasInsufficientQuestions && <div className='bg-danger text-white mb-3 rounded p-5'>
          <h5 className='text-center text-white'>Insufficient Questions Available</h5>
          <p>We were unable to create the required number of questions for the test. Please choose one of the following options:</p>
          <ul>
            <li>
              <b>Contact the Olearno Team</b> for additional questions.
            </li>
            <li>
              <b>Create the test with the available questions</b> and add more questions later.
            </li>
          </ul>
        </div>}
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={{}} initialValues={initialValues} onSubmit={() => { }}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {sections?.map((section: any, index: number) => {
                return <>
                  <div className="mb-8" id={'section' + index}>
                    <strong>Section No {index + 1}</strong>
                    <button type="button" className="btn btn-primary m-2 p-2" onClick={() => { setIsEditing(true); setCurrentSection(section); setCurrentSectionIndex(index) }}>Edit</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { setCurrentSectionIndex(index); setShowDeleteModal(true) }}>Delete</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { setCurrentSectionIndex(index); setShowReplaceModal(true) }}>Replace</button>
                  </div>
                  {ViewSection(section, true, 1, errors, section, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword') ? { ...section, ...{ correct: true } } : ((section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'dragdrop' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives' || section.type == 'short') ? { ...section, ...{ correct: false } } : section)))}
                  <hr />
                </>
              })}
            </Form>)}
        </Formik>
        <div className='text-center'>
          <button type="button" className='btn btn-success' onClick={() => { setShowCreateChallengeModal(true) }}>Schedule as a Test</button>
        </div>
      </div>
        <div style={{ display: isEditing ? 'block' : 'none' }}>
          <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            validationSchema={ValidationSchema({}, true)} initialValues={currentSection}
            onSubmit={saveSection}>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
              <Form>
                {EditSection(values, errors, setFieldValue)}
                <button type="button" onClick={() => { setIsEditing(false); scrollCurrentSectionToView(); }} className="btn btn-primary">Back</button>
                <button type="submit" className="btn btn-primary float-end">Save</button>
              </Form>)}
          </Formik>
        </div>
      </>}
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showReplaceModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowReplaceModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Replace Question</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div className="modal-body">
              <h3 className='text-center'>Are you sure you want to replace this question?</h3>
              <div className='mt-4 p-2'>
                <button type='button' onClick={() => { setShowReplaceModal(false) }} className='btn btn-success'>No</button>
                <button type='button' onClick={() => { replaceSection(); setShowReplaceModal(false) }} className='btn btn-danger float-end'>Yes</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showCreateChallengeModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowCreateChallengeModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Schedule Test</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div className="modal-body">
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchemaAssignment} initialValues={{}} onSubmit={createAssignmentInstance}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div>
                      <div><label className='form-label fw-bolder text-dark fs-6'>Test Name</label></div>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='heading' placeholder='Title' />
                      {ErrorDisplay('heading', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>Start Date</label></div>
                      <Field type='date' className='form-control form-control-lg mb-1'
                        name='startDate' placeholder='Title' />
                      {ErrorDisplay('startDate', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>Start Time</label></div>
                      <div>
                        <div className='col-3 col-md-2 d-inline-block me-2'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='startTimeH' placeholder='hh' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='startTimeM' placeholder='mm' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field as="select" name="startTimeAMPM" className="form-select mb-1">
                            <option value='am'>AM</option>
                            <option value='pm'>PM</option>
                          </Field>
                        </div>
                        {ErrorDisplay('startTimeH', errors)}
                        {ErrorDisplay('startTimeM', errors)}
                      </div>
                      <div><label className='form-label fw-bolder text-dark fs-6'>End Date</label></div>
                      <Field type='date' className='form-control form-control-lg mb-1'
                        name='endDate' placeholder='Title' />
                      {ErrorDisplay('endDate', errors)}
                      <div><label className='form-label fw-bolder text-dark fs-6'>End Time</label></div>
                      <div>
                        <div className='col-3 col-md-2 d-inline-block me-2'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='endTimeH' placeholder='hh' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field type='text' className='form-control form-control-xs mb-1'
                            name='endTimeM' placeholder='mm' number />
                        </div>
                        <div className='col-3 col-md-2 d-inline-block'>
                          <Field as="select" name="endTimeAMPM" className="form-select mb-1">
                            <option value='am'>AM</option>
                            <option value='pm'>PM</option>
                          </Field>
                        </div>
                        {ErrorDisplay('endTimeH', errors)}
                        {ErrorDisplay('endTimeM', errors)}
                      </div>
                      {config.testType == 'subject' && <>
                        <div><label className='form-label fw-bolder text-dark fs-6'>Total Time (in mins)</label></div>
                        <Field type='text' className='form-control form-control-lg mb-1'
                          name='totalTime' placeholder='Total Time' />
                        {ErrorDisplay('totalTime', errors)}
                      </>}
                    </div>
                    {createChallengeError && createChallengeError !== '' && <div className='text-danger'>*{createChallengeError}</div>}
                    <button type='button' className='btn btn-danger' onClick={() => { setShowCreateChallengeModal(false) }}>Close</button>
                    <button className='btn btn-success float-end'>Schedule</button>
                  </Form>)}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showDeleteModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowDeleteModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Delete Section</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <h3 className='text-center'>Are you sure you want to delete this question?</h3>
            <p className='text-danger text-center'>This cannot be undone!</p>
            <div className='mt-4 p-2'>
              <button type='button' onClick={() => { setShowDeleteModal(false) }} className='btn btn-success'>No</button>
              <button type='button' onClick={() => { deleteSection(currentSectionIndex); setShowDeleteModal(false) }} className='btn btn-danger float-end'>Yes</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showReplacementQuestionModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowReplacementQuestionModal(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Replacement Questions</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
              validationSchema={{}} initialValues={initialValues} onSubmit={() => { }}>
              {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form>
                  {replacementSections?.map((section: any, index: number) => {
                    return <>
                      <div className="mb-8" id={'section' + index}>
                        <button type="button" className="btn btn-success m-2 p-2" onClick={() => { let newSections = [...sections]; newSections[currentSectionIndex] = section; setSections(newSections); setAllSections([...allSections, ...[section._id]]); setShowReplacementQuestionModal(false) }} style={{ zIndex: 1000 }}>Use This</button>
                      </div>
                      {ViewSection(section, true, 1, errors, section, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword') ? { ...section, ...{ correct: true } } : ((section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'dragdrop' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives' || section.type == 'short') ? { ...section, ...{ correct: false } } : section)))}
                      <hr />
                    </>
                  })}
                </Form>)}
            </Formik>
            <button type='button' onClick={() => { replaceSection(true) }} className='btn btn-dark float-end'>More</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { GenerateChallenge }
