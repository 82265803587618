import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup'
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';

type ApiParams = { batchId: string };
export const AttendanceReport = () => {
  const { batchId } = useParams<ApiParams>();
  let [batch, setBatch] = useState<any>({});
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    batchInit();
  }, [])
  const batchInit = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-min/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatch(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const sendMessage = () => {
    DisplayLoader(true, '')
    let startDate = (document.getElementById('startdate') as HTMLInputElement).value;
    let endDate = (document.getElementById('enddate') as HTMLInputElement).value;
    let channels: any = [];
    if ((document.getElementById('sms') as HTMLInputElement).checked) {
      channels.push('sms')
    }
    if ((document.getElementById('email') as HTMLInputElement).checked) {
      channels.push('email')
    }
    if ((document.getElementById('whatsapp') as HTMLInputElement).checked) {
      channels.push('whatsapp')
    }
    for (let i = 0; i < report.users.length; i++) {
      let user = report.users[i];
      let message = 'Dear Parent,\n' + user.name;
      if (report.userReport[user.username].missedSessions === 0) {
        message = message + ' attended all the session between ' + startDate + ' and ' + endDate;
      } else {
        message = message + ' was absent for the following sessions between ' + startDate + ' and ' + endDate;
        message = message + report.userReport[user.username].missedSessions?.map((session: any) => {
          return '\n' + new Date(session.startTime).toLocaleDateString() + ":" + new Date(session.startTime).toLocaleTimeString() + "-" + new Date(session.endTime).toLocaleTimeString();
        })
      }
      addMessage(message, channels, user.username)
    }
  }
  const addMessage = (message: string, channels: string[], username: string) => {
    let input: any = {};
    input.message = message;
    input.channels = channels;
    input.username = username;
    axios.post(process.env.REACT_APP_API_URL + '/messages/', input).then((reply) => {
      if (reply?.data?.success) {
        //noticeBoardInit();
        //setShowSendMessages(false);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const reports = () => {
    let input: any = {};
    input.startDate = new Date(new Date((document.getElementById('startdate') as HTMLInputElement).value).getTime() + new Date().getTimezoneOffset() * 60 * 1000);
    input.endDate = new Date(new Date((document.getElementById('enddate') as HTMLInputElement).value).getTime() + new Date().getTimezoneOffset() * 60 * 1000 + 24*60*60*1000);
    console.log(input)
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/attendance-reports/' + batchId, input).then((reply) => {
      if (reply?.data) {
        setReport(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  return (
    <div className='mt-1 bg-light shadow p-3'>
      <h3 className='text-center'>{batch ? (batch.name + ' - ') : ''}Attendance Reports</h3>
      <div className='text-center'>
        <strong>From Date</strong><input className='m-3' type='date' id='startdate'></input>
        <strong>To Date</strong><input className='m-3' type='date' id='enddate'></input>
        <button className='btn btn-primary' onClick={() => { reports() }}>Get</button>
      </div>
      <div>
        {report?.users && report?.users.length > 0 &&
          <strong className='float-end me-2'>Attendance Rate</strong>
        }
        {
          report?.users?.map((user: any, index: number) => {
            return <div className='p-2'>
              <button className="btn btn-default p-2 m-2 btn-block text-start shadow mb-0 border border-dark" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} role="button" aria-expanded="false" aria-controls={"collapse" + index} style={{ width: "100%" }}>
                {user.name}
                <button className='float-end btn btn-success rounded p-2'>{report.userReport[user.username].presentPercent}%</button>
              </button>
              <div className="collapse ms-4 bg-white p-3 border border-dark" id={"collapse" + index}>
                {report.userReport[user.username].missedSessions.length === 0 &&
                  <>Student is present in all the sessions</>
                }
                {report.userReport[user.username].missedSessions.length > 0 &&
                  <>
                    <h6>Absent Sessions</h6>
                    {
                      report.userReport[user.username].missedSessions?.map((session: any) => {
                        return <div>
                          {new Date(session.startTime).toLocaleDateString()} : {new Date(session.startTime).toLocaleTimeString()} - {new Date(session.endTime).toLocaleTimeString()}
                        </div>
                      })
                    }
                  </>
                }
                <div className='mt-2'>
                  <a href={'/delivery-status/' + user._id} style={{ fontSize: "12px" }}>Check message delivery</a>
                </div>
              </div>
            </div>
          })
        }
        {report?.users && report?.users.length > 0 &&
          <div className='p-5'>
            <div>
              <input type='checkbox' id='email'></input><span className='ps-2 pe-4'>Email</span>
              <input type='checkbox' id='sms'></input><span className='ps-2 pe-4'>SMS</span>
              <input type='checkbox' id='whatsapp'></input><span className='ps-2 pe-4'>Whatsapp</span>
            </div>
            <button className='btn btn-primary p-3' onClick={() => { sendMessage() }}>Send Message</button>
          </div>
        }
      </div>
    </div>
  )
}