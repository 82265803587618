import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { DisplayLoader } from '../../utils/loader';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { data } from '../stats/WeightagePU';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
type ApiParams = { id: string; userId: string };
const Reports: FC = () => {
  const { id, userId } = useParams<ApiParams>();
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);
  const parseTime = (time: number) => {
    const secs = Math.round(time % 60)
    const mins = Math.floor(time / 60)
    return (mins > 9 ? mins : '0' + mins) + ':' + (secs > 9 ? secs : '0' + secs)
  }
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-reports/' + id + (userId ? ('/' + userId) : '')).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      <h1 className='text-center m-3'>{report?.challenge?.heading}</h1>
      {userId && <h3 className='text-center m-4'>{report?.attempt?.name}</h3>}
      {(report?.challengeInstance?.type !== 'lesson' || userId) && report?.challengeInstance?.extraDetails && report?.challengeInstance?.extraDetails?.showReports != 'show' && <h3 className='m-2 mt-10 mb-10 text-center'>
        {report.challengeInstance.extraDetails.reportMessage}
      </h3>}
      {(report?.challengeInstance?.type !== 'lesson' || userId) && (userId || !report?.challengeInstance?.extraDetails?.showReports || report?.challengeInstance?.extraDetails?.showReports == 'show') && <>
        {report?.stats && <div>
          <div className='col-6 col-md-3 p-3 d-inline-block text-center '>
            <div className='text-center bg-success shadow-lg rounded p-4 pb-1 text-light'>
              <h4 className='d-inline-block text-light' style={{ fontSize: '26px' }}>{report.stats.rank || 1}</h4>/{report.stats.studentCount}
              <h6 className='text-light text-light'>Rank</h6>
            </div>
          </div>
          <div className='d-none d-md-inline-block col-6 p-3 text-center'></div>
          <div className='col-6 col-md-3 p-3 d-inline-block text-center'>
            <div className='text-center bg-default shadow-lg rounded p-4 pb-1'>
              <h4 className='text-dark' style={{ fontSize: '26px' }}><span className='text-success'>{report.stats.correct}</span> / <span className='text-danger'>{report.stats.wrong}</span></h4>
              <h6 className='text-dark'>Correct/Wrong</h6>
            </div>
          </div>
          <div className="progress-container ms-4 me-4 shadow" style={{ position: 'relative', marginTop: '70px', marginBottom: '70px' }}>
            <div className="progress shadow" style={{ height: "40px" }}>
              <div className="progress-bar bg-success rounded" role="progressbar" style={{ width: Math.max(0, Math.round(report.attempt.totalPoints * 100 / report.stats.totalPoints)) + "%" }} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}>
                {Math.round(report.attempt.totalPoints * 100 / report.stats.totalPoints) > 30 && <span style={{ fontSize: '14px' }}>Your Marks: <span style={{ fontSize: '18px' }}><b>{report.attempt.totalPoints}</b>/{report.stats.totalPoints}</span></span>}
              </div>
              {Math.round(report.attempt.totalPoints * 100 / report.stats.totalPoints) <= 30 && <div className="progress-bar bg-light rounded text-dark" role="progressbar" style={{ width: (100 - Math.round(report.attempt.totalPoints * 100 / report.stats.totalPoints)) + "%" }} aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}>
                <span style={{ fontSize: '14px' }}>Your Marks: <span style={{ fontSize: '18px' }}><b>{report.attempt.totalPoints}</b>/{report.stats.totalPoints}</span></span>
              </div>}
              {/* {Math.round(report.attempt.totalPoints * 100 / report.stats.totalPoints) <= 30 && <span style={{fontSize: '14px', right: 0}}>Your Marks: <span style={{fontSize: '18px'}}><b>{report.attempt.totalPoints}</b>/{report.stats.totalPoints}</span></span>} */}
            </div>
            <div style={{ left: Math.max(0, Math.round(report.stats.highest * 100 / report.stats.totalPoints)) + "%", position: 'absolute', bottom: '0px', width: '0', height: '50px', borderLeft: '3px solid black' }}></div>
            {Math.round(report.stats.highest * 100 / report.stats.totalPoints) > 80 && <div style={{ left: (Math.round(report.stats.highest * 100 / report.stats.totalPoints) - 5) + "%", position: 'absolute', top: '-30px', fontSize: '14px' }}>Highest: <b>{Math.round(report.stats.highest)}</b></div>}
            {Math.round(report.stats.highest * 100 / report.stats.totalPoints) <= 80 && <div style={{ left: Math.max(0, Math.round(report.stats.highest * 100 / report.stats.totalPoints)) + "%", position: 'absolute', top: '-30px', fontSize: '14px' }}>Highest: <b>{Math.round(report.stats.highest)}</b></div>}
            <div style={{ left: Math.max(0, Math.round(report.stats.average * 100 / report.stats.totalPoints)) + "%", position: 'absolute', top: '0px', width: '0', height: '50px', borderLeft: '3px solid black' }}></div>
            {Math.round(report.stats.average * 100 / report.stats.totalPoints) > 80 && <div style={{ left: (Math.round(report.stats.average * 100 / report.stats.totalPoints) - 5) + "%", position: 'absolute', bottom: '-30px', fontSize: '14px' }}>Average: <b>{Math.round(report.stats.average)}</b></div>}
            {Math.round(report.stats.average * 100 / report.stats.totalPoints) <= 80 && <div style={{ left: Math.max(0, Math.round(report.stats.average * 100) / report.stats.totalPoints) + "%", position: 'absolute', bottom: '-30px', fontSize: '14px' }}>Average: <b>{Math.round(report.stats.average)}</b></div>}
          </div>
          <div className='col-md-3 col-12 d-inline-block p-2' >
            <div className='shadow rounded' style={{ height: '500px' }}>
              <Bar data={{
                labels: ['Correct', 'Wrong', 'Unattempted'],
                datasets: [
                  {
                    label: 'Time Spent (in minutes)',
                    data: [Math.round(report.stats.correctAttemptTime / 60), Math.round(report.stats.wrongAttemptTime / 60), Math.round(report.stats.unAttemptedTime / 60)],
                    backgroundColor: ['#4CAF50', '#F44336', '#FFEB3B'],
                  },
                ],
              }} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: 'Utilisation of Time',
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Time (minutes)',
                    },
                  },
                },
              }} />
            </div>
          </div>
          <div className='col-md-9 col-12 d-inline-block p-2' >
            <div className='shadow rounded' style={{ height: '500px' }}>
              <Bar data={{
                labels: report.challenge.sections.map((item: any, index: number) => 'Q-' + (index + 1)),
                datasets:
                  Object.keys(report.stats.sectionTimeSpent).map((section: any) => {
                    return report.stats.sectionTimeSpent[section].progression.map((timeData: any) => {
                      let array = new Array(report.challenge.sections.length)
                      array[report.challenge.sections.indexOf(section)] = [...timeData.map((item: any) => (item / 60))];
                      return { data: array, skipNull: true, backgroundColor: 'black', maxBarThickness: 5 }
                    })
                  }).flat()
              }} options={{
                indexAxis: 'y' as const, // Makes the bars horizontal
                scales: {
                  x: {
                    max: (report.challengeInstance.totalTime || report.challenge.totalTime || 0),
                    beginAtZero: false,
                    title: {
                      display: true,
                      text: 'Time (minutes)',
                    },
                    stacked: true, // Stack the bars
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Questions',
                    },
                    stacked: true, // Stack the y-axis as well (if needed)
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: 'Timeline',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem: any) {
                        const relevantData = tooltipItem.dataset.data.filter((d: any) => d != null)[0]
                        return parseTime(relevantData[2] * 60) + '-' + parseTime((relevantData[2] + relevantData[3]) * 60);
                      }
                    }
                  },
                },
              }} />
            </div>
          </div>
        </div>}
        {(report.attempt && !report.attempt.completed && report.challengeInstance.type !== 'lesson') && <h5 className='text-center text-danger m-5'>*Not submitted</h5>}
        {(report.attempt && report.attempt.completed) && <h2 className='text-center mt-10 mb-10'>Detailed Report</h2>}
        {
          report.challenge?.sections?.map((section: string, index: number) => {
            return (
              <div className='m-2 shadow rounded p-4'>
                <div className='d-inline-block text-center bg-light shadow-lg rounded p-4 pb-1 mb-5'>
                  <b>{index + 1}</b>/{report.challenge.sections.length}<br />
                  <h6>Section</h6>
                </div>
                <div className={'d-inline-block float-end text-light text-center shadow-lg rounded p-4 pb-1 mb-5 ' + (report.attempt.sections[section]?.points > 0 ? 'bg-success' : ((report.attempt.sections[section]?.answer != null && report.attempt.sections[section]?.answer != '') ? 'bg-danger' : 'bg-info'))}>
                  <b>{report.attempt.sections[section]?.points ? report.attempt.sections[section]?.points : (report.attempt.sections[section]?.answer ? 0 : '-')}</b>/{report.sectionMap[section].points}<br />
                  <h6 className='text-light'>Marks</h6>
                </div>
                {<Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                  validationSchema={{}} initialValues={report.attempt.sections[section]} onSubmit={() => { }}>
                  {({ errors, touched, values, setFieldValue, setErrors }) => (
                    <Form>
                      {ViewSection(report.sectionMap[section], true, 1, errors, values, setFieldValue, report.attempt.sections[section] ? report.attempt.sections[section] : { ...report.sectionMap[section], ...{ correct: false } }, true)}
                    </Form>
                  )}
                </Formik>}
                <hr />
              </div>
            )
          })
        }
      </>}
    </div >
  )
}

export { Reports }
