import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import { enIN } from 'date-fns/locale/en-IN';
import { DisplayLoader } from '../../utils/loader';
import { Modal } from 'react-bootstrap';
import { relative } from 'path';
type ApiParams = { startDate: string, endDate: string, batch: string };
const OfflineReportsSummary: FC = () => {
  const location = useLocation();
  let [report, setReport] = useState<any>({});
  let [showModal, setShowModal] = useState<boolean>(false);
  const [filterStartDate, setFilterStartDate] = useState<any>();
  const [filterEndDate, setFilterEndDate] = useState<any>();
  const [filterBatch, setFilterBatch] = useState<any>();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const batch = queryParams.get('batch');

  registerLocale('enIN', enIN)
  useEffect(() => {
    getReports();
  }, []);
  useEffect(() => {
    getReports()
    setShowModal(false);
  }, [startDate, endDate, batch]);
  const resetFilters = () => {
    setFilterStartDate('')
    setFilterEndDate('')
    setFilterBatch(null)
  }
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-offline-reports?summary=true&startDate=' + startDate + '&endDate=' + endDate + '&batch=' + batch).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      <h1 className='text-center' style={{ position: 'relative' }}>Class Reports - Summary
        <button className='btn btn-dark p-2 no-print' style={{ position: 'absolute', right: '50px' }} onClick={() => { setShowModal(true) }}><i className='fa fa-filter pe-0'></i></button>
        <Link to={'/class-reports'} style={{ position: 'absolute', right: 0 }} className='no-print btn btn-dark p-2 me-2'><i className='fa fa-list-alt pe-0'></i></Link>
      </h1>
      {report.summary && <div className='p-2'>
        <div className='text-center'>
          <h5>
            {batch && batch.length > 9 && <b>{report.batchList.filter((b: any) => b._id == batch)[0]?.name}</b>}
            {batch && batch.length > 9 && (!isNaN(parseInt(startDate || '')) || !isNaN(parseInt(endDate || ''))) && <> - </>}
            {(!isNaN(parseInt(startDate || '')) || !isNaN(parseInt(endDate || ''))) &&
              <b className='ms-4'>
                {(startDate && !isNaN(parseInt(startDate || ''))) ? new Date(parseInt(startDate)).toLocaleDateString('en-IN') + ' - ' : 'until '}
                {(endDate && !isNaN(parseInt(endDate || ''))) ? new Date(parseInt(endDate)).toLocaleDateString('en-IN') : 'Now'}
              </b>
            }
          </h5>
        </div>
        {
          report.batchNames.map((batchName: string) => {
            if (!report.summary[report.batchNameIdMap[batchName]]) return
            return <div className='p-2 row rounded shadow mt-5 m-3'>
              {report.summary[report.batchNameIdMap[batchName]] && <div className='row'>
                <div className='col-12'>
                  <div className='text-end mt-5'>
                    <h1>{report.batchList.filter((batch: any) => batch._id == report.batchNameIdMap[batchName])[0]?.name}</h1>
                  </div>
                  <div className='row'>
                    <div className='col-9 p-3'>
                      <h4 className='ps-3 col-12'>Top Performers</h4>
                      <div className='col-4 p-3 d-inline-block align-top'>
                        <div className='rounded shadow p-2'>
                          <div className='row mb-3' style={{ fontSize: '32px' }}>
                            <div className='col-5 text-center'><i style={{ fontSize: '32px', color: 'gold' }} className='fa fa-medal'></i></div>
                            <div className='col-7 text-end'>{Math.round(parseInt(report.summary[report.batchNameIdMap[batchName]].studentPoints[0][1]) * 100 / report.summary[report.batchNameIdMap[batchName]].totalPoints)}%</div>
                          </div>
                          <div className='text-center'>
                            <b>{report.userDetails[report.summary[report.batchNameIdMap[batchName]].studentPoints[0][0]]}</b>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 p-3 d-inline-block'>
                        <table className='table table-bordered'>
                          {report.summary[report.batchNameIdMap[batchName]].studentPoints.slice(1, 4).map((student: string, index: number) => {
                            return <tr>
                              <td className='p-1'><b>{report.userDetails[student[0]]}</b></td>
                              <td className='p-1'>{Math.round(parseInt(student[1]) * 100 / report.summary[report.batchNameIdMap[batchName]].totalPoints)}%</td>
                            </tr>
                          })}
                        </table>
                      </div>
                    </div>
                    <div className='col-3 p-3'>
                      {/* <h4 className='text-end pe-3 col-12'>Students needing personal attention</h4> */}
                      <div className='col-6 p-3 d-inline-block'></div>
                      <div className='col-6 p-3 d-inline-block'>
                        {/* {report.summary[report.batchNameIdMap[batchName]].studentPoints.slice(report.summary[report.batchNameIdMap[batchName]].studentPoints.length - 3, report.summary[report.batchNameIdMap[batchName]].studentPoints.length).map((student: string, index: number) => {
                          return <tr>
                            <td className='p-1'><b>{report.userDetails[student[0]]}</b></td>
                            <td className='p-1'>{Math.round(parseInt(student[1]) * 100 / report.summary[report.batchNameIdMap[batchName]].totalPoints)}%</td>
                          </tr>
                        }).reverse()} */}
                      </div>
                    </div>
                    {/*report.summary[report.batchNameIdMap[batchName]].studentPoints.slice(0, 4).map((student: string, index: number) => {
                      return <div className='col-3 p-3'>
                        <div className='rounded shadow p-2'>
                          <div className='row mb-3' style={{ fontSize: '32px' }}>
                            <div className='col-5 text-center'><i style={{ fontSize: '32px', color: (index == 0) ? 'gold' : (index == 1) ? 'silver' : '#CD7F32' }} className='fa fa-medal'></i></div>
                            <div className='col-7 text-end'>{Math.round(parseInt(student[1]) * 100 / report.summary[report.batchNameIdMap[batchName]].totalPoints)}%</div>
                          </div>
                          <div className='text-center'>
                            <b>{report.userDetails[student[0]]}</b>
                          </div>
                        </div>
                      </div>
                    })*/}

                  </div>
                </div>
                <div className='col-12'>
                  <h4 className='text-center'>Classes Taken by Teachers</h4>
                  {Object.keys(report.summary[report.batchNameIdMap[batchName]].teacherSessions).map((teacher: string) => {
                    return <div className='shadow rounded row m-2'>
                      <div className='col-3 bg-primary rounded text-light p-2'>
                        <h4 className='text-center text-light'>{report.userDetails[teacher]}</h4>
                        <div className='text-center' style={{ fontSize: '28px' }}>{report.summary[report.batchNameIdMap[batchName]].teacherSessions[teacher].sessionCount}</div>
                        <div className='text-center'>Periods</div>
                      </div>
                      <div className='col-9 p-2 ps-4'>
                        <ul style={{ fontWeight: 'bold' }}>
                          {report.summary[report.batchNameIdMap[batchName]].teacherSessions[teacher].topics.map((topic: string) => {
                            return report.challengeInstanceDependencyMap.filter((c: any) => c.id == topic)?.[0]?.dependency?.[1]
                          }).filter((value: any, index: any, self: any) => self.indexOf(value) === index).map((value: string) => {
                            return <li>{value}</li>
                          })}
                        </ul>
                      </div>
                    </div>
                  })}
                </div>

              </div>}
              {/* <div className='border border-dark rounded-top col-12 p-2'>
                <h3>{report.batchList.filter((batch: any) => batch._id == report.batchNameIdMap[batchName])[0]?.name}</h3>
              </div>

              <div className='col-6 border border-dark p-2'>
                <h6>Total no. of Periods</h6> {JSON.stringify(Object.values(report.summary[report.batchNameIdMap[batchName]].teacherSessions).reduce((accumulator: any, currentValue: any) => accumulator + currentValue.sessionCount, 0))}
                <h6 className='mt-2'>No. of periods by teachers</h6>
                {Object.keys(report.summary[report.batchNameIdMap[batchName]].teacherSessions).map((teacher: string) => {
                  return <div>{report.userDetails[teacher]}
                    <div>{report.summary[report.batchNameIdMap[batchName]].teacherSessions[teacher].sessionCount}</div>
                    <div>{report.summary[report.batchNameIdMap[batchName]].teacherSessions[teacher].topics.map((topic: string) => {
                      return report.challengeInstanceDependencyMap.filter((c: any) => c.id == topic)?.[0]?.dependency?.[1]
                    }).filter((value: any, index: any, self: any) => self.indexOf(value) === index).map((value: string) => {
                      return <div>{value}</div>
                    })}</div>
                  </div>
                })}
              </div>
              <div className='col-6 border border-dark p-2'>
                <h6>Best Performing Students</h6>
                {report.summary[report.batchNameIdMap[batchName]].studentPoints.slice(0, 3).map((student: string) => {
                  return <div>{report.userDetails[student[0]]}</div>
                })}
                <h5 className='mt-2'>Students needing attention</h5>
                {report.summary[report.batchNameIdMap[batchName]].studentPoints.slice(report.summary[report.batchNameIdMap[batchName]].studentPoints.length - 3, report.summary[report.batchNameIdMap[batchName]].studentPoints.length).map((student: string) => {
                  return <div>{report.userDetails[student[0]]}</div>
                })}
              </div> */}

            </div>
          })
        }
      </div>
      }
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Filter by Dates</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            {report.batchList && <div className='p-3 pb-4 shadow rounded'>
              <div className='m-2 row'>
                <div className='col-6'><strong>Batch</strong></div>
                <div className='col-6 '>
                  <Select className='ms-4' value={filterBatch} name='batch' id='batch' options={report.batchList.map((batch: any) => { return { value: batch._id, label: batch.name } })} onChange={(value) => { setFilterBatch(value) }} />
                </div>
              </div>
              <div className='m-2 row'>
                <div className='col-6'><strong>From</strong></div>
                <div className='col-6 '><DatePicker locale={'enIN'} selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} placeholderText='from' className='ms-4' /></div>
              </div>
              <div className='m-2 row'>
                <div className='col-6 '><strong>To</strong></div>
                <div className='col-6 '><DatePicker locale={'enIN'} selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} placeholderText='to' className='ms-4' /></div>
              </div>
              <div>
                <div>
                  <button className='btn btn-dark p-2' onClick={resetFilters}>Reset Filters</button>
                  <Link to={'/class-reports-summary?startDate=' + new Date(filterStartDate).getTime() + '&endDate=' + new Date(filterEndDate).getTime() + '&batch=' + filterBatch?.value} className='p-2 btn btn-dark float-end'>Submit</Link>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { OfflineReportsSummary }
