/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'

import axios from 'axios'
import { useAuth } from '../../modules/auth';
import { Link } from 'react-router-dom';
import { StudentReports } from '../reports/StudentReports';

const DashboardWrapper: FC = () => {
  const { currentUser } = useAuth()
  let [challenges, setChallenges] = useState<any>([]);
  let [folders, setFolders] = useState<any>([]);
  let [folderSequence, setFolderSequence] = useState<any>([]);
  let [dashboardState, setDashboardState] = useState<string>('lessons');
  let [dashboardSubState, setDashboardSubState] = useState<string>('active');
  let [activeChallenges, setActiveChallenges] = useState<any>([]);
  let [upcomingChallenges, setUpcomingChallenges] = useState<any>([]);
  let [completedChallenges, setCompletedChallenges] = useState<any>([]);
  let [currentFolderStructure, setCurrentFolderStructure] = useState<any>([]);
  let [currentChallengeInstances, setCurrentChallengeInstances] = useState<any>([]);
  const setFolderStructure = () => {
    if (folders.length === 0) return;
    let folderStructure: any = folders;
    let challengeInstances: any = [];
    /*
    folderSequence.forEach((index) => {
      if (folderStructure[index].challengeInstances) {
        challengeInstances = folderStructure[index].challengeInstances;
      }
      folderStructure = folderStructure[index].folderStructure;
    })
    */
    setCurrentFolderStructure(folderStructure)
    setCurrentChallengeInstances(challengeInstances)
  }
  const getChallengeInstances = (type: string) => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-instances-by-user/' + type).then((reply) => {
      console.log("here")
      if (reply?.data?.challengeInstances) {
        setCompletedChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.end) < new Date() || (challenge.attemptDetails.length > 0 && challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return -1
          return Date.parse(b.end) - Date.parse(a.end);
        }));
        setUpcomingChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.start) > new Date()
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
        setActiveChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return ((new Date(challenge.start) < new Date() && new Date(challenge.end) > new Date()) || !challenge.start)
            && (challenge.attemptDetails.length === 0 || !challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
      } else {
        //TODO
      }
    })
  }
  const listFolders = (folders: any, level: number, parent: string) => {
    return <>
      {folders.folderStructure?.map((folder: any, index: number) => {
        return <div className="accordion-item shadow mb-1">
          <h2 className="accordion-header" id={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
            <button className="accordion-button collapsed p-3" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} aria-expanded="true" aria-controls={"collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
              <h5>{folder.name}</h5>
            </button>
          </h2>
          <div id={"collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} className="accordion-collapse collapse" aria-labelledby={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level} data-bs-parent={"#" + parent}>
            <div className="accordion-body">
              {listFolders(folder, (level + 1), "collapse" + index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level)}
            </div>
          </div>
        </div>
      })}
      {folders.challengeInstances?.map((folder: any, index: number) => {
        return <div className="accordion-item shadow mb-2">
          <h2 className="accordion-header p-3" id={index + folder.name.replaceAll(/\s/g, '').replaceAll(/'/g, '') + level}>
            <Link to={'lesson/' + folder.id} style={{ width: "100%" }}><h5><i className="fa fa-book me-3 text-info"></i>{folder.name}</h5></Link>
          </h2>
        </div>
      })}
    </>
  }
  useEffect(() => {
    setFolderStructure();
  }, [folders, folderSequence])
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/get-folder-structure/').then((reply) => {
      if (reply?.data?.folders) {
        setFolders([reply.data.folders]);
        setFolderSequence([reply.data.folders]);
      } else {
        setDashboardState('tests'); getChallengeInstances('test')
        //TODO
      }
    })
  }, []);
  return (
    <div className='ms-4 me-4 bg-white'>
      {currentUser?.role !== 'student' && <div className='m-4'>

        { }
      </div>}
      {currentUser?.role !== 'student' && <div className='mt-2 p-3 border-top border-dark border-2 rounded row'>
        <div className='col-12 d-inline-block shadow-lg pt-5'>
          <div className='col-12 col-md-3 p-2 d-inline-block'>
            <Link to='/batches' className='btn btn-info btn-block' style={{ width: "100%" }}>
            <div><i className="fa fa-users m-3" style={{fontSize: '24px'}}></i></div>
            Batches</Link>
          </div>
          <div className='col-12 col-md-3 p-2 d-inline-block'>
            <Link to='/all-challenges' className='btn btn-dark btn-block' style={{ width: "100%" }}>
            <div><i className="fa fa-book m-3" style={{fontSize: '24px'}}></i></div>All Modules</Link>
          </div>
          <div className='col-12 col-md-3 p-2 d-inline-block'>
            <Link to='/class-reports' className='btn btn-dark btn-block' style={{ width: "100%" }}>
            <div><i className="fa fa-bar-chart m-3" style={{fontSize: '24px'}}></i></div>Class Reports</Link>
          </div>
          <div className='col-12 col-md-3 p-2 d-inline-block'>
            <Link to="/generate-test" className='btn btn-dark btn-block' style={{ width: "100%" }}>
            <div><i className="fa fa-gears m-3" style={{fontSize: '24px'}}></i></div>Generate Test/Assignment</Link>
          </div>
          <div className='col-12 p-2 d-inline-block'>
            <h3 className='text-center'>Other Links</h3>
          </div>
          <div className='col-12 p-2 d-inline-block'>
            <Link to="/review" className='btn btn-info btn-block' style={{ width: "100%" }}>Review</Link>
          </div>
          <div className='col-12 p-2 d-inline-block'>
            <Link to="/create-module" className='btn btn-info btn-block' style={{ width: "100%" }}>+ New Module</Link>
          </div>

          {/**
          <div className='col-12 p-2 d-inline-block'>
            <Link to='' className='btn btn-dark btn-block' style={{ width: "100%" }}><i className="fa fa-hand me-3"></i>Attendance (Coming Soon)</Link>
          </div>
          <div className='col-12 p-2 d-inline-block'>
            <Link to='' className='btn btn-dark btn-block' style={{ width: "100%" }}><i className="fa fa-calendar me-3"></i>Time Table (Coming Soon)</Link>
          </div>
           */}
        </div>
        {/* <div className='col-12 col-md-9 d-inline-block'>
          <div className='p-4 shadow-lg rounded'>
            <h3 className='text-center'>My Courses</h3>
            <div className="accordion animate__animated animate__fadeIn" id="lessonList">
              {folders && folders.length > 0 && listFolders(folders[0], 0, 'lessonList')}
            </div>
            <div>
              {(!folders || folders.length === 0) && <div className='text-center'>
                <div className="spinner-border text-primary m-3" role="status"></div><div><strong>Creating custom courses for your profile. Please wait. It might take a few minutes.</strong></div></div>}
            </div>
          </div>
        </div> */}
      </div>}
      <div>
        {
          currentUser?.role === 'student' &&
          <>
            {window.location.origin.indexOf('quest') !== -1 && <div className=' text-center'>
              <Link to='/scholar' target='_blank' className='btn btn-info btn-block  mb-5 mt-5 shadow-lg'>
                <h2 className='text-light mb-0'><i className="fa fa-graduation-cap me-3" />Olearno K-12 Scholarships</h2>
              </Link>
            </div>}
            <h6 className='mb-0'>
              <div onClick={() => { setDashboardState('lessons') }} className={(dashboardState === 'lessons' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Lessons</div>
              <div onClick={() => { setDashboardState('assignments'); getChallengeInstances('assignment') }} className={(dashboardState === 'assignments' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Assignments</div>
              <div onClick={() => { setDashboardState('tests'); getChallengeInstances('test') }} className={(dashboardState === 'tests' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Tests</div>
              <div onClick={() => { setDashboardState('reports'); }} className={(dashboardState === 'reports' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Reports</div>
            </h6>
            <div className='border-top border-dark p-2 shadow pe-4'>
              {dashboardState === 'lessons' && <div>
                <div className="accordion animate__animated animate__fadeIn" id="lessonList">
                  {folders && folders.length > 0 && listFolders(folders[0], 0, 'lessonList')}
                </div>
              </div>}
              {(dashboardState === 'reports') && <>
                <StudentReports />
              </>}
              {(dashboardState === 'assignments' || dashboardState === 'tests') && <>
                <h5 className='text-center mb-4'>{dashboardState === 'assignments' ? 'Assignments' : 'Tests'}</h5>
                {/* <div className='p-2 d-inline-block'>
                  <Link to="/generate" className='btn btn-info float-end' style={{ width: "100%" }}>Generate Your own</Link>
                </div> */}
                <h6 className='mb-0'>
                  <div onClick={() => { setDashboardSubState('active') }} className={(dashboardSubState === 'active' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Active</div>
                  <div onClick={() => { setDashboardSubState('upcoming') }} className={(dashboardSubState === 'upcoming' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Upcoming</div>
                  <div onClick={() => { setDashboardSubState('completed') }} className={(dashboardSubState === 'completed' ? 'bg-dark text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Completed</div>
                </h6>
                <div className='border-top border-dark p-2 shadow pe-4'>
                  <div className='mt-3'>
                    {dashboardSubState === 'active' &&
                      <div>
                        {activeChallenges.map((challenge: any, index: number) => {
                          return <Link to={(dashboardState === 'assignments' ? '/assignment/' : '/testseries/') + challenge._id} className='p-3 shadow card mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}><h5>{challenge.challengeDetails[0].heading}</h5>
                            {challenge.end && <div className='d-inline-block' style={{ fontSize: '10px' }}>Ends On - <b>{new Date(challenge.end).toLocaleString()}</b></div>}
                            {dashboardState === 'tests' && <div className='float-end d-inline-block' style={{ fontSize: '10px' }}>Total Time - <b>{challenge.totalTime} Mins</b></div>}
                          </Link>
                        })}
                      </div>
                    }
                    {dashboardSubState === 'upcoming' &&
                      <div>
                        {upcomingChallenges.map((challenge: any, index: number) => {
                          return <div className='p-3 shadow card d-flex mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}><h5>{challenge.challengeDetails[0].heading}</h5>
                            <span style={{ fontSize: '10px' }}>Starts On - {new Date(challenge.start).toLocaleString()}</span>
                          </div>
                        })}
                      </div>
                    }
                    {dashboardSubState === 'completed' &&
                      <div>
                        {completedChallenges.map((challenge: any, index: number) => {
                          return <a href={'/reports/' + challenge._id} className='p-3 shadow card mb-3 text-dark animate__animated animate__fadeIn' key={index + challenge.challengeDetails[0].heading + dashboardState}>
                            <h5>{challenge.challengeDetails[0].heading}</h5>
                          </a>
                        })}
                      </div>
                    }
                  </div>
                </div>
              </>}
            </div>
          </>
        }
      </div>
    </div>
  )
}

export { DashboardWrapper }
