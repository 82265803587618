import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const FirebaseUpload = (file : any, url: string, cb: Function) => {
  if (!file) return;
  var file = file;
  console.log(url)
  const storageRef = ref(storage, 'engage/' + url);
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // Handle unsuccessful uploads
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        cb(downloadURL, {
          title: file.name
        });
      });
    }
  );
}