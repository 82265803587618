import KaTeXRenderer from "../pages/utils/KatexRenderer";
import SequenceHorizontal from "../modules/components/display/displayquestiontypes/SequenceHorizontal";
import SequenceVertical from "../modules/components/display/displayquestiontypes/SequenceVertical";
import DragDrop from "../modules/components/display/displayquestiontypes/DragAndDrop";

export const showAttempt = (section: any, attempt: any) => {
  const isMatch = (index: any, category: any, answer: any, options: any) => {
    try {
      console.log(answer)
      let ans = answer.filter((a: any) => { return a.split('-')[0] == index })[0]
      if (ans) {
        let ansRight = ans.split('-')[1]
        return category == options[ansRight][1]
      }
    } catch {
      console.log(attempt)
      console.log(section)
      return false
    }
  };
  return <>{attempt && attempt.answer && <span>
    {(section.type == 'short' || section.type == 'long' || section.type == 'numerical') && <span><KaTeXRenderer>{attempt.answer || ''}</KaTeXRenderer> </span>}
    {(section.type == 'dragdrop') && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded'} style={{ pointerEvents: 'none' }}>
      <DragDrop setFieldValue={() => { }} itemsIn={[...[
        { id: 'question', items: [] }
      ],
      ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: section.options.map((opt: string[]) => opt[0]).filter((opt1: string, index: number) => { return opt1 != '' && isMatch(index, opt, attempt.answer, section.options) }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) } })]}></DragDrop>
    </div>}
    {(section.type == 'verticalsequence') && <div style={{ pointerEvents: 'none' }}><SequenceVertical setFieldValue={(() => { })} itemsIn={attempt.answer.map((option: string, index: number) => {
      return ({ id: index.toString(), name: option, index: index })
    })}></SequenceVertical></div>}
    {(section.type == 'horizontalsequence') && <div style={{ pointerEvents: 'none' }}><SequenceHorizontal setFieldValue={() => { }} itemsIn={attempt.answer.map((option: string, index: number) => {
      return ({ id: index.toString(), name: option, index: index })
    })}></SequenceHorizontal></div>}
    {(section.type == '7lives') && <>
      {attempt.answer.split('').map((l: string) => { return <>{(l == '#' ? 'CLUE' : l) + ','}</> })}
    </>}
    {(section.type == 'crossword') && <>
    </>}
    {(section.type == 'imageselect') && <>{/*<div className='animate__animated animate__bounceInUp' style={{maxHeight: '200px'}}>
        <AnnotatableImageView
          section={section}
          disabled={true}
          values={{}}
          attempt={answer}
          setFieldValue={()=>{}} />
    </div>*/}</>}
    {(section.type == 'matrix') && <span>{section?.options?.map((option: any, index: number) => {
      return (
        <>
          {option[0] && option[0].trim() != '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
            {section?.options?.map((option: any, index1: number) => {
              return <>{option[1] && option[1].trim() != '' && <td><input className='m-2' type='checkbox' name='answer' checked={attempt.answer && attempt.answer.indexOf(index.toString() + '-' + index1.toString()) != -1} value={index.toString() + '-' + index1.toString()} style={{ height: "15px", width: "15px" }} disabled={true} /></td>}</>
            })}
          </tr>}
        </>
      )
    })}</span>}
    {(section.type == 'blanks' || section.type == 'blanks-any') && <span>{attempt.answer?.map((a: string) => { return <KaTeXRenderer>{(a || '') + ','}</KaTeXRenderer> })}</span>}
    {(section.type == 'multiple') && <span>{attempt.answer.map((a: string) => { return <KaTeXRenderer>{(section.options[a] || '') + ','}</KaTeXRenderer> })}</span>}
    {(section.type == 'mcq') && <KaTeXRenderer>{section.options[attempt.answer] || ''}</KaTeXRenderer>}
  </span>
  }
    {!attempt && <span>-</span>}</>
}
export default showAttempt;
