/* eslint-disable jsx-a11y/anchor-is-valid */
import { Section } from '../../../models/Section'
import KaTeXRenderer from '../../../pages/utils/KatexRenderer';
import "katex/dist/katex.min.css";
import clg from 'crossword-layout-generator';
import { systemSort } from '../../../utils/systemSort';
import DragDrop from './displayquestiontypes/DragAndDrop';
import SequenceHorizontal from './displayquestiontypes/SequenceHorizontal';
import SequenceVertical from './displayquestiontypes/SequenceVertical';
import AnnotatableImageView from './displayquestiontypes/ImageAnnotationView';
import MindMapQuestion from './displayquestiontypes/MindMapFill';
import { CrossWord } from './displayquestiontypes/Crossword';
export const PrintSection = (section: Section, showAnswer?: boolean, showExplanation?: boolean, questionNo?: number, showOnlyExplanation?: number) => {
  const parseQuestion = (question: string, type: string) => {
    if (type === 'blanks' || type === 'blanks-any') {
      return question.replace(/#blank([0-9])#/g, '________');
    }
    if (type === 'click') {
      let q = question.replace(/<ca>|&lt;ca&gt;|<wa>|&lt;wa&gt;|<ba>|&lt;ba&gt;/g, '<button type="button" class="btn btn-info" onClick={function(e){blankClick(e)}}>').replace(/<\/ca>|&lt;\/ca&gt;|<\/wa>|&lt;\/wa&gt;|<\/ba>|&lt;\/ba&gt;/g, '</button>');
      console.log(q);
      return q;
    }
    return '';
  }
  const parseBlankAnswer = (text: string) => {
    const answerLength = text.match(/#blank([0-9])#/g)?.length || 0;
    let answer = text;
    for (let i = 0; i < answerLength; i++) {
      answer = answer.replaceAll("#blank" + (i + 1) + "#", '<button type="button" class="btn btn-info ms-2 me-2 mb-2" style="min-width: 100px" name="blanksQuestion' + (i + 1) + '" disabled>' + section.options[i] + '</button>')
    }
    return answer;
  }
  const parseCrossword = (options: string[][]) => {
    let result = clg.generateLayout(options.map((option: string[]) => { return { clue: option[1].replace(/<[^>]*>?/gm, ''), answer: option[0].replace(/<[^>]*>?/gm, '').toUpperCase() } })).result;
    let crosswordData: any = {
      'across': {},
      'down': {},
    }
    console.log(result)
    let crosswordIndexInfo: any = {}
    result.forEach((word: any, index: number) => {
      if (!crosswordIndexInfo[word.position]) {
        crosswordIndexInfo[word.position] = {};
      }
      if (word.orientation == 'across') {
        crosswordData['across'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
        //crosswordData['across'][crosswordIndexInfo[word.position]] = { clue: word.clue, answer: options.filter((opt: string[]) => { return opt[0].replace(/<[^>]*>?/gm, '') == word.wordStr })[0][1].replace(/<[^>]*>?/gm, ''), row: word.xNum, col: word.yNum }
      } else {
        crosswordData['down'][word.position] = { clue: word.clue, answer: word.answer, row: word.starty - 1, col: word.startx - 1 }
      }
    })
    return crosswordData;
  }
  const isMatch = (index: any, category: any, answer: any, options: any) => {
    let ans = answer.filter((a: any) => { return a.split('-')[0] == index })[0]
    if (ans) {
      let ansRight = ans.split('-')[1]
      return category == options[ansRight][1]
    }
  };
  return (
    <div className='text-dark' >
      {(section.type === 'slide' || section.type === 'simulation') && <>
        <div>
          <KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer>
        </div>
      </>
      }
      {(section.type === 'mcq' || section.type === 'multiple') && <>
        <div style={{ display: 'flex', alignItems: 'top' }}>
          <div style={{ width: '20px' }}>
            {questionNo ? questionNo + ')   ' : ''}
          </div>
          <div style={{ flex: '1' }}>
            <KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer>
          </div>
        </div>


        {/* <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div> */}
        {section?.options?.map((option: any, index: number) => {
          return (
            <>
              <div className='col-11 d-inline-block align-top pt-1 compact' style={{marginLeft : '20px'}}><KaTeXRenderer>{'(' + (index + 1) + ')  ' + option}</KaTeXRenderer></div>
            </>
          )
        })}
      </>}
      {(section.type === 'numerical' || section.type === 'short' || section.type === 'long') && <>
        <div>
          <b><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></b>
        </div>
      </>}
      {(section.type === 'blanks' || section.type === 'blanks-any') && <>
        <div>
          <b><KaTeXRenderer>{parseQuestion(section.text ? section.text : '', section.type)}</KaTeXRenderer></b>
        </div>
        <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
        {systemSort([...section.options])?.map((option: any, index: number) => {
          return (
            <button className='m-2 p-2'>
              <KaTeXRenderer>{option || ''}</KaTeXRenderer>
            </button>
          )
        })}
      </>}
      {section.type === 'pair' && <div>
        {systemSort([...section.options])?.map((option: string, index: number) => {
          return (<button className='m-2 p-2'>
            <KaTeXRenderer>{option || ''}</KaTeXRenderer>
          </button>)
        })}
      </div>}
      {section.type === 'click' && <><div>
        <></>
      </div></>}
      {section.type === 'matrix' && <>
        <b><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></b>
        {section?.options?.map((option: any, index: number) => {
          return (
            <div className='row mb-1 justify-content-center align-items-center' key={index}>
              <div className='col-1 text-end'>{(option[0] && option[0] != '') ? ((index + 1) + '.') : ''}</div>
              <div className='col-5'><KaTeXRenderer>{option[0]}</KaTeXRenderer></div>
              <div className='col-1 text-end'>{(option[1] && option[1] != '') ? (String.fromCharCode(index + 65).toUpperCase() + '.') : ''}</div>
              <div className='col-5'><KaTeXRenderer>{option[1]}</KaTeXRenderer></div>
            </div>
          )
        })}
      </>}
      {section.type == '7lives' && <div className='text-dark text-center'>
        <div className='text-dark mb-1'><b>Guess the word</b></div>
        {
          section.text?.replace(/(<([^>]+)>)/ig, '').toLowerCase().split('').map((w) => {
            if (w == ' ') {
              return <span style={{ margin: '2px', fontSize: '24px', minWidth: '24px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            }
            return <span style={{ margin: '2px', fontSize: '24px', minWidth: '24px' }}>{w == ' ' ? '      ' : '_'}</span>
          })
        }
        <div><b>Clues</b></div>
        <div>{section.options?.map((opt: string) => { return <KaTeXRenderer>{opt || ''}</KaTeXRenderer> })}</div>
      </div>}
      {section.type == 'dragdrop' && <>
        <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        <div>
          <div>
            <DragDrop setFieldValue={() => { }} itemsIn={[...[
              { id: 'question', items: section.options.map((opt: string[]) => opt[0]).filter((opt: string) => { return opt != '' }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) }
            ],
            ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: [] } })]}></DragDrop>
          </div>
        </div>
      </>}
      {section.type == 'horizontalsequence' && <><div>
        <div className='text-dark animate__animated animate__bounceInUp'>Arrange in correct order</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        </div>
        <div className=' animate__animated animate__fadeIn ' style={{ animationDelay: '0.25s' }}>
          <SequenceHorizontal setFieldValue={() => { }} itemsIn={[...section.options].sort((a: string, b: string) => {
            const randomTrueOrFalse = (a.length + Array.from(a + b).reduce((sum, char) => sum + char.charCodeAt(0) - 'A'.charCodeAt(0) + 1, 0)) % 2 == 1;
            return randomTrueOrFalse ? 1 : -1
          })?.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceHorizontal>
        </div>
      </div></>}
      {section.type == 'verticalsequence' && <><div>
        <div className='text-dark animate__animated animate__bounceInUp'>Arrange in correct order</div>
        <div className='animate__animated animate__bounceInUp'>
          <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        </div>
        <div className=' animate__animated animate__fadeIn ' style={{ animationDelay: '0.25s' }}>
          <SequenceVertical setFieldValue={() => { }} itemsIn={[...section.options].sort((a: string, b: string) => {
            const randomTrueOrFalse = (a.length + Array.from(a + b).reduce((sum, char) => sum + char.charCodeAt(0) - 'A'.charCodeAt(0) + 1, 0)) % 2 == 1;
            return randomTrueOrFalse ? 1 : -1
          })?.map((option: string, index: number) => {
            return ({ id: index.toString(), name: option, index: index })
          })}></SequenceVertical>
        </div>
      </div></>}
      {section.type == 'imageselect' && !showAnswer && <div className='animate__animated animate__bounceInUp'>
        <AnnotatableImageView
          section={section}
          disabled={true}
          values={{}}
          attempt={{}}
          setFieldValue={() => { }} />
      </div>}
      {section.type == 'mindmapquestion' && <div>
        <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        <MindMapQuestion initialData={section.options} setFieldValue={() => { }} />
      </div>}
      {section.type == 'mindmapslide' && <div>
        <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        <MindMapQuestion initialData={section.answer} setFieldValue={() => { }} />
      </div>}
      {section.type == 'mindmapactivity' && <div>
        <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
        <MindMapQuestion initialData={{}} setFieldValue={() => { }} />
      </div>}
      {section.type == 'crossword' && <><div>
        {<CrossWord crosswordData={parseCrossword(section.options)} submitCrossWord={() => { }} answer={showAnswer} />}
      </div></>}
      {
        showAnswer && ((section.type == 'short' || section.type == 'pair' ||
          section.type == 'blanks' || section.type == 'blanks-any'
          || section.type == 'verticalsequence' || section.type == 'horizontalsequence')
          || (section.answer !== null && section.answer !== '' && section.type != 'mindmapslide')) && <div>
          <div><strong>Answer</strong></div>
          {section.type == 'mcq' && <div className='compact'> <KaTeXRenderer>{'<b>(' + (parseInt(section.answer) + 1) + ')</b>  ' + section.options[parseInt(section.answer)] || ''}</KaTeXRenderer></div>}
          {section.type == 'multiple' && <>{section.answer?.map((answer: any) => {
            return <KaTeXRenderer>{section.options[parseInt(answer)] || ''}</KaTeXRenderer>
          })}</>}
          {(section.type == 'numerical' || section.type == 'long') && <KaTeXRenderer>{section.answer || ''}</KaTeXRenderer>}
          {(section.type == 'short') && <>{section.options?.map((answer: any) => {
            return <KaTeXRenderer>{answer || ''}</KaTeXRenderer>
          })}</>}
          {section.type == 'pair' && <>{section.options.map((option: string, index: number) => {
            if (index % 2 == 1) {
              return <><KaTeXRenderer>{option || ''}</KaTeXRenderer><hr /></>
            } else {
              return <><KaTeXRenderer>{option || ''}</KaTeXRenderer></>
            }
          })}</>}
          {(section.type == 'blanks' || section.type == 'blanks-any') && <><KaTeXRenderer>{parseBlankAnswer(section.text || '')}</KaTeXRenderer></>}
          {section.type == 'matrix' && <>
            <div>{section?.options?.map((option: any, index: number) => {
              return (
                <>
                  {option[0] && option[0].trim() != '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
                    {section?.options?.map((option: any, index1: number) => {
                      return <>{option[1] && option[1].trim() != '' && <td><input className='m-2' type='checkbox' name='answer' checked={section?.answer.indexOf(index.toString() + '-' + index1.toString()) != -1} style={{ height: "15px", width: "15px" }} disabled={true} /></td>}</>
                    })}
                  </tr>}
                </>
              )
            })}
            </div>
          </>}
          {section.type == 'dragdrop' && <>
            {<div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded '} style={{ animationDelay: '0.75s', pointerEvents: 'none' }}>
              <DragDrop setFieldValue={() => { }} itemsIn={[...[
                { id: 'question', items: [] }
              ],
              ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: section.options.map((opt: string[]) => opt[0]).filter((opt1: string, index: number) => { return opt1 != '' && isMatch(index, opt, section.answer, section.options) }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) } })]}></DragDrop>
            </div>}
          </>}
          {(section.type == 'horizontalsequence' || section.type == 'verticalsequence') && <>
            {<div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded'} style={{ animationDelay: '0.75s' }}>
              <span className={'p-2 mb-2'}>Correct Order</span>
              {section?.options?.map((option: any) => { return <span><KaTeXRenderer>{option || ''}</KaTeXRenderer></span> })}
            </div>}
          </>}
          {section.type == 'imageselect' && <div className='animate__animated animate__bounceInUp'>
            <AnnotatableImageView
              section={section}
              disabled={true}
              values={{}}
              attempt={{ ...section, ...{ correct: true } }}
              setFieldValue={() => { }} />
          </div>}
          {section.type == 'mindmapquestion' && <div>
            <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
            <MindMapQuestion initialData={section.answer} setFieldValue={() => { }} />
          </div>}
          {/*section.type != 'multiple' && section.type != 'mcq' && <>{JSON.stringify(section.answer)}</>*/}
        </div>
      }
      {
        showExplanation && section.explanation && section.explanation != '' && <div>
          <div><strong>Explanation</strong></div>
          <KaTeXRenderer>{section.explanation || ''}</KaTeXRenderer>
        </div>
      }
    </div>
  )
}