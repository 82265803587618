import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
type ApiParams = { userId: string };
const StudentReports: FC = () => {
  const { userId } = useParams<ApiParams>();
  let [report, setReport] = useState<any>([]);
  let [name, setName] = useState<any>('');
  let [includeAssignment, setIncludeAssignment] = useState<boolean>(true);
  let [includeTest, setIncludeTest] = useState<boolean>(true);
  useEffect(() => {
    getReports();
  }, []);
  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/student-reports/' + (userId ? userId : '')).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data.reports);
          setName(reply.data.name)
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      <h3 className='text-center m-5'>{name != '' ? name : 'Your Performance Report'}</h3>
      <hr />
      <div className='pb-10 pe-4 no-print'>
        <b className='float-end'>
          <input type='checkbox' className='me-2' checked={includeAssignment}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIncludeAssignment(event.target.checked);
            }} />Assignments
          <input type='checkbox' className='me-2 ms-2' checked={includeTest}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIncludeTest(event.target.checked);
            }} />Tests
        </b>
      </div>
      <div>
        <div className='col-5 d-inline-block'></div>
        <div className='col-2 d-inline-block'><h5 className='text-end pe-1'>Marks</h5></div>
        <div className='col-2 d-inline-block'><h5 className='text-end pe-1'>Rank</h5></div>
        <div className='col-3 d-inline-block'></div>
        {report?.map((module: any) => {
          return ((module.challengeDetails[0]?.type === 'test' && includeTest) ||
            (module.challengeDetails[0]?.type === 'assignment' && includeAssignment)) && <div className='shadow p-3 rounded mb-3'>
              <div className='col-5 d-inline-block'><b>{module.challengeDetails[0]?.heading}</b></div>
              <div className='col-2 d-inline-block text-end'><b>{module.attemptDetails[0]?.totalPoints}</b></div>
              <div className='col-2 d-inline-block text-end'><b>{module.attemptDetails[0]?.rank}</b></div>
              <div className='col-3 d-inline-block text-end no-print'>
                <a href={'/reports/' + module._id} className='btn btn-dark'><i className="fa fa-chart-line"></i></a>
              </div>
              {module.attemptDetails[0]?.completed && <div style={{ fontSize: "10px" }}>Submitted On - {new Date(module.attemptDetails[0].createdAt).toLocaleString()}</div>}
              {!module.attemptDetails[0]?.completed && <div style={{ fontSize: "10px" }}>*Not submitted</div>}
            </div>
        })}
      </div>
    </div >
  )
}

export { StudentReports }
