import { ChangeEvent, useState } from "react";
import { Section, ValidationSchema } from "../../models/Section";
import { ViewSection } from "../../modules/components/display/ViewSection";
import { Form, Formik, FormikValues } from "formik";
import axios from "axios";
import { DisplayLoader } from "../../utils/loader";
import { Modal } from "react-bootstrap";
import { EditSection } from "../../modules/components/display/EditSection";
import KaTeXRenderer from "../utils/KatexRenderer";

export const CreateSectionsFromJSON2 = () => {
  let [sections, setSections] = useState<any>([]);
  let [currentSection, setCurrentSection] = useState<any>({});
  let [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  let [isEditing, setIsEditing] = useState<boolean>(false);
  let [tags, setTags] = useState<any>([]);
  let [challengeName, setChallengeName] = useState<any>([]);
  const scrollCurrentSectionToView = () => {
    setTimeout(() => {
      (document.getElementById('section' + currentSectionIndex) as HTMLElement).scrollIntoView();
    }, 100)
  }
  const validateSection = (section: any) => {
    section.valid = true;
    if (section.type == 'mcq' || section.type == 'short') {
      if (!section.text || section.text == '' ||
        section.points == null || section.points == 0 ||
        section.options == null || section.options.length == 0 || !Array.isArray(section.options) ||
        section.answer == null || section.options[section.answer] == null) {
        section.valid = false;
      }
    }
    if (section.type == 'long') {
      if (!section.text || section.text == '' ||
        section.points == null || section.points == 0 ||
        section.answer == null || section.answer == '') {
        section.valid = false;
      }
    }
    if (section.type == 'slide') {
      if (!section.text || section.text == '') {
        section.valid = false;
      }
    }
    if (!section.valid) console.log(section.text)
    return section;
  }
  const processSection = (section: any) => {
    let newSection: any = {}
    newSection.ownerInstitutionId = "646dff1af4d030e48cd9680b"
    newSection.type = section.type;
    if (section.difficultyLevel) {
      newSection.difficultyLevel = section.difficultyLevel;
    }
    if (newSection.type == 'slide') {
      newSection.text = section;
      newSection.points = 0;
      return validateSection(newSection);
    }
    if (section.question) newSection.text = section.question;//.replace(/\d+. /, '');
    if (section.text) newSection.text = section.text;//.replace(/\d+. /, '');
    if (section.options) {
      if (Array.isArray(section.options)) {
        newSection.options = section.options;
      } else {
        newSection.options = Object.values(section.options).map((opt: any) => {
          return opt[opt.length - 1] == '*' ? opt.slice(0, opt.length - 1) : opt
        })
      }
    }
    if (section.group) newSection.group = section.group;
    if (section.answer) {
      if (newSection.options && newSection.options.indexOf(section.answer) != -1) {
        newSection.answer = newSection.options.indexOf(section.answer)
      } else if (newSection.options && newSection.options.indexOf(Object.values(section.answer)[0]) != -1) {
        newSection.answer = newSection.options.indexOf(Object.values(section.answer)[0]);
      } else if (newSection.options && newSection.options.indexOf(section.options[section.answer]) != -1) {
        newSection.answer = newSection.options.indexOf(section.options[section.answer]);
      } else if (newSection.options && section.options[section.answer] && section.options[section.answer][section.options[section.answer].length - 1] == '*' && newSection.options.indexOf(section.options[section.answer].slice(0, section.options[section.answer].length - 1)) != -1) {
        newSection.answer = newSection.options.indexOf(section.options[section.answer].slice(0, section.options[section.answer].length - 1));
      } else {
        newSection.answer = section.answer;
      }
    }
    if (section.answer) {
      newSection.explanation = section.explanation;
    }
    if (section.tags) {
      newSection.tags = section.tags;
    }
    if (section.source) {
      newSection.source = section.source;
    }
    if (newSection.type != 'slide') {
      newSection.points = 5;
    }
    return validateSection(newSection);
  }
  const deleteSection = (sectionNo: number) => {
    let newSections = [...sections];
    newSections.splice(sectionNo, 1);
    setSections(newSections);
  };
  const setEdit = (sectionNo: number) => {
    let newSections = [...sections];
    if (!newSections[sectionNo].reviewStatus) {
      newSections[sectionNo].reviewStatus = -11;
    } else {
      delete newSections[sectionNo].reviewStatus;
    }
    setSections(newSections);
  };
  const saveSection = (values: FormikValues) => {
    let newSections = [...sections]
    newSections[currentSectionIndex] = values
    setIsEditing(false)
    scrollCurrentSectionToView();
    setSections(newSections);
  }
  const parseAnswerFile = (sections: any, text: string, startText: string, answerFinder: RegExp, solutionDelimiter: RegExp, numberSplitter: RegExp, numberRemover: RegExp) => {
    // const startText = ''
    // const solutionDelimiter = /\n(?=\d+\.\s*\(\d+\))/
    // const answerFinder = /\((\d+)\)/
    // const numberSplitter = /^\d+/
    if (startText) {
      text = text.split(startText)[1]
    }
    let newSections = [...sections]
    const sols = text.split(solutionDelimiter)
    sols.forEach((sol: any) => {
      if (sol.match(numberSplitter)) {
        const qNo = parseInt(sol.match(numberSplitter)[0].trim()) - 1
        let currentSection = { ...sections[qNo] }
        currentSection.answer = currentSection.type == 'mcq' ? (parseInt(sol.match(answerFinder)[1]) - 1) : sol.match(answerFinder)[1]
        currentSection.explanation = sol.replace(numberRemover,'').split(/(\$+\S[^\$]*\$+)/).map((part: any) => part.startsWith('$') ? part : part.replace(/\n/g, ' $\\newline$ ')).join('');
        console.log(currentSection)
        newSections[qNo] = currentSection
      }
    })
    return newSections
  }
  const parseAnswerFileWithTable = (sections: any, text: string, startText: string, answerFinder: string, solutionDelimiter: RegExp, numberSplitter: RegExp, numberRemover: RegExp, endText: string) => {
    // const startText = '## JEE-MAIN MOCK TEST-1 XII'
    // const endText = '## MATHEMATICS'
    // const answerFinder = /\((\d+)\)/
    // const solutionDelimiter = /Q\.\s*(?=\d+)\s*/
    // const numberSplitter = /^\d+/
    let newSections = [...sections]
    const answers = text
      .split(startText)[1]
      .split(endText)[0]
      .split('\n')
      .filter(line => line.trim().startsWith(answerFinder))
      .map(line => line.split('|'))
      .flat()
      .map(item => item.trim())
      .filter(item => /^\d+$/.test(item))
      .map(Number);
    answers.forEach((answer, index) => {
      newSections[index].answer = ((newSections[index]['type'] == 'mcq') ? (answer - 1) : answers)
    })
    const sols = text.split(endText)[1].split(solutionDelimiter)
    sols.forEach((sol: any) => {
      if (sol.trim()) {
        const qNo = parseInt(sol.match(numberSplitter)[0].trim()) - 1
        newSections[qNo].explanation = sol.split(numberSplitter)[1].split(/(\$+\S[^\$]*\$+)/).map((part: any) => part.startsWith('$') ? part : part.replace(/\n/g, ' $\\newline$ ')).join('');
      }
    })
    return newSections
  }
  const parseQuestionFile = (text: string, startText: string, subjectDelimiter: string, questionDelimiter: RegExp, questionOptionsDelimiter: RegExp, numberSplitter: RegExp, numberRemover: RegExp) => {
    // const startText = /(?=## MATHEMATICS)/
    // const subjectDelimiter = /##/
    // const questionDelimiter = /Q\.\s*\d+\s*/
    // const questionOptionsDelimiter = /\(\d+\)/
    let subjectTags: any = []
    subjectDelimiter.split(',').forEach((sub, index) => {
      if (index % 2 == 1) {
        if (parseInt(sub) == -1) {
          subjectTags = [subjectDelimiter.split(',')[index - 1].split('|')]
        } else {
          subjectTags = [...subjectTags, ...new Array(parseInt(sub)).fill(subjectDelimiter.split(',')[index - 1].split('|'))]
        }
      }
    })
    let allQuestions: any = []
    text = text.split(startText)[1]


    const source = (document.getElementById('questionSource') as HTMLInputElement).value
    let questions = text.split(questionDelimiter)
    questions.forEach((q1, index) => {
      if (q1.trim() && q1.match(numberSplitter)?.[0]) {
        const qNo = parseInt(q1.match(numberSplitter)?.[0].trim() || '-1') - 1
        q1 = q1.replace(numberRemover, '')
        const [question, ...options] = q1.split(questionOptionsDelimiter).map(str => str.trim());
        let section = {
          fineTags: {
            topics : (subjectTags[index] || subjectTags[0]).map((tag:string)=>tag.toLocaleLowerCase()),
            targetTest: 'mains'
          },
          source: source.toLocaleLowerCase(),
          ownerInstitutionId: "646dff1af4d030e48cd9680b",
          text: question.split(/(\$+\S[^\$]*\$+)/).map((part: any) => part.startsWith('$') ? part : part.replace(/\n/g, ' $\\newline$ ')).join(''),
          options: options,
          type: (options && options.length > 0) ? 'mcq' : 'numerical'
        }
        allQuestions[qNo] = section;
      }
    })

    return allQuestions
  }

  const processFiles = () => {
    let questionFile = (document.getElementById('questionFile') as HTMLInputElement).files?.[0]
    let answerFile = (document.getElementById('answerFile') as HTMLInputElement).files?.[0]
    const questionSettingsArray = (document.getElementById('questionSettingsArray') as HTMLInputElement).value.split('|||')
    const answerSettingsArray = (document.getElementById('answerSettingsArray') as HTMLInputElement).value.split('|||')
    const questionReader = new FileReader();
    if (questionFile) {
      questionReader.onerror = (event) => {
        console.log(event)
      }
      questionReader.onload = (event) => {
        let questionContent = (event.target?.result?.toString() || '').replace(
          /!\[\]\((https:\/\/cdn\.mathpix\.com\/cropped\/[^\)]+)\)/g,
          "<img src='$1' style='max-height:200px'/>")
        if (answerFile) {
          const answerReader = new FileReader();
          answerReader.onload = (event) => {
            let answerContent = (event.target?.result?.toString() || '').replace(
              /!\[\]\((https:\/\/cdn\.mathpix\.com\/cropped\/[^\)]+)\)/g,
              "<img src='$1' style='max-height:200px'/>")
            let sections = parseQuestionFile(questionContent, questionSettingsArray[0], questionSettingsArray[1], new RegExp(questionSettingsArray[2]), new RegExp(questionSettingsArray[3]), new RegExp(questionSettingsArray[4]), new RegExp(questionSettingsArray[5]))
            if (answerSettingsArray[5]) {
              sections = parseAnswerFileWithTable(sections, answerContent, answerSettingsArray[0], answerSettingsArray[1], new RegExp(answerSettingsArray[2]), new RegExp(answerSettingsArray[3]), new RegExp(answerSettingsArray[4]), answerSettingsArray[5])
            } else {
              sections = parseAnswerFile(sections, answerContent, answerSettingsArray[0], new RegExp(answerSettingsArray[1]), new RegExp(answerSettingsArray[2]), new RegExp(answerSettingsArray[3]), new RegExp(answerSettingsArray[4]))
            }
            setSections(sections.filter((section: any) => {
              return section != undefined && !(section.type == 'mcq' && section.options.length < 3) 
            }))
          }
          answerReader.readAsText(answerFile);
        }
      }
      questionReader.readAsText(questionFile);
    }
  }
  const upload = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/bulk-upload/', { challengeName: challengeName, sections: sections }).then((reply) => {
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
    })
  }
  return (
    <>
      <div style={{ display: isEditing ? 'none' : 'block' }}>
        <div className="row">
          <div className="ps-2 pe-2 col-6 d-inline-block">
            <label className="m-2"><b>Questions</b></label>
            <div><input id="questionSettingsArray" type="text" placeholder="Start Text" /></div>
            <div className="mt-4"><input type="file" id="questionFile" ></input></div>
          </div>
          <div className="ps-2 pe-2 col-6 d-inline-block">
            <label className="m-2"><b>Ans and Sol</b></label>
            <div><input id="answerSettingsArray" type="text" placeholder="Start Text" /></div>
            <div className="mt-4"><input type="file" id="answerFile" ></input></div>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <label className="m-2"><b>Source</b></label>
          <div><input id="questionSource" type="text" placeholder="Start Text" /></div>
        </div>
        <button className="btn btn-success mt-4" onClick={() => { processFiles() }}>Process</button>
        <hr className="mb-5" />
        <div className="text-center">{challengeName}</div>
        {/*
      <div>Tags - {tags.map((tag: string) => {
        return <span>{tag},</span>
      })}</div>
      <input type="text" id="tag" placeholder="New Tag"></input><button className="btn btn-dark" onClick={() => { addTag() }}>Add</button>*/}
        Total Questions - <b>{sections.length}</b>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={ValidationSchema({}, true)} initialValues={{}}
          onSubmit={() => { }}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {sections?.map((section: Section, index: number) => {
                return <>
                  <div className="mb-8" id={'section' + index}>
                    <strong>Section No {index + 1}</strong>
                    <button type="button" className="btn btn-primary m-2 p-2" onClick={() => { setIsEditing(true); setCurrentSection(section); setCurrentSectionIndex(index) }}>Edit</button>
                    <button type="button" className="btn btn-danger float-end m-2 p-2" onClick={() => { deleteSection(index) }}>Delete</button>
                    <button type="button" className={"btn float-end m-2 p-2 " + (section.reviewStatus == -11 ? "btn-success" : "btn-danger")} onClick={() => { setEdit(index) }}>Needs to be edited later</button>
                  </div>
                  <div>Tags - {(section.tags || section?.fineTags?.topics)?.map((tag: string) => {
                    return <span>{tag},</span>
                  })}</div>
                  {ViewSection(section, false, 1, errors, values, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword') ? { ...section, ...{ correct: true } } : ((section.type == 'numerical' || section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'dragdrop' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives' || section.type == 'short') ? { ...section, ...{ correct: false } } : section)), true)}
                  <hr />
                </>
              })}
            </Form>
          )}
        </Formik>
        <button className="btn btn-success" onClick={() => { upload() }}>Upload</button>
      </div>
      <div style={{ display: isEditing ? 'block' : 'none' }}>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={ValidationSchema({}, true)} initialValues={currentSection}
          onSubmit={saveSection}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              {EditSection(values, errors, setFieldValue)}
              <button type="button" onClick={() => { setIsEditing(false); scrollCurrentSectionToView(); }} className="btn btn-primary">Back</button>
              <button type="submit" className="btn btn-primary float-end">Save</button>
            </Form>)}
        </Formik>
      </div>
    </>
  )
}