export const DisplayLoader = (shouldDisplay: boolean, text: string) => {
  if (document.getElementById('loader-div')) {
    (document.getElementById('loader-div') as HTMLDivElement).style.display = shouldDisplay ? 'block' : 'none';
    if (shouldDisplay) {
      (document.getElementById('loader-text') as HTMLDivElement).style.display = text != '' ? 'block' : 'none';
      if (text != '') {
        (document.getElementById('loader-text') as HTMLDivElement).innerHTML = text;
      }
    }
  }
}