import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
type ApiParams = { id: string; sectionNo: string };
export const ShowChallenge: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  return(
    <div className='rounded shadow-lg text-center'>
      <h4>Share the following link to take the Challenge</h4>
      {window.location.origin + '/start-challenge/' + id}
    </div>
  )
}