/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth, getIsNotLoggedIn, AuthLayout } from '../modules/auth'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { App } from '../App'
import { RegisterNotLogged } from '../modules/auth/components/RegisterNotLogged'
import { Exercise } from '../pages/challenge/Exercise'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  console.log(currentUser)
  console.log("begin" + (!currentUser ? 'Hell' : (currentUser && currentUser.isVerified) ? '2' : '1'))
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {/*(currentUser && currentUser.isVerified && currentUser.isNotLoggedIn !== true) ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              
            </>
          ) : (
            <>
            <Route path='/wLogin' element={<RegisterNotLogged />} />
            <Route path='*' element={<Navigate to='/wLogin' />} />
              
            </>
          )</Routes>*/}

          {(!currentUser) ? <>
            <Route element={<MasterLayout isRelative={true} />}>
              <Route path='test/:id' element={<Exercise />} >
                <Route path=':sectionNo' element={<Exercise />} />
              </Route>
              <Route path='testseries/:id' element={<Exercise />} >
                <Route path=':sectionNo' element={<Exercise />} />
              </Route>
            </Route>
            <Route path='*' element={<AuthPage />} />
            {/* <Route path='/wLogin' element={<RegisterNotLogged />} />
            <Route element={<AuthLayout />}>
              <Route path='*' element={<RegisterNotLogged />} />
            </Route> */}
          </> :

            <>
              <Route path='/*' element={<PrivateRoutes />} />
            </>}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
