import axios, { AxiosResponse } from 'axios';
import * as Yup from 'yup'
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { DisplayLoader } from '../../utils/loader';
import { type } from 'os';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { Modal } from 'react-bootstrap';
import { jsPDF } from "jspdf";
import { useAuth } from '../../modules/auth';
import { PrintSection } from '../../modules/components/display/PrintSection';
import { challengeTypeToPresentTypeMap } from '../../models/Challenge';
import { generateOMRSheet } from '../../utils/generateOMR';
import { useParams } from 'react-router-dom';
type ApiParams = { id: string };
const Print: FC = () => {
  const { currentUser, logout } = useAuth()
  const { id } = useParams<ApiParams>();
  let [showAnswer, setShowAnswer] = useState<boolean>(false);
  let [showExplanation, setShowExplanation] = useState<boolean>(false);
  let [isOffline, setIsOffline] = useState<boolean>(false);
  let [challenge, setChallenge] = useState<any>({});
  let [sections, setSections] = useState<any>({});
  useEffect(() => {
    updateChallenge();
  }, []);
  const updateChallenge = (sectionNo?: number) => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge/' + id).then((reply) => {
      if (reply?.data) {
        setChallenge(reply.data.challenge);
        setSections(reply.data.sections);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  function addWaterMark(doc: any) {
    var totalPages = doc.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      //doc.addImage(imgData, 'PNG', 40, 40, 75, 75);
      doc.setTextColor(0, 0, 0, 0.3);
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height * 2 / 3, 'Olearno');
      doc.text(doc.internal.pageSize.width / 2 - 20, doc.internal.pageSize.height / 3, 'Olearno');
    }

    return doc;
  }
  const print = () => {
    DisplayLoader(true, '')
    var doc = new jsPDF('p', 'pt', 'a4');
    doc.setProperties({
      title: challenge?.heading
    })
    doc.html((document.getElementById('print') || ''), {
      callback: function (doc) {
        let newDoc = addWaterMark(doc)
        newDoc.save(challenge?.heading + '.pdf');
        DisplayLoader(false, '')
      },
      margin: [20, 20, 20, 20],
      autoPaging: 'text',
      windowWidth: 700,
      width: 700,
      html2canvas: {
        scale: 0.8 // default is window.devicePixelRatio,
      },
      //width: 190, //target width in the PDF document
      //windowWidth: 650 //window width in CSS pixels
    });
  }
  function PrintWord() {
    DisplayLoader(true, '')
    let filename = challenge?.heading;
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + (document.getElementById('print') as HTMLElement).innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
    filename = filename ? filename + '.doc' : 'document.doc';
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
    document.body.removeChild(downloadLink);
    DisplayLoader(false, '')
  }
  const createOMRConfig = () => {
    let OMRConfig: any = {}
    let subjectList = ['physics', 'chemistry', 'maths', 'biology']
    subjectList.forEach((subject: string) => {
      let count = challenge.sections.filter((section: any) => {
        return sections[section].tags.indexOf(subject) != -1
      }).length;
      if (count > 0) {
        OMRConfig[subject] = count;
      }
    })
    return OMRConfig;
  }
  return (
    <div className="">
      <h3 className='text-center'>{challenge.heading} - {challenge.presentationMode == 'assignment' ? 'Assignment' : ''}</h3>
      <hr />
      <>
        <div className='no-print text-center'>
          <div>
            <input type='checkbox' className='me-1' onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShowAnswer(event.target.checked);
            }} /> Show Answer
            <input type='checkbox' className='ms-2 me-1' onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setShowExplanation(event.target.checked);
            }} /> Show Explanation
          </div>
          {/*<button type='button' className='btn btn-info p-2 me-4' onClick={() => { PrintWord() }}>Word (.doc)</button>*/}
          <button type='button' className='btn btn-info p-2 me-4' onClick={() => { window.print() }}>Print {challenge.presentationMode == 'assignment' ? 'Assignment' : 'Test'}</button>
          <button type='button' className='btn btn-info p-2' onClick={() => { generateOMRSheet('Olearno', 'JEE Mains Prep 1', 'https:\/\/fastly.picsum.photos/id/48/200/200.jpg?hmac=3FKJwSlm1FM1GD916vZX2Z3HUjHsUXvQM3rYWYXsQvc', 6, createOMRConfig(), false) }}>Print OMR</button>
          {/*<div style={{ fontSize: "12px" }}>*.doc files can't be opened in a phone</div>*/}
        </div>
        <div id="print" style={{ width: '100%' }}>
          {challenge?.sections?.map((section: any, index: number) => {
            return <div className='mb-4'>
              <h4>Question No. {index + 1}</h4>
              {/*<div className='float-end'>Marks - <b>{sections[section]?.points}</b></div>*/}
              {PrintSection(sections[section], showAnswer, showExplanation)}
              <hr></hr>
            </div>
          })}
        </div>
      </>
    </div>
  )
}

export { Print }
