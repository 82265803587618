import { FormikErrors } from "formik";
import { Section } from "../../../models/Section";

export const ErrorDisplay = (field : string, errors: FormikErrors<any>) => {
  return(<>
  {errors && errors[field] && !Array.isArray(errors[field]) && 
  <div className='text-danger'>*{JSON.stringify(errors[field]).replaceAll('"', '')}</div>}
  {errors && errors[field] && Array.isArray(errors[field]) && 
    <div className='text-danger'>*{ JSON.stringify(errors[field]).replaceAll('"', '').indexOf('Options cannot be empty') !== -1 ? 'Options cannot be empty' : ''}</div>}
    </>
  )
}