import { FC, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikValues } from 'formik'
import { useAuth } from '../../modules/auth';
import * as Yup from 'yup'
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';
import { useParams } from 'react-router-dom';

type ApiParams = { id: string };
export const Profile: FC = () => {
  const { currentUser } = useAuth()
  const { id } = useParams<ApiParams>();
  const [user, setUser] = useState<any>({})
  const [pageState, setPageState] = useState(0)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const profileKeyMap : any = {
    'schoolname' : 'School',
    'combination' : 'Combination',
    'mathmarks' : 'Maths Marks',
    'sciencemarks' : 'Science Marks',
    'fathername' : "Father's name",
    'fathercontact' : "Father's contact",
    'mothername' : "Mother's name",
    'mothercontact' : "Mother's contact",
    'address' : 'Address',

  }
  let validationSchema = Yup.object({
    oldpassword: Yup.string().required("This field in required"),
    newpassword: Yup.string().required("This field is required"),
    repassword: Yup.string().required("This field is required").oneOf([Yup.ref('newpassword')], "New Password and Confirm Password didn't match"),
  })
  useEffect(() => {
    getUserData()
  }, []);
  const getUserData = async () => {
    axios.get(process.env.REACT_APP_API_URL + '/user/' + (id || ''),).then((reply) => {
      DisplayLoader(false, '')
      if (reply?.data) {
        setUser(reply.data)
      } else {
        //TODO
      }
    }).catch(() => {
      DisplayLoader(false, '')
      //TODO
    })
  }
  const updatePassword = async (values: FormikValues) => {
    DisplayLoader(true, '')
    setMessage('')
    setError('')
    axios.post(process.env.REACT_APP_API_URL + '/reset-password/', values).then((reply) => {
      DisplayLoader(false, '')
      if (reply?.data) {
        if (reply?.data.error) {
          setError(reply?.data.error)
        }
        if (reply?.data.message) {
          setMessage(reply?.data.message)
        }
      } else {
        //TODO
      }
    }).catch(() => {
      DisplayLoader(false, '')
      //TODO
    })
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      {pageState == 0 && <>
        <div className='shadow mb-4 rounded row m-2 p-4 pb-5'>
          <div className='col-6'><h5>Name</h5></div>
          <div className='col-6'>
            <h3>{user?.name}</h3>
          </div>
          {user?.profileDetails && <div className='row p-4 mt-5 border-top'>
            <hr />
            <h4 className='text-center'>Details</h4>
            {Object.keys(user.profileDetails).map((key: string) => {
              return <>
                <div className='col-6'><h5>{profileKeyMap[key]}</h5></div>
                <div className='col-6'>
                  <h3>{user.profileDetails[key]}</h3>
                </div>
              </>
            })}
          </div>}
          <div>
            <button className='btn btn-primary p-2 float-end' onClick={() => { setPageState(1) }}>Reset Password</button>
          </div>
        </div>

      </>}
      {
        pageState == 1 && <>
          <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            validationSchema={validationSchema} initialValues={{}} onSubmit={updatePassword}>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
              <Form>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Original Password</label></div>
                  <Field type='password' name='oldpassword' className='form-control'></Field>
                  {ErrorDisplay('oldpassword', errors)}
                </div>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div><label className='form-label fw-bolder text-dark fs-6'>New Password</label></div>
                  <Field type='password' name='newpassword' className='form-control'></Field>
                  {ErrorDisplay('newpassword', errors)}
                </div>
                <div className='bg-light rounded p-2 col-12 d-inline-block'>
                  <div><label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label></div>
                  <Field type='password' name='repassword' className='form-control'></Field>
                  {ErrorDisplay('repassword', errors)}
                </div>
                {error && error != '' && <div className='bg-danger p-2 rounded m-4 text-light'>{error}</div>}
                {message && message != '' && <div className='bg-success p-2 rounded m-4 text-light'>{message}</div>}
                <button className='btn btn-success'>Update Password</button>
              </Form>
            )}
          </Formik>
        </>
      }
    </div>
  )
}