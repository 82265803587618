import { FC } from 'react'
import axios from 'axios';
import { useAuth } from '../../modules/auth';

export const DeleteAccount: FC = () => {
  const { currentUser, logout } = useAuth()
  const deleteAccount = () => {
    axios.delete(process.env.REACT_APP_API_URL + '/users/' + currentUser?.id).then((reply) => {
      logout();
    }).catch(()=>{})
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow p-15'>
      <h3 className='text-center'>Are you sure you want to delete the account?</h3>
      <div className='text-center'>You will lose all the saved data about your progress</div>
      <a href='/' className='btn btn-success float-end' >No</a>
      <button type='button' className='btn btn-danger mb-10' onClick={() => { deleteAccount() }}>Yes</button>
    </div>
  )
}