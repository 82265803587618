function divideNumber(number: number, parts: number): number[] {
  const quotient = Math.floor(number / parts);
  const remainder = number % parts;
  const result = Array.from({ length: parts }, (_, index) =>
    index < remainder ? quotient + 1 : quotient
  );
  return result;
}

function updateInput(questionJSON: Record<string, number>): Record<string, number> {
  if (Object.keys(questionJSON).length > 4) return {};
  if (Object.keys(questionJSON).length > 2) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 45).length > 0
    )
      return {};
    else return questionJSON;
  } else if (Object.keys(questionJSON).length == 2) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 90).length > 0
    )
      return {};
    else {
      let parts1 = divideNumber(
        questionJSON[Object.keys(questionJSON)[0]],
        2
      );
      let updatedJSON: Record<string, number> = {};
      parts1.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[0] + '|' + index
        ] = part)
      );
      let parts2 = divideNumber(
        questionJSON[Object.keys(questionJSON)[1]],
        2
      );
      parts2.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[1] + '|' + index
        ] = part)
      );
      return updatedJSON;
    }
  } else if (Object.keys(questionJSON).length == 1) {
    if (
      Object.keys(questionJSON).filter((k) => questionJSON[k] > 180).length > 0
    )
      return {};
    else {
      let parts = divideNumber(
        questionJSON[Object.keys(questionJSON)[0]],
        4
      );
      let updatedJSON: Record<string, number> = {};
      parts.forEach(
        (part, index) =>
        (updatedJSON[
          Object.keys(questionJSON)[0] + '|' + index
        ] = part)
      );
      return updatedJSON;
    }
  }
  return {};
}

let templateJSON: any = {
  "pageDimensions": [
    1800,
    2400
  ],
  "bubbleDimensions": [
    41,
    35
  ],
  "fieldBlocks": {
    "q01block": {
      "origin": [
        190,
        677
      ],
      "bubblesGap": 74,
      "labelsGap": 34.5,
      "fieldLabels": [
        "q1..45"
      ],
      "fieldType": "QTYPE_MCQ4"
    },
    "q02block": {
      "origin": [
        618,
        677
      ],
      "bubblesGap": 74,
      "labelsGap": 34.5,
      "fieldLabels": [
        "q46..90"
      ],
      "fieldType": "QTYPE_MCQ4"
    },
    "q03block": {
      "origin": [
        1040,
        677
      ],
      "bubblesGap": 74,
      "labelsGap": 34.5,
      "fieldLabels": [
        "q91..135"
      ],
      "fieldType": "QTYPE_MCQ4"
    },
    "q04block": {
      "origin": [
        1465,
        677
      ],
      "bubblesGap": 74,
      "labelsGap": 34.5,
      "fieldLabels": [
        "q136..180"
      ],
      "fieldType": "QTYPE_MCQ4"
    },
    "rollnoblock": {
      "origin": [
        1136,
        167
      ],
      "bubblesGap": 34,
      "labelsGap": 65,
      "fieldLabels": [
        "r1..6"
      ],
      "fieldType": "QTYPE_INT"
    }
  },
  "preProcessors": [{
    "name": "CropOnMarkers",
    "options": {
      "relativePath": "omr_marker.jpg",
      "sheetToMarkerWidthRatio": 17
    }
  }]
}

export function generateOMRCorrectionTemplate(
  rollNoLength: number,
  questionJSON: Record<string, number>,
): void {
  questionJSON = updateInput(questionJSON);
  console.log(questionJSON)
  templateJSON['fieldBlocks']['rollnoblock']['fieldLabels'] = ['r1..' + rollNoLength];
  if (Object.keys(questionJSON).length < 4) {
    delete templateJSON['fieldBlocks']['q04block']
    if (Object.keys(questionJSON).length < 3) {
      delete templateJSON['fieldBlocks']['q03block']
      if (Object.keys(questionJSON).length < 2) {
        delete templateJSON['fieldBlocks']['q02block']
      }
    }
  }
  let count = 0;
  for (let i = 0; i < Object.keys(questionJSON).length; i++) {
    templateJSON['fieldBlocks']['q0' + (i + 1) + 'block']['fieldLabels'] = ["q" + (count + 1) + ".." + (count + questionJSON[Object.keys(questionJSON)[i]])]
    count = count + questionJSON[Object.keys(questionJSON)[i]];
  }
  return templateJSON;
}
