import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';

type ApiParams = { batchId: string };
export const BatchTeachers = () => {
  const { batchId } = useParams<ApiParams>();
  let [batch, setBatch] = useState<any>({});
  let [teachers, setTeachers] = useState<any>([]);
  let [success, setSuccess] = useState<string>('');
  let [error, setError] = useState<string>('');
  let validationSchema = Yup.object({
    teachers: Yup.string()
    /*
      .test(
        'needValidEmailList',
        'Enter comma separated list of of valid emails',
        function (items) {
          let isValid = true;
          items?.split(',').filter(item => item).forEach((item) => {
            if (!item.toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )) isValid = false;
          })
          return isValid;
        }
      ),
      */
  });
  const addTeachers = (values: FormikValues) => {
    let input: any = {}
    input.teachers = values.teachers.split(',').filter((item: any) => item).filter(function (item: string, pos: number, self: any) {
      return self.indexOf(item) === pos;
    })
    input.teachers = [...batch.teachers, ...input.teachers];
    input._id = batch._id;
    input.name = batch.name;
    DisplayLoader(true, '')
    setError('')
    setSuccess('')
    axios.post(process.env.REACT_APP_API_URL + '/update-batch/', input).then((reply) => {
      if (reply?.data) {
        initializePage();
        DisplayLoader(false, '')
        setSuccess('Successfully updated the batch')
      } else {
        DisplayLoader(false, '')
        setError('Unknown error while updating the batch. Please try again.');
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      setError('Unknown error while updating the batch. Please try again.');
      DisplayLoader(false, '')
    });
  }
  const removeUser = (userToRemove : string) => {
    let input: any = {}
    input.teachers = batch.teachers;
    input.teachers.splice(input.teachers.indexOf(userToRemove), 1);
    input._id = batch._id;
    input.name = batch.name;
    DisplayLoader(true, '')
    setError('')
    setSuccess('')
    axios.post(process.env.REACT_APP_API_URL + '/update-batch/', input).then((reply) => {
      if (reply?.data) {
        initializePage();
        DisplayLoader(false, '')
        setSuccess('Successfully updated the batch')
      } else {
        DisplayLoader(false, '')
        setError('Unknown error while updating the batch. Please try again.');
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      setError('Unknown error while updating the batch. Please try again.');
      DisplayLoader(false, '')
    });
  }
  const initializePage = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatch(reply.data);
        //DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
    axios.get(process.env.REACT_APP_API_URL + '/batch-teachers/' + batchId).then((reply) => {
      if (reply?.data) {
        setTeachers(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  useEffect(() => {
    initializePage();
  }, [])
  return (
    <div className='mt-1 bg-light shadow p-3'>
      <h3 className='text-center'>{batch?.name} - Members</h3>
      <div>
        {
          teachers?.map((user: any) => {
            return <div className='m-4 p-2 pb-6 shadow rounded' style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {user.name}
              <button className='p-2 float-end btn btn-danger' onClick={()=>{removeUser(user.username)}}>Remove</button>
              <a href={'/student-reports/'+user._id} className='p-2 float-end btn btn-dark me-5'>Reports</a>
            </div>
          })
        }

        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={{}} onSubmit={addTeachers}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <div><label className='form-label fw-bolder text-dark fs-6'>Add Emails</label></div>
              <div>
                <Field component="textarea" rows="4" className='form-control form-control-lg mb-1'
                  name='teachers' placeholder='Emails' />
                {ErrorDisplay('teachers', errors)}
              </div>
              {error && error !== '' && (
                <div className='mt-3 alert alert-danger text-center'>
                  <div className='alert-text font-weight-bold'>
                    {error}
                  </div>
                </div>
              )}
              {success && success !== '' && (
                <div className='mt-3 alert alert-success text-center'>
                  <div className='alert-text font-weight-bold'>
                    {success}
                  </div>
                </div>
              )}
              <button className="btn btn-primary float-right">Add</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}