/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'

import axios from 'axios'
import { useParams } from 'react-router-dom';
type ApiParams = { batchId: string };
const BatchReports: FC = () => {
  const { batchId } = useParams<ApiParams>();
  let [challenges, setChallenges] = useState<any>([]);
  let [folders, setFolders] = useState<any>([]);
  let [folderSequence, setFolderSequence] = useState<any>([]);
  let [dashboardState, setDashboardState] = useState<string>('lessons');
  let [dashboardSubState, setDashboardSubState] = useState<string>('completed');
  let [activeChallenges, setActiveChallenges] = useState<any>([]);
  let [upcomingChallenges, setUpcomingChallenges] = useState<any>([]);
  let [completedChallenges, setCompletedChallenges] = useState<any>([]);
  let [currentFolderStructure, setCurrentFolderStructure] = useState<any>([]);
  let [currentChallengeInstances, setCurrentChallengeInstances] = useState<any>([]);
  const setFolderStructure = () => {
    if (folders.length === 0) return;
    let folderStructure: any = folders;
    let challengeInstances: any = [];
    setCurrentFolderStructure(folderStructure)
    setCurrentChallengeInstances(challengeInstances)
  }
  const getChallengeInstances = (type: string) => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-instances-by-batch/' + batchId + '/' + type).then((reply) => {
      if (reply?.data?.challengeInstances) {
        setCompletedChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.end) < new Date() || (challenge.attemptDetails.length > 0 && challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return -1
          return Date.parse(b.end) - Date.parse(a.end);
        }));
        setUpcomingChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.start) > new Date()
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
        setActiveChallenges(reply.data.challengeInstances.filter((challenge: any) => {
          return new Date(challenge.start) < new Date() && new Date(challenge.end) > new Date() && (challenge.attemptDetails.length === 0 || !challenge.attemptDetails[0].completed)
        }).sort(function (a: any, b: any) {
          if (!b.date) return 1
          return Date.parse(a.end) - Date.parse(b.end);
        }));
      } else {
        //TODO
      }
    })
  }
  const getTests = () => {
  }
  useEffect(() => {
    setFolderStructure();
  }, [folders, folderSequence])
  useEffect(() => {
    /*
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-list/').then((reply) => {
      if (reply?.data?.challenges) {
        setChallenges(reply.data.challenges);
      } else {
        //TODO
      }
    })
    */
    axios.get(process.env.REACT_APP_API_URL + '/get-folder-structure/'+batchId).then((reply) => {
      if (reply?.data?.folders) {
        setFolders([reply.data.folders]);
        setFolderSequence([reply.data.folders]);
      } else {
        //TODO
      }
    })
  }, []);
  return (
    <>


      <div>
        {
          
          <>
            <h3 className='text-center'>Reports</h3>
            <h6 className='mb-0'>
              <div onClick={() => { setDashboardState('lessons') }} className={(dashboardState === 'lessons' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Lessons</div>
              <div onClick={() => { setDashboardState('assignments'); getChallengeInstances('assignment') }} className={(dashboardState === 'assignments' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Assignments</div>
              <div onClick={() => { setDashboardState('tests'); getChallengeInstances('test') }} className={(dashboardState === 'tests' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Tests</div>
            </h6>
            <div className='border-top border-primary p-2 shadow pe-4'>
              {dashboardState === 'lessons' && <>
                <ol className="breadcrumb text-muted fs-6 fw-semibold">
                  {
                    folderSequence?.map((folder: any, index: number) => {
                      if (folder.name) {
                        if (index === folderSequence.length - 1) {
                          return <li className="breadcrumb-item text-muted">{folder.name}</li>
                        } else {
                          return <li className="breadcrumb-item"><a onClick={() => { setFolderSequence(folderSequence.slice(0, index + 1)) }} className="">{folder.name}</a></li>
                        }
                      } else {
                        if (index === folderSequence.length - 1) {
                          return <li className="breadcrumb-item text-muted">All Lessons</li>
                        } else {
                          return <li className="breadcrumb-item"><a onClick={() => { setFolderSequence(folderSequence.slice(0, index + 1)) }} className="">All Lessons</a></li>
                        }
                      }
                    })
                  }
                  {/*<li className="breadcrumb-item"><a href="#" className="">Library</a></li>
              <li className="breadcrumb-item text-muted">Active</li>*/}
                </ol>
                {/*folderSequence.length > 1 && <button className='btn btn-primary mb-6' onClick={() => { setFolderSequence(folderSequence.slice(0, folderSequence.length - 1)) }}>Back</button>*/}
                {folderSequence[folderSequence.length - 1]?.folderStructure?.map((folder: any, index: number) => {
                  return <button onClick={() => { setFolderSequence([...folderSequence, ...[folder]]) }} className='shadow card p-3 m-2 mt-4 bg-light col-12' key={index}><h5>{folder.name}</h5></button>
                })}
                {folderSequence[folderSequence.length - 1]?.challengeInstances?.map((challengeInstance: any, index: number) => {
                  return <a href={'/composite-reports/' + challengeInstance.id} className='shadow card p-3 m-2 mt-4 bg-light text-dark' key={index}><h5><i className="fa fa-chart-line text-dark me-5"></i>{challengeInstance.name}</h5></a>
                })}
              </>}
              {(dashboardState === 'assignments' || dashboardState === 'tests') && <>
                <h5 className='text-center mb-4'>{dashboardState === 'assignments' ? 'Assignments' : 'Tests'}</h5>
                <h6 className='mb-0'>
                  <div onClick={() => { setDashboardSubState('completed') }} className={(dashboardSubState === 'completed' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Completed</div>
                  <div onClick={() => { setDashboardSubState('active') }} className={(dashboardSubState === 'active' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Active</div>
                  <div onClick={() => { setDashboardSubState('upcoming') }} className={(dashboardSubState === 'upcoming' ? 'bg-primary text-light' : 'shadow') + ' d-inline-block p-4 rounded-top me-1'} style={{ cursor: 'pointer' }}>Upcoming</div>
                </h6>
                <div className='border-top border-primary p-2 shadow pe-4'>
                  <div className='mt-3'>
                    {dashboardSubState === 'active' &&
                      <>
                        {activeChallenges.map((challenge: any) => {
                          return <a href={('/composite-reports/') + challenge._id} className='p-3 shadow card mb-3 text-dark'><h6>{challenge.challengeDetails[0].heading}</h6>
                            <div className='d-inline-block' style={{ fontSize: '10px' }}>Ends On - <b>{new Date(challenge.end).toLocaleString()}</b></div>
                            {dashboardState === 'tests' && <div className='float-end d-inline-block' style={{ fontSize: '10px' }}>Total Time - <b>{challenge.totalTime} Mins</b></div>}
                          </a>
                        })}
                      </>
                    }
                    {dashboardSubState === 'upcoming' &&
                      <>
                        {upcomingChallenges.map((challenge: any) => {
                          return <div className='p-3 shadow card d-flex mb-3 text-dark'><h6>{challenge.challengeDetails[0].heading}</h6>
                            <span style={{ fontSize: '10px' }}>Starts On - {new Date(challenge.start).toLocaleString()}</span>
                          </div>
                        })}
                      </>
                    }
                    {dashboardSubState === 'completed' &&
                      <>
                        {completedChallenges.map((challenge: any) => {
                          return <a href={'/composite-reports/' + challenge._id} className='p-3 shadow card mb-3 text-dark'>
                            {challenge.challengeDetails[0].heading}
                          </a>
                        })}
                      </>
                    }
                  </div>
                </div>
              </>}
            </div>
          </>
        }

      </div>
    </>
  )
}

export { BatchReports }
