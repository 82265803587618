import Crossword from '@jaredreisinger/react-crossword';
import { useEffect, useRef, useState } from 'react';
interface CrosswordProps {
  crosswordData: any;
  submitCrossWord: Function;
  answer: any;
}
export const CrossWord: React.FC<CrosswordProps> = ({ crosswordData, submitCrossWord, answer }) => {
  const crosswordRef: any = useRef();
  useEffect(()=>{
    setTimeout(()=>{
      if(answer == true) {
        crosswordRef.current.fillAllAnswers(()=>{})
      }
    },1000)
  },[answer])
  return <>
    {answer == true && <div>
      <>
        <b>Answers</b>
        <div>
          <b>Across</b>
          {Object.keys(crosswordData.across).map((a: any) => {
            return <div><b>{a}. </b>{crosswordData.across[a].clue} - <b>{crosswordData.across[a].answer}</b></div>
          })}
        </div>
        <div>
          <b>Down</b>
          {Object.keys(crosswordData.down).map((a: any) => {
            return <div><b>{a}. </b>{crosswordData.down[a].clue} - <b>{crosswordData.down[a].answer}</b></div>
          })}
        </div>
      </>
        </div>}
    <div style={{ display: 'flex', pointerEvents: (answer == true ? 'none' : 'auto') }}>
      <Crossword ref={crosswordRef} data={crosswordData} useStorage={false} theme={{ allowNonSquare:true, gridBackground: 'transparent' }} onAnswerCorrect={(c: any) => {
        setTimeout(() => {
          if (crosswordRef.current.isCrosswordCorrect()) { 
            submitCrossWord(); 
          }
        }, 100);
      }} />
    </div></>;
}