import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import { useAuth } from '../../modules/auth';
type ApiParams = { id: string};
const JoinOrganisation: FC = () => {
  const { id } = useParams<ApiParams>();
  const { logout } = useAuth()
  let [state, setState] = useState<string>('');
  let [message, setMessage] = useState<string>('');
  useEffect(() => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/join-organisation/',{id : id, confirm: false}).then((reply) => {
      if(reply?.data?.message) {
        setState('message')
        setMessage(reply.data.message)
      } else {
        setState('error')
        if(reply?.data?.error) {
          setMessage(reply.data.error);  
        } else {
          setMessage('Something went wrong. Try again later');  
        }
      }
      DisplayLoader(false, '')
    }, (error) => {
      setState('error')
      setMessage('Something went wrong. Try again later');
      DisplayLoader(false, '')
    });
  }, []);
  const join = () => {
    axios.post(process.env.REACT_APP_API_URL + '/join-organisation/',{id : id, confirm: true}).then(async (reply) => {
      if(reply?.data?.message) {
        setState('success')
        setMessage(reply.data.message)
        /*
        saveAuth(reply.data)
        const { data: user } = await getUserByToken(reply.data.api_token)
        setCurrentUser(user)
        */
      } else {
        setState('error')
        if(reply?.data?.error) {
          setMessage(reply.data.error);  
        } else {
          setMessage('Something went wrong. Try again later');  
        }
      }
      DisplayLoader(false, '')
    }, (error) => {
      setState('error')
      setMessage('Something went wrong. Try again later');
      DisplayLoader(false, '')
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {state === 'message' && <div className='text-center p-4'>
        <h5>{message}</h5>
        <a href='/' className='btn btn-danger m-2'>No</a>
        <button className='btn btn-success m-2' onClick={()=>{join()}}>Yes</button>
      </div>
      }
      {state === 'error' && <div className='text-center p-4'>
        <div ><h5 className='text-danger'>{message}</h5></div>
        <a href='/' type='button' className='btn btn-dark'>GO HOME</a>
      </div>}
      {state === 'success' && <div className='text-center p-4'>
        <div ><h5 className='text-success'>{message}</h5></div>
        <a href='/' type='button' className='btn btn-default m-2'>GO HOME</a>
        <button onClick={()=>{logout()}} type='button' className='btn btn-primary m-2'>LOGOUT</button>
      </div>}
    </div>
  )
}

export { JoinOrganisation }
