import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Field, Form, Formik, FormikValues } from 'formik';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';

type ApiParams = { sessionId: string };
export const Attendance = () => {
  const { sessionId } = useParams<ApiParams>();
  let [session, setSession] = useState<any>({});
  let [attendees, setAttendees] = useState<any>({});
  let validationSchema = Yup.object({});
  useEffect(() => {
    getSession();
  }, [])
  const getSession = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-session/' + sessionId).then((reply) => {
      if (reply?.data) {
        setSession(reply.data);
        let newAttendees: any = {}
        reply.data.participants?.forEach((participant: any) => {
          if (reply.data.session?.attendees?.indexOf(participant.username) !== -1) {
            newAttendees[participant.username] = true;
          } else {
            newAttendees[participant.username] = false;
          }
        })
        setAttendees(newAttendees);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const submitAttendance = (values: FormikValues) => {
    DisplayLoader(true, '')
    let newAttendees: any = []
    session?.participants?.forEach((participant: any) => {
      if (attendees[participant.username]) newAttendees.push(participant.username);
    })
    axios.post(process.env.REACT_APP_API_URL + '/submit-attendance/' + sessionId, { attendees: newAttendees }).then((reply) => {
      if (reply?.data) {
        getSession();
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  return (
    <div className='mt-1 bg-light shadow p-3' style={{ maxWidth: '630px', margin: "auto" }}>
      <h2 className='text-center'>{session?.batchName} - Attendance
        {session?.session?.attendanceTaken && <i className='fa fa-circle-check text-success ms-4' style={{ fontSize: "20px" }}></i>}
      </h2>
      <h3 className='text-center'>{session?.session?.name}</h3>
      <h5 className='text-center'>{new Date(session?.session?.startTime).toLocaleString()} - {new Date(session?.session?.endTime).toLocaleString()}</h5>
      <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
        validationSchema={validationSchema} initialValues={{}} onSubmit={submitAttendance}>
        {({ errors, touched, values, setFieldValue, setErrors }) => (
          <Form>
            <table className='table table-bordered'>
              <tbody>
                {session?.participants?.map((participant: any) => {
                  return <tr>
                    <td>{participant.name}</td>
                    <td>
                      <Field style={{ width: "20px", height: "20px" }} type='checkbox' name={participant.username} checked={attendees[participant.username]} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setAttendees({ ...attendees, ...{ [participant.username]: e.target.checked } }) }}></Field>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
            <button className='btn btn-primary'>Submit</button>
          </Form>)}
      </Formik>
    </div>
  )
}