import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import { Form, Formik } from 'formik';
import { ViewSection } from '../../modules/components/display/ViewSection';
import Latex from 'react-latex-next';
import SequenceVertical from '../../modules/components/display/displayquestiontypes/SequenceVertical';
import SequenceHorizontal from '../../modules/components/display/displayquestiontypes/SequenceHorizontal';
import DragDrop from '../../modules/components/display/displayquestiontypes/DragAndDrop';
import showAttempt from '../../utils/showAttempt';
type ApiParams = { id: string };
const OfflineReportsGroup: FC = () => {
  const { id } = useParams<ApiParams>();
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-offline-reports/' + id).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }

  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {report.batchList && <div>
        <h2 className='text-center'>{report.batchList[0].name} - {report.userDetails[report.session.teacherId]}</h2>
        <div className='text-center'><strong>{new Date(report.session.startTime).toLocaleDateString('en-GB')}</strong></div>
      </div>}
      {report.batchList && <div className='pb-15'><Link to={'/class-reports-question/'+report.session._id} type='button' className='btn btn-dark p-2 m-2 float-end'><i className='fa fa-line-chart'></i> by Question</Link></div>}
      <div className='row ms-2'>
        <strong className='row'>
          <div className='col-9'>Students</div>
          <div className='col-3'>Total</div>
        </strong>
      </div>

      {report && report.session && Object.entries(report.session.groupData)
        .sort(([keyA, valueA], [keyB, valueB]) => { return (valueB as { totalPoints: number }).totalPoints - (valueA as { totalPoints: number }).totalPoints })?.map((data: any, index: number) => {
          return <div className='shadow-lg p-3 m-2 mt-4 rounded'>
            <h2 className="accordion-header">
              <button className="btn btn-default text-start collapsed w-100" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="false" aria-controls={"collapse" + index}>
                <div className='row'>
                  <div className='col-9'>{data[1].students?.map((student: any) => {
                    return report.userDetails[student]
                  }).join(',')}</div>
                  <div className='col-3'>
                    <span className='ms-4'>{data[1].totalPoints}</span>
                    <i className="fa-solid fa-caret-down float-end" style={{ fontSize: '18px' }}></i>
                  </div>
                </div>
              </button>
            </h2>
            <div id={"collapse" + index} className="accordion-collapse collapse ms-1" aria-labelledby="" data-bs-parent="">
              <table className='table table-bordered'>
                <thead><tr><th></th><th>Result</th><th>Points</th></tr></thead>
                {
                  report?.session?.showOrder.map((question: any, index: number) => {
                    return <>{(() => {
                      let challege = report.challenges.filter((c: any) => c._id == report.challengeInstances.filter((ci: any) => ci._id == question.challengeInstanceId)[0].challengeId)[0]
                      let section = report.sections.filter((section: any) => section._id == challege.sections[question.sectionNo])[0]
                      let attempt = data[1]?.[challege._id]?.[section._id] || {}
                      return <><tr>
                        <td className='p-3'><strong>Question No. {index + 1}.</strong></td>
                        <td>
                          {attempt && attempt.correct == false && <button className='btn btn-danger p-2'>Wrong</button>}
                          {attempt && attempt.correct == true && <button className='btn btn-success p-2'>Correct</button>}
                          {(!attempt || (attempt.correct != false && !attempt.correct)) && <span className='text-danger'>Not-Attempted</span>}
                          {/* {attempt ? (attempt['correct'] ? <span className={'p-2 bg-success text-light'}>Correct</span> : <span className={'p-2 bg-danger text-light'}>Wrong</span>) : '-'} */}
                        </td>
                        <td>{attempt && attempt.points ? attempt['points'] : '-'}</td>
                      </tr>
                        <tr><td className='p-3 ps-8' colSpan={3}>
                          <div>
                            <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                              validationSchema={{}} initialValues={{}} onSubmit={() => { }}>
                              {({ errors, touched, values, setFieldValue, setErrors }) => (
                                <Form>
                                  {ViewSection(section, true, 1, errors, values, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword' || section.type == 'dragdrop') ? { ...section, ...{ correct: true } } : ((section.type == 'short' || section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives') ? { ...section, ...{ correct: false } } : section)))}
                                </Form>)}</Formik>
                          </div>
                          <div className='border-bottom border-dark'><b>Answer Submitted</b> - {showAttempt(section, attempt)}</div>
                        </td></tr>
                      </>
                      {/*<Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                    validationSchema={{}} initialValues={{}} onSubmit={()=>{}}>
                    {({ errors, touched, values, setFieldValue, setErrors }) => (
                      <Form>
                        <div>
                          {JSON.stringify(attempt)}
                        </div>
                        {ViewSection(section, true, 1, errors, values, setFieldValue)}
                    </Form>)}</Formik>*/}

                    })()}
                    </>

                  })
                }
              </table>
            </div>
          </div>
        })}
    </div>
  )
}

export { OfflineReportsGroup }
