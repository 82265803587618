import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { DisplayLoader } from '../../../utils/loader'
import axios from 'axios'
import { error } from 'console'

const initialValues = {
  password: 'admin@demo.com',
  changepassword: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password is too short')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      "Must Contain atleast Once letter, One Number and One Symbol"
    )
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})
type ApiParams = { token: string };
export function ResetPassword() {
  const { token } = useParams<ApiParams>();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [state, setState] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setError("")
      setHasErrors(undefined)
      DisplayLoader(true, '')
      try {
        axios.post(process.env.REACT_APP_API_URL + '/reset-password/' + token, values).then((data) => {
          if (data.data.error) {
            setError(data.data.error)
          } else {
            setSuccess("Password changed successfully. Go to login page to continue.");
          }
          DisplayLoader(false, '')
        }).catch(() => {
          setError("Unknown Error")
          DisplayLoader(false, '')
        })
        DisplayLoader(false, '')
      } catch (e) {
        setError("Unknown Error")
        DisplayLoader(false, '')
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {error && error !== '' && (
        <div className='mb-lg-15 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}
      {success && success !== '' && (
        <div className='mb-lg-15 alert alert-success text-center'>
          <div className='alert-text font-weight-bold'>
            {success}
          </div>
        </div>
      )}
      {success === '' && <>
        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Go To Login Page
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </>}
      {success !== '' && <Link to='/auth/login'>
        <button
          type='button'
          id='kt_login_password_reset_form_cancel_button'
          className='btn btn-light col-12'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          Go To Login Page
        </button>
      </Link>}
    </form>
  )
}
