import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';

type ApiParams = { userId: string };
export const DeliveryStatus = () => {
  const { userId } = useParams<ApiParams>();
  let [messages, setMessages] = useState<any>({});
  useEffect(() => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/delivery-status/' + userId).then((reply) => {
      if (reply?.data) {
        setMessages(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }, [])
  return (
    <div className='mt-1 bg-light shadow p-3'>
      <h3 className='text-center'>{messages?.name} - Message Status</h3>
      <table className='table table-bordered'>
        <thead>
          <tr><th>Message</th><th>Type</th><th>Status</th><th>Date</th></tr>
        </thead>
        <tbody>
          {
            messages?.messages?.map((message: any) => {
              return <tr>
                <td>{message.message}</td>
                <td>{message.type[0].toUpperCase()}</td>
                <td>{message.status[0].toUpperCase()}</td>
                <td>{new Date(message.createdAt).toLocaleString()}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  )
}