/* eslint-disable jsx-a11y/anchor-is-valid */
import { Section } from '../../../../models/Section'
import { Field, FormikErrors } from 'formik'
import { ErrorDisplay } from '../../common/ErrorDisplay'
import KaTeXRenderer from '../../../../pages/utils/KatexRenderer';
import "katex/dist/katex.min.css";
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { useEffect, useState } from 'react';

export const SevenLives = (word: string, section: Section, disabled: boolean, values: any, attempt?: any, submitAnswer?: Function) => {
  const click = (letter: string) => {
    let answer = values.answer ? values.answer : '';
    if (answer.indexOf(letter) == -1 || letter == '#') {
      answer = answer + letter;
      if (submitAnswer) {
        submitAnswer({ answer: answer, sectionId: section._id })
      }
    }
  }
  /*
  useEffect(() => {
    let tempWord = section.text ? section.text : '';
    tempWord = tempWord.replace(/(<([^>]+)>)/ig, '').toLowerCase();
    setWord(tempWord);
    setLives(7 - (attempt?.answer ? attempt?.answer?.replace(new RegExp('[' + tempWord + ']', 'g'), '').length : 0));
  }, [attempt, section])
  let [word, setWord] = useState<string>('');
  let [lives, setLives] = useState<number>(7);
  */
  return (
    <div className='text-dark text-center'>
      {attempt && attempt.correct && <h3>You guessed it right!</h3>}
      {((!attempt || !attempt.correct) && (7 - (attempt?.answer ? attempt?.answer?.replace(new RegExp('[' + word + ']', 'g'), '').length : 0)) > 0) && <div>
        <div className='text-dark mb-1'>Guess the word</div>
        <h3>{(7 - (attempt?.answer ? attempt?.answer?.replace(new RegExp('[' + word + ']', 'g'), '').length : 0))}</h3>
        <h6>Lives Left</h6>
      </div>}
      {
        (7 - (attempt?.answer ? attempt?.answer?.replace(new RegExp('[' + word + ']', 'g'), '').length : 0)) == 0 && <h3>Out of lives</h3>
      }
      <div className='mb-5 mt-5'>
        {
          word.split('').map((w) => {
            if (attempt && attempt.answer && attempt.answer.indexOf(w) != -1) {
              return <span style={{ margin: '2px', fontSize: '24px', minWidth: '24px' }}>{w}</span>
            }
            if (w == ' ') {
              return <span style={{ margin: '2px', fontSize: '24px', minWidth: '24px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            }
            return <span style={{ margin: '2px', fontSize: '24px', minWidth: '24px' }}>{w == ' ' ? '      ' : '_'}</span>
          })
        }
      </div>
      <div className='mb-5'>
        {attempt?.answer?.replace(/[^#]/g, "").length > 0 && <h6>Clues</h6>}
        {
          attempt?.answer?.replace(/[^#]/g, "")?.split('').map((l: string, index: number) => {
            return <KaTeXRenderer>{section.options[index]}</KaTeXRenderer>
          })
        }
      </div>
      <div className='text-center'>
        <div>
          {'qwertyuiop'.split('').map((l) => {
            return <button type='button' className={'btn shadow btn-sm m-1 border border-dark pe-0 ps-0 ' + (attempt && attempt.answer && attempt.answer.indexOf(l) != -1 ? (word.indexOf(l) == -1 ? 'btn-danger' : 'btn-success') : 'btn-default')} id={l} onClick={() => { click(l) }} disabled={disabled == null || (attempt && attempt.answer && (attempt.answer.indexOf(l) != -1 || attempt.correct))} style={{width: '8%', maxWidth: "50px"}}>{l}</button>
          })}
        </div>
        <div>
          {'asdfghjkl'.split('').map((l) => {
            return <button type='button' className={'btn shadow btn-sm m-1 border border-dark pe-0 ps-0 ' + (attempt && attempt.answer && attempt.answer.indexOf(l) != -1 ? (word.indexOf(l) == -1 ? 'btn-danger' : 'btn-success') : 'btn-default')} id={l} onClick={() => { click(l) }} disabled={disabled == null || (attempt && attempt.answer && (attempt.answer.indexOf(l) != -1 || attempt.correct))} style={{width: '8%', maxWidth: "50px"}}>{l}</button>
          })}
        </div>
        <div>
          {'zxcvbnm'.split('').map((l) => {
            return <button type='button' className={'btn shadow btn-sm m-1 border border-dark pe-0 ps-0 ' + (attempt && attempt.answer && attempt.answer.indexOf(l) != -1 ? (word.indexOf(l) == -1 ? 'btn-danger' : 'btn-success') : 'btn-default')} id={l} onClick={() => { click(l) }} disabled={disabled == null || (attempt && attempt.answer && (attempt.answer.indexOf(l) != -1 || attempt.correct))} style={{width: '8%', maxWidth: "50px"}}>{l}</button>
          })}
        </div>
        <div>
          <button type='button' className='btn btn-default shadow m-1 mt-4 border border-dark p-1 ps-20 pe-20' id='#' onClick={() => { click('#') }} disabled={disabled == null || (section.options?.length - attempt?.answer?.replace(/[^#]/g, "").length <= 0 || attempt && attempt.correct)}>Clue ({section.options?.length - (attempt?.answer ? attempt?.answer?.replace(/[^#]/g, "").length : 0)})</button>
        </div>
      </div>
    </div>
  )
}