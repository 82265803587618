import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect } from 'react'
import { DisplayLoader } from '../../utils/loader';
import { useAuth } from '../../modules/auth';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
type Props = { id: string };
const ReportsList: FC<Props> = ({ id }) => {
  const { currentUser, logout } = useAuth()
  let [presentations, setPresentations] = useState<any>([]);
  let [batches, setBatches] = useState<any>([]);
  let [showModal, setShowModal] = useState<boolean>(false);
  let [isOffline, setIsOffline] = useState<boolean>(false);
  const listPresentations = () => {
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-instance-list/' + id).then((reply) => {
      if (reply?.data?.challengeInstances) {
        setPresentations(reply.data.challengeInstances);
        setIsOffline(reply.data.isOffline);
      } else {
        //TODO
      }
    })
  };
  const listBatches = () => {
    if (batches.length > 0) return
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-list/').then((reply) => {
      if (reply?.data) {
        setBatches(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  return (
    <>
      <button type='button' onClick={() => { listPresentations(); listBatches(); setShowModal(true) }} className='btn btn-info m-1 float-end p-2 pt-1 pb-1 shadow-lg'>
        <i className="fa fa-chart-line"></i><small>Reports</small>
      </button>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowModal(false) }}>
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Reports</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            {currentUser?.isNotLoggedIn && <div className='text-center'>
              <h4>Access this feature by Logging In or Registering for free</h4>
              <button className='btn btn-info pb-1 pt-1' onClick={() => { logout() }}>Log In<br />
                <span style={{ fontSize: "8px" }}>or Register</span>
              </button>
            </div>}
            {!currentUser?.isNotLoggedIn && presentations && presentations.length > 0 && <div className='text-center'>
              <div className='col-12 d-inline-block text-left'>
                <strong>
                  <div className='col-4 d-inline-block text-center'>Type</div>
                  <div className='col-4 d-inline-block text-center'>Batch</div>
                  <div className='col-4 d-inline-block text-center'>{isOffline ? 'Date' : 'End Time'}</div>
                </strong>
              </div>
              {
                presentations.map((presentation: any) => {
                  return <div>{
                    <Link to={'/composite-reports/' + presentation._id}>
                      <div className='card p-3 m-2 col-12 d-inline-block border-bottom'>
                        <div className='col-4 d-inline-block'>
                          {presentation.type.slice(0, 1).toUpperCase() + presentation.type.slice(1)}
                        </div>
                        <div className='col-4 d-inline-block'>
                          {batches.filter((batch: any) => { return batch._id === presentation.batchId })[0]?.name}
                        </div>
                        <div className='col-4 d-inline-block'>
                          {presentation.end && !isOffline && new Date(presentation.end).toLocaleString()}
                          {presentation.end && isOffline && new Date(presentation.end).toLocaleDateString()}
                        </div>
                      </div>
                    </Link>
                  }</div>
                })
              }
            </div>}
          </div>
        </div>
      </Modal>
    </>
  )
}

export { ReportsList }
