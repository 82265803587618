let tagMap = {
  "6th": {
    "English": {
      "Honeysuckle": [
        "Chapter 1 Who Did Patrick’s Homework?",
        "Chapter 2 How the Dog Found Himself a Master",
        "Chapter 3 Taro’s Reward",
        "Chapter 4 An Indian – American Woman in Space: Kalpana Chawala",
        "Chapter 5 A Different Kind of School",
        "Chapter 6 Who I Am",
        "Chapter 7 Fair Play",
        "Chapter 8 The Banyan Tree"
      ],
      "A Pact With The Sun": [
        "Chapter 1 A Tale of Two Birds",
        "Chapter 2 The Friendly Mongoose",
        "Chapter 3 The Shepherd’s Treasure",
        "Chapter 4 Tansen",
        "Chapter 5 The Monkey and the Crocodile",
        "Chapter 6 The Wonder Called Sleep",
        "Chapter 7 A Pact with the Sun"
      ]
    },
    "Social": {
      "Geography": [
        "Chapter 1 The Earth in the Solar System",
        "Chapter 2 Globe Latitudes and Longitudes",
        "Chapter 3 Motions of the Earth",
        "Chapter 4 Maps",
        "Chapter 5 Major Domains of the Earth",
        "Chapter 6 Our Country – India"
      ],
      "History": [
        "Chapter 1 What, Where, How And When",
        "Chapter 2 From Hunting, Gathering to Growing Food",
        "Chapter 3 In the Earliest Cities",
        "Chapter 4 What Books and Burials Tell Us",
        "Chapter 5 Kingdoms, Kings and An Early Republic",
        "Chapter 6 New Questions and Ideas",
        "Chapter 7 From Kingdom to an Empire",
        "Chapter 8 Villages, Towns and Trade",
        "Chapter 9 New Empires and Kingdoms",
        "Chapter 10 Buildings, Paintings and Books"
      ],
      "Civics": [
        "Chapter 1 Understanding Diversity",
        "Chapter 2 Diversity and Discrimination",
        "Chapter 3 What is Government",
        "Chapter 4 Panchayati Raj",
        "Chapter 5 Rural Administration",
        "Chapter 6 Urban Administration",
        "Chapter 7 Rural Livelihoods",
        "Chapter 8 Urban Livelihoods"
      ]
    }
  },
  "7th": {
    "Social": {
      "History : Our Pasts – II": [
        "Chapter 1 Tracing Changes Through a Thousand Years",
        "Chapter 2 Kings and Kingdoms",
        "Chapter 3 Delhi: 12th to 15th Century",
        "Chapter 4 The Mughal (16th to 17th Century)",
        "Chapter 5 Tribes, Nomads and Settled Communities",
        "Chapter 6 Devotional Paths to the Divine",
        "Chapter 7 The Making of Regional Cultures",
        "Chapter 8 Eighteenth-Century Political Formations"
      ],
      "Civics – Social and Political Life II": [
        "Chapter 1 On Equality",
        "Chapter 2 Role of the Government in Health",
        "Chapter 3 How the State Government Works",
        "Chapter 4 Growing up as Boys and Girls",
        "Chapter 5 Women Change the World",
        "Chapter 6 Understanding Media",
        "Chapter 7 Markets Around Us",
        "Chapter 8 A Shirt in the Market"
      ],
      "Geography – Our Environment": [
        "Chapter 1 Environment",
        "Chapter 2 Inside Our Earth",
        "Chapter 3 Our Changing Earth",
        "Chapter 4 Air",
        "Chapter 5 Water",
        "Chapter 6 Human Environment Interactions",
        "Chapter 7 Life in the Deserts"
      ]
    },
    "English": {
      "An Alien Hand": [
        "Chapter 1 The Tiny Teacher",
        "Chapter 2 Bringing Up Kari",
        "Chapter 3 Golu Grows a Nose",
        "Chapter 4 Chandni",
        "Chapter 5 The Bear Story",
        "Chapter 6 A Tiger in the House",
        "Chapter 7 An Alien Hand"
      ],
      "Honeycomb": [
        "Chapter 1 Three Questions; The Squirrel",
        "Chapter 2 A Gift of Chappals; The Rebel",
        "Chapter 3 Gopal and the Hilsa Fish; The Shed",
        "Chapter 4 The Ashes that Made Trees Bloom; Chivvy",
        "Chapter 5 Quality; Trees",
        "Chapter 6 Expert Detectives; Mystery of the Talking Fan",
        "Chapter 7 The Invention of Vita-Wonk, Dad and the Cat and the Tree, Garden Snake",
        "Chapter 8 Homage to our Brave Soldiers, Meadow Surprises"
      ]
    }
  },
  "8th": {
    "English": {
      "Honeydew": [
        "Chapter 1 The Best Christmas Present in the World",
        "Chapter 2 The Tsunami",
        "Chapter 3 Glimpses of the Past",
        "Chapter 4 Bipin Choudhury’s Lapses Memory",
        "Chapter 5 The Summit Within",
        "Chapter 6 This is Jody’s Fawn",
        "Chapter 7 A Visit to Cambridge",
        "Chapter 8 A Short Monsoon Diary"
      ],
      "It So Happened": [
        "Chapter 1 How the Camel got his hump",
        "Chapter 2 Children at Work",
        "Chapter 3 The Selfish Giant",
        "Chapter 4 The Treasure Within",
        "Chapter 5 Princess September",
        "Chapter 6 The Fight",
        "Chapter 7 Jalebis",
        "Chapter 8 Ancient Education System of India"
      ]
    },
    "Social": {
      "Geography": [
        "Chapter 1 Resources",
        "Chapter 2 Land, Soil, Water, Natural Vegetation",
        "Chapter 3 Agriculture",
        "Chapter 4 Industries",
        "Chapter 5 Human Resources"
      ],
      "History": [
        "Chapter 1 How, When and Where",
        "Chapter 2 From Trade to Territory",
        "Chapter 3 Ruling the Countryside",
        "Chapter 4 Tribal, Dikus and The Vision",
        "Chapter 5 When People Rebel",
        "Chapter 6 Civilising the “Native”, Educating the Nation",
        "Chapter 7 Women, Caste and Reform",
        "Chapter 8 The Making of the National Movement: 1870s -1947"
      ],
      "Civics": [
        "Chapter 1 The Indian Constitution",
        "Chapter 2 Understanding Secularism",
        "Chapter 3 Parliament and the Making of Laws",
        "Chapter 4 Judiciary",
        "Chapter 5 Understanding Marginalisation",
        "Chapter 6 Confronting Marginalisation",
        "Chapter 7 Public Facilities",
        "Chapter 8 Law and Social Justice"
      ]
    }
  },
  "9th": {
    "Social": {
      "Geography": [
        "Chapter 1 India – Size and Location",
        "Chapter 2 Physical Features of India",
        "Chapter 3 Drainage",
        "Chapter 4 Climate",
        "Chapter 5 Natural Vegetation and Wildlife",
        "Chapter 6 Population"
      ],
      "History": [
        "Chapter 1 The French Revolution",
        "Chapter 2 Socialism in Europe and the Russian Revolution",
        "Chapter 3 Nazism and the Rise of Hitler",
        "Chapter 4 Forest Society and Colonialism",
        "Chapter 5 Pastoralists in the Modern India"
      ],
      "Civics": [
        "Chapter 1 What Is Democracy? Why Democracy?",
        "Chapter 2 Constitutional Design",
        "Chapter 3 Electoral Politics",
        "Chapter 4 Working Of Institutions",
        "Chapter 5 Democratic Rights"
      ],
      "Economics": [
        "Chapter 1 The Story of Village Palampur",
        "Chapter 2 People as Resource",
        "Chapter 3 Poverty as a Challenge",
        "Chapter 4 Food Security in India"
      ]
    },
    "English": {
      "Beehive": [
        "Chapter 1 The Fun They Had",
        "Chapter 2 The Sound of Music",
        "Chapter 3 The Little Girl",
        "Chapter 4 Truly Beautiful Mind",
        "Chapter 5 The Snake and the Mirror",
        "Chapter 6 My Childhood",
        "Chapter 7 Reach for the Top",
        "Chapter 8 Kathmandu",
        "Chapter 9 If I Were You"
      ],
      "Moments": [
        "Chapter 1 The Lost Child",
        "Chapter 2 The Adventures of Toto",
        "Chapter 3 Iswaran The Storyteller",
        "Chapter 4 In the Kingdom of Fools",
        "Chapter 5 The Happy Prince",
        "Chapter 6 Weathering The Storm In Ersama",
        "Chapter 7 The Last Leaf",
        "Chapter 8 A House Is Not A Home",
        "Chapter 9 The Beggar"
      ]
    }
  },
  "10th": {
    "English": {
      "First Flight": [
        "Chapter 1 A Letter to God",
        "Chapter 2 Nelson Mandela: Long Walk to Freedom",
        "Chapter 3 Two Stories about Flying",
        "Chapter 4 From the Diary of Anne Frank",
        "Chapter 5 Glimpses of India",
        "Chapter 6 Mijbil the Otter",
        "Chapter 7 Madam Rides the Bus",
        "Chapter 8 The Sermon at Benares",
        "Chapter 9 The Proposal"
      ],
      "Footprints without Feet": [
        "Chapter 1 A Triumph of Surgery",
        "Chapter 2 The Thiefs Story",
        "Chapter 3 The Midnight Visitor",
        "Chapter 4 A Question of Trust",
        "Chapter 5 Footprints without Feet",
        "Chapter 6 The Making Of A Scientist",
        "Chapter 7 The Necklace",
        "Chapter 8 Bholi",
        "Chapter 9 The Book That Saved The Earth"
      ]
    },
    "Social": {
      "Geography": [
        "Chapter 1 Resources and Development",
        "Chapter 2 Forest and Wildlife Resources",
        "Chapter 3 Water Resources",
        "Chapter 4 Agriculture",
        "Chapter 5 Minerals and Energy Resources",
        "Chapter 6 Manufacturing Industries",
        "Chapter 7 Lifelines of National Economy"
      ],
      "History": [
        "Chapter 1 The Rise of Nationalism in Europe",
        "Chapter 2 Nationalism in India",
        "Chapter 3 The Making of a Global World",
        "Chapter 4 The Age of Industrialisation",
        "Chapter 5 Print Culture and the Modern World"
      ],
      "Civics": [
        "Chapter 1 Power-sharing",
        "Chapter 2 Federalism",
        "Chapter 3 Gender, Religion and Caste",
        "Chapter 4 Political Parties",
        "Chapter 5 Outcomes of Democracy"
      ],
      "Economics": [
        "Chapter 1 Development",
        "Chapter 2 Sectors of Indian Economy",
        "Chapter 3 Money and Credit",
        "Chapter 4 Globalisation and The Indian Economy",
        "Chapter 5 Consumer Rights"
      ]
    }
  }
}
export default tagMap;