import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { Form, Formik, FormikValues, Field } from 'formik'
import { EditSection } from '../../modules/components/display/EditSection'
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { DisplayLoader } from '../../utils/loader';
import { Value } from 'sass';
import Select from 'react-select'
import { topicList } from '../../models/LocalLists';

const Review: FC = () => {
  let [topics, setTopics] = useState<string[]>([]);
  let [tags, setTags] = useState<any>([]);
  let [tagToSearch, setTagsToSearch] = useState<any>([]);
  let [sectionState, setSectionState] = useState<number>(0);//0-list,1-preview,2-edit
  let [isEditing, setIsEditing] = useState<boolean>(false);
  let [sections, setSections] = useState<any>([]);
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/section-tags/').then((reply) => {
      if (reply.data) {
        setTags(reply.data)
      }
    }).catch(() => {
      //TODO
    })
  }, []);
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, false, false));
  }
  const updateTopics = (values: any) => {
    setTopics(values?.map((value: any) => { return value.value }))
  }
  const saveSection = (section: Section, approval?: boolean) => {
    if (approval != null) {
      section.reviewStatus = approval ? 1 : -2;
    }
    setSectionState(0);
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/sections/' + section._id, section).then((reply) => {
      if (reply?.data) {
        if(approval != null) {
          searchQuestions(); 
        }
        /*
        if (approval != null) {
          searchQuestions();
        } else {
          setSections([reply.data])
        }
        */
        DisplayLoader(false, '')
      } else {
        DisplayLoader(false, '')
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      DisplayLoader(false, '')
    });
  }
  const searchQuestions = () => {
    //let search = (document.getElementById('search') as HTMLInputElement)?.value || '';
    if (topics.length == 0) return
    //let skip = (document.getElementById('skipCount') as HTMLInputElement)?.value || 0;
    let reviewStatusList = [null, -1]
    if((document.getElementById('includeReviewed') as HTMLInputElement)?.checked) {
      reviewStatusList = [1];
    }
    axios.post(process.env.REACT_APP_API_URL + '/sections/search', { query: { topics: topics, reviewStatus: { $in:  reviewStatusList} }, limit: 10, skip: 0 }).then((reply) => {
      if (reply?.data) {
        setSections(reply.data)
      } else {
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg bg-light shadow'>
      <div>
        {sectionState == 1 && <div className='col-12 d-inline-block bg-light p-1 align-top border border-left-0 border-top-0 border-bottom-0 border-dark'>
          <div className='mb-2'>
            {tagToSearch.map((tag: string) => {
              return <div className="btn-group m-1" role="group" aria-label="Button group with cancel/delete options">
                <button type='button' className='p-2 btn btn-dark'>{tag}</button>
                <button type="button" className="btn btn-danger p-2" onClick={() => { let t = [...tagToSearch]; t.splice(tagToSearch.indexOf(tag), 1); setTagsToSearch(t) }}>X</button>
              </div>
            })}
            <Field as="select" name="type" className="m-1" onChange={(e: any) => { if (tagToSearch.indexOf(e.target.value) == -1) { setTagsToSearch([...tagToSearch, ...[e.target.value]]) } }}>
              <option value="">Select Tags to Search</option>
              {tags.map((tag: string) => {
                return <option value={tag}>{tag}</option>
              })}
            </Field>
            <div className='mt-2'>
              {tagToSearch.length > 0 && <button type='button' className='btn btn-primary p-2' onClick={() => { searchQuestions() }}>Search</button>}
            </div>
          </div>
          <hr className='m-2' />
          {
            sections?.map((section: Section, index: number) => {
              return (
                <div key={index}>
                  <div className={'shadow bg-light mb-1 rounded p-1 border border-dark border-1'} style={{ width: "100%", textAlign: "left" }}>
                    <div className='text-center text-dark'><b>Section {index + 1}</b></div>
                    <div>
                      <strong>Tags -</strong>
                      {section?.tags?.map((tag: string) => {
                        return <button className='p-1 btn btn-dark m-1'>{tag}</button>
                      })}
                    </div>
                    {/*<div className='mt-1'>
                      <strong>Points - {section.points}</strong>
                    </div>*/}
                    {section.reviewStatus != 1 && <div className='mt-1'>
                      <strong>Source - {section.source && section.source != 'ChatGPT' ? 'Trusted' : 'Untrusted'}</strong>
                    </div>}
                    <div className='mt-1'>
                      <strong>Review Status - {section.reviewStatus == 1 ? 'Reviewed' : ((!section.reviewStatus || section.reviewStatus == -1) ? 'Not Reviewed' : 'Rejected')}</strong>
                    </div>
                    <div className='mt-1'>
                      <strong>Difficulty Level - {section.difficultyLevel}</strong>
                    </div>
                    <hr />
                    <div>
                      {/*ViewSection(section, true, 1, errors, values, setFieldValue, section)*/}
                    </div>
                    <div className='text-center m-2'>
                      <button type='button' className='btn btn-success p-2 float-start' onClick={() => { saveSection(section, true); }}>Approve</button>
                      <button type='button' className='btn btn-dark p-2' onClick={() => { setInitValues(section); setSectionState(2) }}>Edit Section</button>
                      <button type='button' className='btn btn-danger p-2 float-end' onClick={() => { saveSection(section, false) }}>Reject</button>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>}
        {sectionState == 0 && <div className='col-12 d-inline-block shadow bg-light rounded p-5 card-body'>
          <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            validationSchema={validationSchema} initialValues={{}} onSubmit={() => { }}>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
              <Form>
                <div className='mb-2'>
                  {/*{tagToSearch.map((tag: string) => {
                    return <div className="btn-group m-1" role="group" aria-label="Button group with cancel/delete options">
                      <button type='button' className='p-2 btn btn-dark'>{tag}</button>
                      <button type="button" className="btn btn-danger p-2" onClick={() => { let t = [...tagToSearch]; t.splice(tagToSearch.indexOf(tag), 1); setTagsToSearch(t) }}>X</button>
                    </div>
                  })}
                  <Field as="select" name="type" className="m-1" onChange={(e: any) => { if (tagToSearch.indexOf(e.target.value) == -1) { setTagsToSearch([...tagToSearch, ...[e.target.value]]) } }}>
                    <option value="">Select Tags to Search</option>
                    {tags.map((tag: string) => {
                      return <option value={tag}>{tag}</option>
                    })}
                  </Field>
                  <div className='mt-4'><label className='form-label fw-bolder text-dark fs-6'>Topics</label></div>
                  */}
                  <div className='col-12 d-inline-block'>
                    <Select name='topicList' id='topicList' options={topicList('JEE-NEET', false)?.map((topic: string) => { return { value: topic, label: topic } })} isMulti={true} onChange={updateTopics} />
                  </div>
                  <div><input type='checkbox' id='includeReviewed' className='me-3 mt-2'/>Only Reviewed</div>
                  {/*<input type='text' id="search" placeholder='Search questions.... (10th Polynomial)'></input>
                  <input type='text' id="skipCount" placeholder='Skip x sections'></input>*/}
                  <div className='mt-2'>
                    <button type='button' className='btn btn-primary p-2' onClick={() => { searchQuestions() }}>Search</button>
                  </div>
                </div>
                <hr className='m-2' />
              </Form>
            )}
          </Formik>
          {sections?.map((section: Section, index: number) => {
            return (
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={section} onSubmit={() => { }}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div>
                      <label className='form-label fw-bolder text-dark fs-6'>Points</label><Field type='number' name='points' className='form-control d-inline mb-1' style={{ width: "75px", marginLeft: "5px" }} />
                      {ErrorDisplay('points', errors)}
                      <button type='button' className='btn btn-primary float-end' onClick={() => { setIsEditing(!isEditing) }}>{isEditing ? 'View' : 'Edit'}</button>
                    </div>
                    <div className='mt-1'>
                      <strong>Source - {section.source && section.source != 'ChatGPT' ? 'Trusted' : 'Untrusted'}</strong>
                    </div>
                    <div className='mt-1'>
                      <strong>Review Status - {section.reviewStatus == 1 ? 'Reviewed' : ((!section.reviewStatus || section.reviewStatus == -1) ? 'Not Reviewed' : 'Rejected')}</strong>
                    </div>
                    <div className='mt-1'>
                      <strong>Difficulty Level - {section.difficultyLevel}</strong>
                    </div>
                    <div style={{ display: !isEditing ? 'block' : 'none' }}>
                      <div>
                        <strong>Tags -</strong>
                        {section?.tags?.map((tag: string) => {
                          return <button className='p-1 btn btn-dark m-1'>{tag}</button>
                        })}
                      </div>
                      {ViewSection(section, true, 1, errors, values, setFieldValue, section)}
                    </div>
                    <div style={{ display: isEditing ? 'block' : 'none' }}>
                      <Field as="select" name="type" className="form-select mb-1" onChange={(e: any) => { values.type = e.target.value; updateValidationSchema(values) }}>
                        <option value="">Choose the challenge type</option>
                        <option value="slide">Slide</option>
                        <option value="mcq">Multiple Choice Question (MCQ)</option>
                        <option value="multiple">Multiple Choice Question with Multiple Answers</option>
                        <option value="numerical">Numerical</option>
                        <option value="short">Short Text</option>
                        <option value="long">Long Text</option>
                        <option value="blanks">Fill in the blanks</option>
                        <option value="matrix">Matrix Match</option>
                        <option value="click">Click</option>
                        <option value="crossword">Crossword</option>
                        <option value="7lives">7 Lives Game</option>
                      </Field>
                      {ErrorDisplay('type', errors)}
                      {EditSection(values, errors, setFieldValue)}
                    </div>
                    <div className='text-center m-2 mt-5'>
                      <button type='button' className='btn btn-success float-start' onClick={() => { saveSection(section, true); }}>Approve</button>
                      <button type='button' className='btn btn-success' onClick={() => { saveSection(values) }}>Save</button>
                      <button type='button' className='btn btn-danger float-end' onClick={() => { saveSection(section, false) }}>Reject</button>
                    </div>
                    <hr />
                  </Form>
                )}
              </Formik>)
          })}
        </div>}
      </div>
    </div>
  )
}
export { Review }