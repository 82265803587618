import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import axios from 'axios';

type ApiParams = { batchId: string };
export const Messages = () => {
  const { batchId } = useParams<ApiParams>();
  let [batch, setBatch] = useState<any>({});
  let [noticeBoard, setNoticeBoard] = useState<any>([]);
  let [batchUsers, setBatchUsers] = useState<any>([]);
  let [showSendMessages, setShowSendMessages] = useState<boolean>(false);
  let [showDeliveryStatus, setShowDeliveryStatus] = useState<boolean>(false);
  useEffect(() => {
    noticeBoardInit();
    batchInit();
  }, [])
  const batchInit = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-min/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatch(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const noticeBoardInit = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/notice-board/' + batchId).then((reply) => {
      if (reply?.data) {
        setNoticeBoard(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const listBatchUsers = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-users/' + batchId).then((reply) => {
      if (reply?.data) {
        setBatchUsers(reply.data);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  const addMessage = () => {
    DisplayLoader(true, '')
    let input: any = {};
    input.message = (document.getElementById('message') as HTMLInputElement).value;
    input.channels = []
    if ((document.getElementById('noticeboard') as HTMLInputElement).checked) {
      input.channels.push('noticeboard')
    }
    if ((document.getElementById('sms') as HTMLInputElement).checked) {
      input.channels.push('sms')
    }
    if ((document.getElementById('email') as HTMLInputElement).checked) {
      input.channels.push('email')
    }
    if ((document.getElementById('whatsapp') as HTMLInputElement).checked) {
      input.channels.push('whatsapp')
    }
    axios.post(process.env.REACT_APP_API_URL + '/messages/' + batchId, input).then((reply) => {
      if (reply?.data?.success) {
        noticeBoardInit();
        setShowSendMessages(false);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }).catch(() => {
      //TODO
      DisplayLoader(false, '')
    })
  }
  return (
    <div className='mt-1 bg-light shadow p-3'>
      <h3 className='text-center mb-20'>{batch ? (batch.name + ' - ') : ''}Notice Board
        <div className='d-block'>
          <button className='float-end m-3 btn btn-primary p-2' onClick={() => { listBatchUsers(); setShowDeliveryStatus(true) }}>Delivery Status</button>
          <button className='float-end m-3 btn btn-primary p-2' onClick={() => { setShowSendMessages(true) }}>Create Message</button>
        </div>
      </h3>
      <div>
        {
          noticeBoard.sort((a: any, b: any) => b.updatedAt.localeCompare(a.updatedAt)).map((message: any) => {
            return <div className='shadow mb-4 mt-4 p-3 rounded pb-7'>
              <div className='mb-3'>{message.message}</div>
              <div style={{ fontSize: "12px" }} className='float-end'><strong>{new Date(message.updatedAt).toLocaleDateString()}</strong></div>
            </div>
          })
        }
      </div>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showDeliveryStatus} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowDeliveryStatus(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>Message Delivery Status</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            {
              batchUsers.map((user: any) => {
                return <a href={'/delivery-status/' + user._id} className='mb-4'>
                  <h5 className='text-primary'>{user.name}</h5>
                </a>
              })
            }
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showSendMessages} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowSendMessages(false) }}>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>New Message</Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            <div className='d-block pb-15'>
              <input type='checkbox' id='noticeboard'></input><span className='ps-2 pe-4'>Notice Board</span>
              <input type='checkbox' id='email'></input><span className='ps-2 pe-4'>Email</span>
              <input type='checkbox' id='sms'></input><span className='ps-2 pe-4'>SMS</span>
              <input type='checkbox' id='whatsapp'></input><span className='ps-2 pe-4'>Whatsapp</span>
              <div className='d-block p-3'><textarea rows={10} style={{ width: '100%' }} id='message'></textarea></div>
              <button className='float-end btn btn-primary' onClick={() => { addMessage() }}>Send</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}