// SingleRowDragAndDrop.tsx
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import KaTeXRenderer from '../../../../pages/utils/KatexRenderer';

interface SingleRowDragAndDropProps {
  itemsIn: DraggableItemProps[];
  setFieldValue: any;
}

interface DraggableItemProps {
  id: string;
  name: string;
  index: number;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ id, name, index }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            border: '1px solid #ccc',
            padding: '8px',
            marginRight: '8px',
            backgroundColor: 'white',
            height: '100%',
            ...provided.draggableProps.style,
          }}
        >
          <KaTeXRenderer>{name || ''}</KaTeXRenderer>
        </div>
      )}
    </Draggable>
  );
};

const SequenceHorizontal: React.FC<SingleRowDragAndDropProps> = ({ itemsIn, setFieldValue }) => {
  const [items, setItems] = useState<DraggableItemProps[]>(itemsIn)
  /*  
    [
    { id: '1', name: 'Item 1', index: 0 },
    { id: '2', name: 'Item 2', index: 1 },
    { id: '3', name: 'Item 3', index: 2 },
    { id: '4', name: 'Item 4', index: 3 },
    // Add more items as needed
  ]);
*/
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems.map((item, index) => ({ ...item, index })));
    setFieldValue('answer', reorderedItems.map((item) => item.name))
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: '16px',
              minHeight: '100px',
            }}
          >
            {items.map((item, index) => (
              <DraggableItem key={item.id} {...item} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SequenceHorizontal;
