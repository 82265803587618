import React, { useEffect, useRef, useState } from 'react';
import jsMind from 'jsmind';
import html2canvas from 'html2canvas';
import 'jsmind/style/jsmind.css'; // Ensure the path to CSS is correct
import { systemSort } from '../../../../utils/systemSort';

interface MinMapProps {
  initialData: any;
  setFieldValue: any;
  type?: string;
}

const MindMapQuestion: React.FC<MinMapProps> = ({ initialData, setFieldValue, type }) => {
  const jmContainerRef = useRef<HTMLDivElement | null>(null);
  const [jm, setJm] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [originalNodes, setOriginalNodes] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [showOptions, setShowOptions] = useState<boolean>(true);

  useEffect(() => {
    if (!jmContainerRef.current) return;

    const options = {
      container: jmContainerRef.current,
      theme: 'primary',
      editable: initialData?.options || type == 'mindmapactivity' ? true : false,
    };

    const jmInstance = new jsMind(options);
    if (!initialData || !initialData.meta) {
      initialData = {
        "meta": {
          "name": "demo",
          "author": "olearno",
        },
        "format": "node_tree",
        "data": {
          "id": "root", "topic": "Root",
        }
      };
    }
    jmInstance.show(initialData);
    setJm(jmInstance);

    // Select node handler
    jmInstance.add_event_listener((type: string, data: any) => {
      if (data.evt === 'select_node') {
        setSelectedNode(data);
      }
    });
    setOriginalNodes(findOriginalNodes(initialData.data))
    //return () => jmInstance.destroy();
  }, []);
  const generateOptions = () => {
    let copySelectedOptions = [...selectedOptions]
    return systemSort([...initialData?.options]).filter((item, index) => {
      const idxInB = copySelectedOptions.indexOf(item);
      if (idxInB !== -1) {
        copySelectedOptions.splice(idxInB, 1); // Remove the element from b to handle duplicates
        return false;
      }
      return true;
    })
  }
  const findOriginalNodes = (data: any) => {
    let originalNodes = [data.id]
    if (data.children) {
      for (let i = 0; i < data.children.length; i++) {
        originalNodes = [...originalNodes, ...findOriginalNodes(data.children[i])];
      }
    }
    return originalNodes;
  }
  const addedTopics = (data: any) => {
    let originalNodes = [data.topic]
    if (data.children) {
      for (let i = 0; i < data.children.length; i++) {
        originalNodes = [...originalNodes, ...addedTopics(data.children[i])];
      }
    }
    return originalNodes;
  }
  const addNode = (text: string) => {
    console.log(text)
    if (selectedNode && jm) {
      const selectedNodeId = selectedNode.node;
      const newId = Date.now().toString(); // Simple unique ID generation
      jm.add_node(selectedNodeId, newId, text);
      setSelectedOptions(addedTopics(jm.get_data('node_tree').data))
      setFieldValue('answer', jm.get_data('node_tree').data)
      //jm.select_node(newId)
      //setSelectedNode({id: newId, topic: 'New Node'})
      //jm.begin_edit(newId)
      //console.log(jm.get_data('node_tree').data)
    }
  };
  const deleteNode = () => {
    console.log(selectedNode)
    if (selectedNode && jm && originalNodes.indexOf(selectedNode.node) == -1) {
      jm.remove_node(selectedNode.node);
      setSelectedNode(null);
      setSelectedOptions(addedTopics(jm.get_data('node_tree').data))
      setFieldValue('answer', jm.get_data('node_tree').data)
    }
  };

  return (
    <div>
      {initialData?.options && <button className='btn btn-info p-2 m-1 text-center' type='button' onClick={() => { setShowOptions(!showOptions) }}>{showOptions ? 'Hide' : 'Show'} Options</button>}
      {initialData?.options && <button className='btn btn-info p-2 m-1 text-center float-end' type='button' onClick={deleteNode} disabled={!selectedNode || selectedNode.id === 'root'}><i className="fa fa-trash p-0 m-0"></i></button>}
      {type == 'mindmapactivity' && <button className='btn btn-info p-2 m-1 text-center float-end' type='button' onClick={() => { addNode('node') }} disabled={!selectedNode || selectedNode.id === 'root'}><i className="fa fa-plus p-0 m-0"></i></button>}
      {type == 'mindmapactivity' && <button className='btn btn-info p-2 m-1 text-center float-end' type='button' onClick={deleteNode} disabled={!selectedNode || selectedNode.id === 'root'}><i className="fa fa-trash p-0 m-0"></i></button>}
      {/*<button className='btn btn-info p-2 m-1 text-center' onClick={shoot} ><i className="fa fa-download p-0 m-0"></i></button>*/}
      {showOptions && initialData?.options && <div className='shadow-lg p-2' style={{ width: "30%", zIndex: "1000", height: "500px", position: "absolute", backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        {generateOptions().map((option: any) => {
          return <button type='button' onClick={() => { addNode(option) }} className='btn btn-info p-2 m-1'>{option}</button>
        })}
        {/*systemSort([...initialData?.options])?.map((option: any) => {
          return selectedOptions.indexOf(option) != -1 ? <></> : <button type='button' onClick={() => { addNode(option) }} className='btn btn-info p-2 m-1'>{option}</button>
        })*/}
      </div>}
      <div ref={jmContainerRef} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default MindMapQuestion;
