/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { Navbar } from './Navbar'
type Props = { isRelative: boolean };
const imageName = {
  'default' : {
    'main' : 'default.jpg',
    'small' : 'default-small.jpg',
  },
  'akanksha' : {
    'main' : 'default-a.jpg',
    'small' : 'default-small-a.jpg',
  },
  'cfal' : {
    'main' : 'Cfal-black-1.png',
    'small' : 'Cfal-black-1.png',
  }
}
export function HeaderWrapper({ isRelative }: Props) {
  const { config, classes } = useLayout()
  if (!config.app?.header?.display) {
    return null
  }
  const getLogoUrl = (type : string) => {
    let currentUrl = window.location.hostname
    if(window.location.hostname.indexOf('akanksha') != -1) {
      if(type == 'small') {
        return imageName['akanksha'].small
      } else {
        return imageName['akanksha'].main
      }
    } if(window.location.hostname.indexOf('cfal') != -1) {
      if(type == 'small') {
        return imageName['cfal'].small
      } else {
        return imageName['cfal'].main
      }
    } else {
      if(type == 'small') {
        return imageName['default'].small
      } else {
        return imageName['default'].main
      }
    }
  }
  return (
    <div id='kt_app_header' className='no-print app-header ms-2 me-2 bg-light' style={{zIndex: "1000", position: isRelative ? 'relative' : 'fixed', left: 0, right: 0, marginBottom : isRelative ? '-75px' : '0px'}}>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
            >
              {/*<div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTIcon iconName='abstract-14' className=' fs-1' />
              </div>*/}
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/' className='d-lg-none'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/' + getLogoUrl('small'))}
                    className='h-30px'
                  />
                </Link>
              </div>
            </div>
            <div
              className='d-flex align-items-center d-lg-block d-none ms-n2 me-2'
            >
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 p-2'>
                <Link to='/' >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/'+ getLogoUrl('default'))}
                    className='h-45px'
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/'+ getLogoUrl('default'))}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/'+ getLogoUrl('default'))}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/'+ getLogoUrl('small'))}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
          style={{position:"absolute", right : "10px", top : "10px"}}
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}
