import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_LOCAL_LOGIN_TYPE_STORAGE_KEY = 'kt-auth-react-tryon-login'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}
const getIsNotLoggedIn = () : boolean => {
  if (!localStorage) {
    return false
  }
  const value: string | null = localStorage.getItem(AUTH_LOCAL_LOGIN_TYPE_STORAGE_KEY)
  console.log("here " + (value === 'true' || value === null))
  return value === 'true' || value === null
}
const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}
const setIsNotLoggedIn = (isNotLoggedIn: boolean) => {
  if (!localStorage) {
    return
  }
  try {
    localStorage.setItem(AUTH_LOCAL_LOGIN_TYPE_STORAGE_KEY, isNotLoggedIn.toString())
  } catch (error) {
    console.error('AUTH LOCAL LOGIN STORAGE SAVE ERROR', error)
  }
  const value: string | null = localStorage.getItem(AUTH_LOCAL_LOGIN_TYPE_STORAGE_KEY)
  return value === 'true'
}
const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, getIsNotLoggedIn, setIsNotLoggedIn, AUTH_LOCAL_STORAGE_KEY}
