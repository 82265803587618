import { Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { initializeDb } from './utils/db'
import React from 'react'
export const HeaderContext = React.createContext<any>({});
const App = () => {
  const [header, setHeader] = useState<any>({});
  useEffect(() => {
    initializeDb();
  }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <HeaderContext.Provider value={{ header: header, setHeader: setHeader }}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      </HeaderContext.Provider>
    </Suspense>
  )
}

export {App}
