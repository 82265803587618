import * as Yup from 'yup'
export type Challenge = {
  heading: string
  presentationMode: string
  type: string
  _id?: string
}
export const challengeInit: Challenge = {
  heading: '',
  presentationMode: '',
  type: '',
}
export const tempQuestionTypeList = [
  { label: 'MCQ', value: 'mcq' },
  { label: 'MCQ with multiple answers', value: 'multiple' },
  { label: 'Numerical', value: 'numerical' },
  { label: 'Match', value: 'match' },
  { label: 'Assertion and Resoning', value: 'assertion' },
  { label: 'Paragraph Type', value: 'comprehension' },
]
export const challengeTypeToQuestionTypeMap: any = {
  'online': {
    'lesson': ["slide", "simulation", "mcq", "multiple", "numerical", "short", "long", "pair", "blanks", "matrix", "click", "crossword", "7lives"],
    'test': ["mcq", "multiple", "numerical", "short", "long", "blanks", "matrix", "click", "crossword", "7lives"],
    'assignment': ["mcq", "multiple", "numerical", "short", "long", "blanks", "pair", "matrix", "click", "crossword", "7lives"],
  },
  'offline': {
    'lesson': ["slide", "mcq", "multiple", "numerical", "short", "long", "blanks", "matrix"],
    'test': ["mcq", "multiple", "numerical", "short", "long", "blanks", "matrix"],
    'assignment': ["mcq", "multiple", "numerical", "short", "long", "blanks", "matrix"],
  }
}
export const challengeTypeToPresentTypeMap: any = {
  'online': {
    'lesson': [],
    'test': ['test'],
    'assignment': ['assignment'],
  },
  'offline': {
    'lesson': ['print'],
    'test': ['test'],
    'assignment': ['assignment'],
  }
}
export const challengeTypeToAddSectionNameMap: any = {
  'online': {
    'lesson': 'Add Content',
    'test': 'Add Question',
    'assignment': 'Add Question',
  },
  'offline': {
    'lesson': 'Add Content',
    'test': 'Add Question',
    'assignment': 'Add Question',
  }
}
export const challengeFilterInit: any = { "slide": true, "mcq": true, "multiple": true, "numerical": true, "short": true, "long": true, "pair": true, "blanks": true, "matrix": true, "click": true, "crossword": true, "7lives": true }
export function ChallengeValidationSchema() {
  let validationObject: any = {}

  validationObject = {
    heading: Yup.string().required().label('Module Name'),
    presentationMode: Yup.string().required().label('Presentation Mode'),
    type: Yup.string().required().label('Content Type'),
  }
  return Yup.object(validationObject);
}