import axios from "axios";
import { useEffect, useState } from "react";
import { DisplayLoader } from "../../utils/loader";

export const AllChallenges = () => {
  let [challenges, setChallenges] = useState<any>([]);
  let [institution, setInstitution] = useState<any>([]);
  useEffect(() => {
    DisplayLoader(true, '');
    axios.get(process.env.REACT_APP_API_URL + '/get-challenge-list/').then((reply) => {
      if (reply?.data?.challenges) {
        setChallenges(reply.data.challenges);
        axios.get(process.env.REACT_APP_API_URL + '/institution/').then((reply) => {
          if (reply?.data) {
            setInstitution({ name: reply.data.name, type: reply.data.profileDetails?.type });
          } else {
            //TODO
          }
          DisplayLoader(false, '')
        }, (error) => {
          DisplayLoader(false, '')
          //reject(error)//TODO
        });


      } else {
        //TODO
      }
    })
  }, [])
  return (
    <>
      {challenges?.map((challenge: any) => {
        return <a key={challenge._id} href={(institution?.type == 'competitive-india' ? '/create-test/' : '/create-module/') + challenge._id} className='card shadow p-5 mb-2'><h6>{challenge.heading}</h6></a>
      })}
    </>
  )
}