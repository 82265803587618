export function initializeDb() {
  const request = indexedDB.open("olearnoDb", 3); // "myDatabase" is the name of the DB, "1" is the version

  request.onupgradeneeded = function (event: any) {
    // Create an object store if it doesn't exist
    console.log("Database upgrading");
    var db = event.target.result;
    if (!db.objectStoreNames.contains("challenges")) {
      db.createObjectStore("challenges", { keyPath: "_id" });
    }
    if (!db.objectStoreNames.contains("sections")) {
      db.createObjectStore("sections", { keyPath: "_id" });
    }
    if (!db.objectStoreNames.contains("learnerData")) {
      db.createObjectStore("learnerData", { keyPath: "id" });
    }
    console.log("Database upgraded successfully");
  };

  request.onerror = function (event: any) {
    // Handle errors
    console.error("Database error: " + event.target.errorCode);
  };

  request.onsuccess = function (event) {
    // Database opened successfully
    console.log("Database opened successfully");
  };

}
export function setValuesInIndexedDB(storeName: string, values: any[]): Promise<string> {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open('olearnoDb');

    openRequest.onsuccess = function (event: any) {
      const db = event.target.result;
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      let completedOperations = 0;

      values.forEach((value: any) => {
        const request = store.put(value); // Use "put" for each value to add or update
        request.onsuccess = function () {
          //console.log("Value added to the object store:", value);
          completedOperations++;
          if (completedOperations === values.length) {
            resolve("All values have been successfully added.");
          }
        };
        request.onerror = function (event: any) {
          console.error("Error adding value to the object store:", event.target.error, value);
          reject("Error adding value to the object store: " + event.target.error);
        };
      });

      transaction.oncomplete = function() {
        resolve("Transaction completed.");
      };
      transaction.onerror = function(event: any) {
        reject("Transaction failed: " + event.target.error);
      };
    };

    openRequest.onerror = function (event: any) {
      console.error("Error opening database:", event.target.error);
      reject("Error opening database: " + event.target.error);
    };
  });
}

export function getItemFromIndexedDB(storeName: string, key: string) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('olearnoDb');

    request.onsuccess = function (event: any) {
      const db = event.target.result;
      const transaction = db.transaction(storeName);
      const objectStore = transaction.objectStore(storeName);
      const getRequest = objectStore.get(key); // Use the key to retrieve the item

      getRequest.onsuccess = function () {
        if (getRequest.result) {
          //console.log("Item retrieved:", getRequest.result);
          resolve(getRequest.result)
        } else {
          console.log("No item found with that key");
          reject()
        }
      };

      getRequest.onerror = function (event: any) {
        console.error("Error retrieving item:", event.target.error);
        reject()
      };
    };

    request.onerror = function (event: any) {
      console.error("Database error:", event.target.error);
    };
  })
}

