import axios from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DisplayLoader } from '../../utils/loader';
import { Challenge } from '../challenge/Challenge';
import { Form, Formik } from 'formik';
import { ViewSection } from '../../modules/components/display/ViewSection';
import showAttempt from '../../utils/showAttempt';
type ApiParams = { id: string };
const OfflineReportsQuestion: FC = () => {
  const { id } = useParams<ApiParams>();
  let [report, setReport] = useState<any>({});
  useEffect(() => {
    getReports();
  }, []);

  const getReports = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/get-offline-reports/' + id).then((reply) => {
      if (reply?.data) {
        if (reply.data.redirect) {
          window.location.pathname = reply.data.redirect;
        } else {
          setReport(reply.data);
        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <div className='rounded border border-dark p-2 pb-0 shadow-lg m-5'>
      {report.batchList && <div>
        <h2 className='text-center'>{report.batchList[0].name} - {report.userDetails[report.session.teacherId]}</h2>
        <div className='text-center'><strong>{new Date(report.session.startTime).toLocaleDateString('en-GB')}</strong></div>
      </div>}
      {report.batchList && <div className='pb-15'><Link to={'/class-reports-group/'+report.session._id} type='button' className='btn btn-dark p-2 m-2 float-end'><i className='fa fa-line-chart'></i> by Group/Student</Link></div>}
      <div className='row ms-2'>
        <strong className='row'>
          <div className='col-6'></div>
          <div className='col-3'>Correct</div>
          <div className='col-3'>Incorrect</div>
        </strong>
      </div>
      {
        report?.session?.showOrder.map((question: any, index: number) => {
          return <div className='shadow-lg p-3 m-2 mt-4 rounded'>
            <h2 className="accordion-header">
              <button className="btn btn-default text-start collapsed w-100" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="false" aria-controls={"collapse" + index}>
                <div className='row'>
                  <div className='col-6'>
                    Question No. {index + 1}
                  </div>
                  {(() => {
                    let challenge = report.challenges.filter((c: any) => c._id == report.challengeInstances.filter((ci: any) => ci._id == question.challengeInstanceId)[0].challengeId)[0]
                    let section = report.sections.filter((section: any) => section._id == challenge.sections[question.sectionNo])[0]
                    let correct = Object.entries(report.session.groupData).filter((entry: any) => {
                      return entry[1]?.[challenge._id]?.[section._id]?.correct == true
                    })
                    let wrong = Object.entries(report.session.groupData).filter((entry: any) => {
                      return entry[1]?.[challenge._id]?.[section._id]?.correct == false
                    })
                    return <><div className='col-3'>{correct.length}</div><div className='col-3'>{wrong.length}</div></>
                  })()}
                </div>
              </button>
            </h2>
            <div id={"collapse" + index} className="accordion-collapse collapse ms-1" aria-labelledby="" data-bs-parent="">
              {(() => {
                let challege = report.challenges.filter((c: any) => c._id == report.challengeInstances.filter((ci: any) => ci._id == question.challengeInstanceId)[0].challengeId)[0]
                let section = report.sections.filter((section: any) => section._id == challege.sections[question.sectionNo])[0]
                return <>
                  <div>
                    <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                      validationSchema={{}} initialValues={{}} onSubmit={() => { }}>
                      {({ errors, touched, values, setFieldValue, setErrors }) => (
                        <Form>
                          {ViewSection(section, true, 1, errors, values, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword' || section.type == 'dragdrop') ? { ...section, ...{ correct: true } } : ((section.type == 'short' || section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives') ? { ...section, ...{ correct: false } } : section)))}
                        </Form>)}</Formik>
                  </div>
                  {<>
                    {report && report.session && Object.entries(report.session.groupData).map((data: any, index: number) => {
                      let attempt = data[1]?.[challege._id]?.[section._id]
                      return <div className='border-bottom border-dark'><b><div>Answer Submitted by: 
                        <div>
                          {attempt && attempt.correct == false && <button className='btn btn-danger p-2 float-end'>Wrong</button>}
                          {attempt && attempt.correct == true && <button className='btn btn-success p-2 float-end'>Correct</button>}
                          {(!attempt || (attempt.correct != false && !attempt.correct)) && <span className='float-end text-danger'>Not-Attempted</span>}
                        </div>
                        </div><span className='col-9'>{data[1].students?.map((student: any) => {
                        return report.userDetails[student]
                      }).join(',')}</span></b> - {showAttempt(section, attempt)}</div>
                    })}

                  </>}

                </>


              })()}
            </div>
          </div>
        })
      }
    </div>
  )
}

export { OfflineReportsQuestion }
