import axios from "axios";
import * as Yup from 'yup'
import { Field, Form, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { DisplayLoader } from "../../utils/loader";
import { ErrorDisplay } from "../../modules/components/common/ErrorDisplay";
import { Link } from "react-router-dom";

export const BatchList = () => {
  let [batches, setBatches] = useState<any>([]);
  let [currentBatch, setCurrentBatch] = useState<any>({});
  let [success, setSuccess] = useState<string>('');
  let [error, setError] = useState<string>('');
  let validationSchema = Yup.object({
    name: Yup.string().required().label('Name'),
    users: Yup.string().required().label('Users')
      // .test(
      //   'needValidEmailList',
      //   'Enter comma separated list of of valid emails',
      //   function (items) {
      //     let isValid = true;
      //     items?.split(',').filter(item => item).forEach((item) => {
      //       if (!item.toLowerCase()
      //         .match(
      //           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //         )) isValid = false;
      //     })
      //     return isValid;
      //   }
      // ),
  });
  useEffect(() => {
    listBatches();
  }, [])
  const listBatches = () => {
    DisplayLoader(true, '')
    axios.get(process.env.REACT_APP_API_URL + '/batch-list/').then((reply) => {
      if (reply?.data) {
        setBatches(reply.data);
        DisplayLoader(false, '')
      } else {
        //TODO
      }
    })
  }
  const addBatch = (values: FormikValues) => {
    let input = { ...values }
    input.users = input.users.split(',').filter(function (item: string, pos: number, self: any) {
      return self.indexOf(item) === pos;
    })
    setError('')
    setSuccess('')
    DisplayLoader(true, '');
    (document.getElementById('addModalClose') as HTMLButtonElement).click();
    axios.post(process.env.REACT_APP_API_URL + '/add-batch/', input).then((reply) => {
      if (reply?.data) {
        listBatches();
        DisplayLoader(false, '')
      } else {
        DisplayLoader(false, '')
        setError('Unknown error while adding a batch. Please try again.');
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      setError('Unknown error while adding a batch. Please try again.');
      DisplayLoader(false, '')
    });
  }
  const updateBatch = (values: FormikValues) => {
    let input = { ...values }
    input.users = input.users.split(',').filter((item: any) => item).filter(function (item: string, pos: number, self: any) {
      return self.indexOf(item) === pos;
    })
    input._id = currentBatch._id;
    (document.getElementById('updateModalClose') as HTMLButtonElement).click();
    DisplayLoader(true, '')
    setError('')
    setSuccess('')
    axios.post(process.env.REACT_APP_API_URL + '/update-batch/', input).then((reply) => {
      if (reply?.data) {
        listBatches();
        DisplayLoader(false, '')
        setSuccess('Successfully updated the batch')
      } else {
        DisplayLoader(false, '')
        setError('Unknown error while updating the batch. Please try again.');
        //TODO
      }
    }, (error) => {
      //reject(error)//TODO
      setError('Unknown error while updating the batch. Please try again.');
      DisplayLoader(false, '')
    });
  }
  return (
    <div className=''>
      <h1 className="text-center">Batches</h1>
      <div className="text-end">
        <Link to='/create-module' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#addModal">Add a new Batch</Link>
      </div>
      {error && error !== '' && (
        <div className='mt-3 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>
            {error}
          </div>
        </div>
      )}
      {success && success !== '' && (
        <div className='mt-3 alert alert-success text-center'>
          <div className='alert-text font-weight-bold'>
            {success}
          </div>
        </div>
      )}
      <div className="text-center">
        {batches.map((batch: any) => {
          return <div className="p-3 m-4 border border-dark border-1 shadow rounded col-sm-5 col-md-3 col-12 d-inline-block"><div><h4>{batch.name}</h4></div>
            <Link to={"/batch-home/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-calendar p-0 me-3" ></i>Batch Home Page</Link><br />
            <Link to={"/timetable/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-calendar p-0 me-3" ></i>Time Table And Attendance</Link><br />
            <Link to={"/messages/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-circle-exclamation p-0 me-3" ></i>Notice Board</Link><br />
            <Link to={"/batch-users/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-users p-0 me-3" ></i>Batch Students</Link><br />
            <Link to={"/batch-teachers/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-users p-0 me-3" ></i>Batch Teachers</Link><br />
            <Link to={"/fees/" + batch._id} className="btn btn-info shadow p-2 m-2"><i className="fa fa-indian-rupee p-0 me-3" ></i>Fees</Link><br />
            {/*<a href={"/timetable/"+batch._id} className="btn btn-light shadow p-2 me-5" data-toggle="tooltip" data-placement="top" title="Timetable"><i className="fa fa-calendar p-0" ></i></a>
          <button className="btn btn-light shadow p-2 me-5" data-toggle="tooltip" data-placement="top" title="Monitor Attendance"><i className="fa fa-clipboard-user ps-1 pe-1" ></i></button>
          <a href={'/batch-reports/'+batch._id} className="btn btn-info shadow p-2 me-5" data-toggle="tooltip" data-placement="top" title="Reports"><i className="fa fa-chart-simple p-0" ></i></a>
          <button className="btn btn-info shadow p-2 me-5" data-toggle="tooltip" data-placement="top" title="Edit Batch" data-bs-toggle="modal" data-bs-target="#updateModal" onClick={() => { setCurrentBatch({ ...batch, ...{ users: batch.users.join(',') } }) }}><i className="fa fa-pencil p-0"></i></button>
          <Link to={"/batch-users/" + batch._id} className="btn btn-info shadow p-2 me-5" data-toggle="tooltip" data-placement="top" title="Batch Members"><i className="fa fa-users p-0" ></i></Link>
          */}
          </div>
        })}
      </div>
      <div className="modal fade" id="addModal" aria-labelledby="addModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">Add Batch</h5>
              <button id='addModalClose' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={{}} onSubmit={addBatch}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Name</label></div>
                    <div>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='name' placeholder='Name' />
                      {ErrorDisplay('name', errors)}
                    </div>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Emails</label></div>
                    <div>
                      <Field component="textarea" rows="4" className='form-control form-control-lg mb-1'
                        name='users' placeholder='Emails' />
                      {ErrorDisplay('users', errors)}
                    </div>
                    <button className="btn btn-primary float-right">Add</button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="updateModal" aria-labelledby="updateModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateModalLabel">Update Batch</h5>
              <button id='updateModalClose' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                validationSchema={validationSchema} initialValues={currentBatch} onSubmit={updateBatch}>
                {({ errors, touched, values, setFieldValue, setErrors }) => (
                  <Form>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Name</label></div>
                    <div>
                      <Field type='text' className='form-control form-control-lg mb-1'
                        name='name' placeholder='Name' />
                      {ErrorDisplay('name', errors)}
                    </div>
                    <div><label className='form-label fw-bolder text-dark fs-6'>Emails</label></div>
                    <div>
                      <Field component="textarea" rows="4" className='form-control form-control-lg mb-1'
                        name='users' placeholder='Emails' />
                      {ErrorDisplay('users', errors)}
                    </div>
                    <button className="btn btn-primary float-right">Save</button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}