import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { number } from 'yup';
import { useAuth } from '../../modules/auth';
import { Modal } from 'react-bootstrap';
import { getUserByToken } from '../../modules/auth/core/_requests';
import { systemSort } from '../../utils/systemSort';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import KaTeXRenderer from '../utils/KatexRenderer';
type ApiParams = { id: string; sectionNo: string };
const NTATest: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [isExpanded, setIsExpanded] = useState<boolean>(false);
  let [showAcceptInstructionsModal, setShowAcceptInstructionsModal] = useState<boolean>(false);
  let [pageStatus, setPageStatus] = useState<number>(0);
  const pageStatusRef = useRef(pageStatus);
  let [challenge, setChallenge] = useState<any>({ sections: [] });
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  let [section, setSection] = useState<any>({});
  let [showNameModal, setShowNameModal] = useState<boolean>(false);
  let [attempt, setAttempt] = useState<any>({});
  let [showAttemptCountModal, setShowAttemptCountModal] = useState<boolean>(false);
  let [attemptId, setAttemptId] = useState<any>('');
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(-1);
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  useEffect(() => {
    if (currentSectionNo !== -1) {
      if (document.getElementById('section')) {
        (document.getElementById('section') as HTMLElement).style.display = 'none'
      }
      if (document.getElementById('footer')) {
        (document.getElementById('footer') as HTMLElement).style.display = 'none'
      }
      getSection();
    }
  }, [currentSectionNo])
  useEffect(() => {
    pageStatusRef.current = pageStatus;
  }, [pageStatus]);
  useEffect(() => {
    if (sectionNo && sectionNo !== '') {
      setCurrentSectionNo(parseInt(sectionNo));
    } else {
      setCurrentSectionNo(0);
    }
    if (currentUser?.isNotLoggedIn && currentUser.name === currentUser.username) {
      setShowNameModal(true);
    }
  }, []);
  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event: any) => {
      event.preventDefault();
    };

    // Add event listener
    document.addEventListener('contextmenu', handleContextMenu);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  const updatePageStatus = () => {
    if (pageStatus == 0) {
      if ((document.getElementById('instructions_read') as HTMLInputElement).checked) {
        resetTimer(challenge.totalTime - challenge.timeUsed, attemptId, challenge.totalTime);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        setPageStatus(1);
      } else {
        setShowAcceptInstructionsModal(true)
      }
    }
  }
  const updateName = async () => {
    let name = (document.getElementById('name') as HTMLInputElement).value;
    const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/change-profile/', { id: currentUser?.id, name: name })
    saveAuth(auth)
    const { data: user } = await getUserByToken(auth.api_token)
    setCurrentUser(user)
    setShowNameModal(false);
  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, true));
  }
  const parseTime = (time: number) => {
    return pad(Math.floor(time / 3600)) + ':' + pad(Math.floor(time / 60) % 60) + ':' + pad(time % 60)
  }
  const pad = (inputNumber: number) => {
    return (inputNumber < 10 ? ('0' + inputNumber) : inputNumber)
  }
  const resetTimer = (time: number, attemptId: string, totalTime: number) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setSecondsLeft(time);
    const newIntervalId = setInterval(() => {
      if (window.location.href.indexOf('test') == -1) clearInterval(newIntervalId)
      if (pageStatusRef.current == 0) return
      setSecondsLeft(secondsLeft => {
        secondsLeft = (secondsLeft - 1 > 0 ? secondsLeft - 1 : 0)
        if ((secondsLeft % 3 === 0) && window.location.pathname.split('/')[1].indexOf('test') != -1) {
          updateTime(attemptId, secondsLeft, totalTime)
        };
        if (secondsLeft === 0) {
          finishAttempt();
        }
        return secondsLeft;
      });
    }, 1000);
    setIntervalId(newIntervalId);
  }
  const getSection = (shouldNotReload?: boolean) => {
    DisplayLoader(true, '')
    if (!shouldNotReload) setIsLoading(true);
    console.log("here")
    axios.post(process.env.REACT_APP_API_URL + '/get-section/',
      { challengeInstanceId: id, type: 'test', sectionPosition: currentSectionNo }).then((reply) => {
        console.log(reply.data)
        if (reply?.data) {
          if (reply.data.redirect) {
            window.location.pathname = reply.data.redirect;
          } else {
            if (reply.data.section?.type === 'pair') {
              reply.data.section.jumbledOptions = systemSort([...reply.data.section.options]);
            }
            console.log(reply.data)
            setChallenge(reply.data.challenge);
            setSection(reply.data.section);
            setAttempt(reply.data.sectionAttempt);
            setAttemptId(reply.data.attemptId);
            if (reply.data.sectionAttempt && !reply.data.sectionAttempt.answer && !(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple')) reply.data.sectionAttempt.answer = '';
            setInitValues(reply.data.sectionAttempt ? reply.data.sectionAttempt : { answer: (!(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple') ? '' : null) });
            updateValidationSchema(reply.data.section);
            if (window.location.pathname.split("/")[1].indexOf('test') != -1) {
              resetTimer(reply.data.challenge.totalTime - reply.data.challenge.timeUsed, reply.data.attemptId, reply.data.challenge.totalTime);
            }
            setTimeout(() => {
              if ((document.getElementById('totalPoints') as HTMLElement)) {
                (document.getElementById('totalPoints') as HTMLElement).classList.remove('animate__heartBeat');
                //(document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
                setTimeout(() => {
                  (document.getElementById('totalPoints') as HTMLElement).classList.add('animate__heartBeat');
                }, 100)
              }
            }, 100)
            setTimeout(() => {
              (document.getElementById('section') as HTMLElement).style.display = 'block';
              (document.getElementById('footer') as HTMLElement).style.display = 'block';
            }, 10)
          }
        } else {
          //TODO
        }
        setIsLoading(false);
        DisplayLoader(false, '')
      }, (error) => {
        console.log(error)
        setIsLoading(false);
        DisplayLoader(false, '')
        window.location.pathname = '/'
        //reject(error)//TODO
      }).catch((e) => {
        console.log(e)
      });
  }
  const updateTime = (attemptId: string, secondsLeft: number, totalTime: number) => {
    if (attemptId) {
      let input: any = { timeUsed: totalTime - secondsLeft }
      if (challenge?.sections?.[currentSectionNo]) {
        input.sectionId = challenge?.sections?.[currentSectionNo];
      }
      axios.post(process.env.REACT_APP_API_URL + '/update-time/' + attemptId, input).then((reply) => {
      }).catch(() => {
        //TODO
      })
    }
  }
  const clearAnswer = () => {

    axios.post(process.env.REACT_APP_API_URL + '/clear-answer/' + attemptId + '/' + section._id).then((reply) => {
      getSection(true);
    }).catch(() => {
      //TODO
    })

  }
  const checkAnswer = (values: FormikValues, goNext?: boolean, reviewFlag?: boolean) => {
    DisplayLoader(true, '')

    if (values.answer && challenge.extraDetails?.forTest == 'mains') {
      if ((currentSectionNo < 30 && currentSectionNo >= 20 &&
        challenge?.attemptSummary?.filter((attempt: any, index: number) => {
          return (index < 30 && index >= 20 && (attempt == 3 || attempt == 5))
        }).length >= 5) ||
        (currentSectionNo < 60 && currentSectionNo >= 50 &&
          challenge?.attemptSummary?.filter((attempt: any, index: number) => {
            return (index < 60 && index >= 50 && (attempt == 3 || attempt == 5))
          }).length >= 5) ||
        (currentSectionNo < 90 && currentSectionNo >= 80 &&
          challenge?.attemptSummary?.filter((attempt: any, index: number) => {
            return (index < 90 && index >= 80 && (attempt == 3 || attempt == 5))
          }).length >= 5)) {
        setShowAttemptCountModal(true)
        DisplayLoader(false, '')
        return
      }
    }

    axios.post(process.env.REACT_APP_API_URL + '/check-answer/' + attemptId + '/' + section._id, { ...values, ...{ reviewFlag: reviewFlag } }).then((reply) => {
      if (reply?.data) {
        if (reply.data.result === true) {
          (document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
          new Audio('/audio/win.mp3').play();
          setTimeout(() => {
            (document.getElementById('plusFive') as HTMLElement).style.display = 'none'
          }, 1000)
        } else if (reply.data.result === false) {
          new Audio('/audio/lose.mp3').play();
        }
        if (goNext && currentSectionNo < (challenge.totalSections - 1)) {
          setCurrentSectionNo(currentSectionNo + 1)
        } else {
          getSection(true);
        }
        if (reply.data.result !== null && document.getElementById('totalPoints')) {

        }
        //setChallenge(reply.data.challenge);
        //setSection(reply.data.section);
        //updateValidationSchema(reply.data.section);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const finishAttempt = () => {
    DisplayLoader(true, '')
    axios.post(process.env.REACT_APP_API_URL + '/finish-attempt/' + attemptId + '/' + section._id).then((reply) => {
      if (reply?.data?.completed) {
        window.location.pathname = '/reports/' + id;
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  const scrollDiv = (toTop: boolean) => {
    var div = document.getElementById("section") as HTMLElement;
    if (toTop) {
      div.scrollTop = 0
    } else {
      div.scrollTop = div.scrollHeight;
    }
  }
  return (
    <>
      {
        pageStatus == 0 && <div className="col-md-12 p-5" >
          <h1 className='text-center'>General Instructions</h1>
          <h4 className="text-center">Please read the instructions carefully</h4>
          <h4 id="section"><strong><u>General Instructions:</u></strong></h4>
          <ol>
            <li>
              <ul className="ulInstruction">
                <li>Total duration of {challenge.heading} is {Math.round(challenge.totalTime / 60)} min.</li>
              </ul>
            </li>
            <li>The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will not be required to end or submit your examination.</li>
            <li>
              The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols:
              <ol>
                <li><img src="/media/nta/Logo1.png" /> You have not visited the question yet.<br /><br /></li>
                <li><img src="/media/nta/Logo2.png" /> You have not answered the question.<br /><br /></li>
                <li><img src="/media/nta/Logo3.png" /> You have answered the question.<br /><br /></li>
                <li><img src="/media/nta/Logo4.png" /> You have NOT answered the question, but have marked the question for review.<br /><br /></li>
                <li><img src="/media/nta/Logo5.png" /> The question(s) "Answered and Marked for Review" will be considered for evalution.<br /><br /></li>
              </ol>
            </li>
            <li>You can click on the "&gt;" arrow which apperes to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on "&lt;" which appears on the right side of question window.</li>
            <li>You can click on your "Profile" image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.</li>
            <li>You can click on <img src="/media/nta/down.png" /> to navigate to the bottom and <img src="/media/nta/up.png" /> to navigate to top of the question are, without scrolling.</li>
          </ol>
          <h4><strong><u>Navigating to a Question:</u></strong></h4>
          <ol >
            <li>
              To answer a question, do the following:
              <ol type="a">
                <li>Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.</li>
                <li>Click on <strong>Save &amp; Next</strong> to save your answer for the current question and then go to the next question.</li>
                <li>Click on <strong>Mark for Review &amp; Next</strong> to save your answer for the current question, mark it for review, and then go to the next question.</li>
              </ol>
            </li>
          </ol>
          <h4><strong><u>Answering a Question:</u></strong></h4>
          <ol >
            <li>
              Procedure for answering a multiple choice type question:
              <ol type="a">
                <li>To select you answer, click on the button of one of the options.</li>
                <li>To deselect your chosen answer, click on the button of the chosen option again or click on the <strong>Clear Response</strong> button</li>
                <li>To change your chosen answer, click on the button of another option</li>
                <li>To save your answer, you MUST click on the Save &amp; Next button.</li>
                <li>To mark the question for review, click on the Mark for Review &amp; Next button.</li>
              </ol>
            </li>
            <li>To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</li>
          </ol>
          <h4><strong><u>Navigating through sections:</u></strong></h4>
          <ol >
            <li>Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by click on the section name. The section you are currently viewing is highlighted.</li>
            <li>After click the Save &amp; Next button on the last question for a section, you will automatically be taken to the first question of the next section.</li>
            <li>You can shuffle between sections and questions anything during the examination as per your convenience only during the time stipulated.</li>
            <li>Candidate can view the corresponding section summery as part of the legend that appears in every section above the question palette.</li>
          </ol>
          <hr />
          <span className="text-danger">Please note all questions will appear in your default language. This language can be changed for a particular question later on.</span>
          <hr />
          <label>
            <input type="checkbox" id="instructions_read" />&nbsp;&nbsp;I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare  that I am not in possession of / not wearing / not  carrying any prohibited gadget like mobile phone, bluetooth  devices  etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations
          </label>
          <hr />
          <div className="text-center" id='footer'>
            <button type='button' className="btn btn-success btn-block" onClick={updatePageStatus}>Proceed</button>
          </div>
        </div>
      }
      {
        pageStatus == 1 && <div className='border-top border-dark border-2 p-2 pb-0 ms-3 me-3 row bg-white' style={{ marginBottom: "100px" }}>
          <div className='col-12 mb-4'>
            <table className='' style={{ fontSize: '16px' }}>
              <tr style={{ height: '30px' }}>
                <td><span className='p-4'>Exam Name</span></td>
                <td> : <span style={{ color: '#f7931e', fontWeight: 'bold' }}>{challenge.heading}</span></td>
              </tr>
              <tr style={{ height: '30px' }}>
                <td><span className='p-4'>Remaining Time</span></td>
                <td> : <span className='p-2' style={{ color: 'white', fontWeight: 'bold', borderRadius: '10%', backgroundColor: '#0098DA' }}>{parseTime(secondsLeft)}</span></td>
              </tr>
            </table>
          </div>
          <div className={(isExpanded ? 'col-md-12' : 'col-md-8') + ' col-12 p-2'}>
            <div className='table table-reponsive'>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '100%' }}>
                      {section.type && !isLoading && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
                        validationSchema={validationSchema} initialValues={initValues} onSubmit={() => { }}>
                        {({ errors, touched, values, setFieldValue, setErrors }) => (
                          <Form >
                            <div id='section' style={{ height: '300px', overflow: 'auto' }}>
                              <button type='button' onClick={() => { scrollDiv(false) }} className='float-end' style={{ background: 'url(/media/nta/down.png)', backgroundSize: 'cover', width: '30px', height: '30px', border: 'none' }}></button>
                              <h2>Question {currentSectionNo + 1} :</h2>
                              <hr />
                              <div className=''>
                                <h4 style={{ fontWeight: 'normal' }}><KaTeXRenderer>{section.text ? section.text : ''}</KaTeXRenderer></h4>
                              </div>
                              {section.type == 'numerical' && <div className='p-2'>
                                <Field type='text' className='form-control form-control-lg m-1' name={'answer'} placeholder='Answer' />
                              </div>}
                              {section.type == 'mcq' && <>
                                <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
                                {section?.options?.map((option: any, index: number) => {
                                  return (
                                    <>
                                      <div className='row m-1 justify-content-center align-bottom rounded pt-4' key={index}>
                                        <label>
                                          <div className='col-11 d-inline-block'>{index + 1}. <KaTeXRenderer>{option || ''}</KaTeXRenderer></div>
                                        </label>
                                      </div>
                                    </>
                                  )
                                })}
                                <div className='mb-2' style={{ marginTop: '40px' }}>
                                  {section?.options?.map((option: any, index: number) => {
                                    return <span className='m-2' style={{ fontSize: '16px', display: 'inline-flex', alignItems: 'center' }}>
                                      <span style={{ marginTop: '5px' }}><Field type='radio' name='answer' value={index.toString()} style={{ height: "20px", width: "20px" }} /> </span>
                                      <span className='ms-1'>{(index + 1) + ')'}</span>
                                    </span>
                                  })}
                                </div>
                              </>}
                              {(section.type == 'multiple' || section.type == 'partialmultiple') && <>
                                <div><label className='form-label fw-bolder text-dark fs-6'>Options</label></div>
                                {section?.options?.map((option: any, index: number) => {
                                  return (
                                    <>
                                      <div className='row m-1 justify-content-center align-bottom rounded pt-4' key={index}>
                                        <label>
                                          <div className='col-11 d-inline-block'>{index + 1}. <KaTeXRenderer>{option || ''}</KaTeXRenderer></div>
                                        </label>
                                      </div>
                                    </>
                                  )
                                })}
                                <div className='mb-2' style={{ marginTop: '40px' }}>
                                  {section?.options?.map((option: any, index: number) => {
                                    return <span className='m-2' style={{ fontSize: '16px', display: 'inline-flex', alignItems: 'center' }}>
                                      <span style={{ marginTop: '5px' }}><Field type='checkbox' name='answer' value={index.toString()} style={{ height: "20px", width: "20px" }} /> </span>
                                      <span className='ms-1'>{(index + 1) + ')'}</span>
                                    </span>
                                  })}
                                </div>
                              </>}
                              <button type='button' onClick={() => { scrollDiv(true) }} className='float-end' style={{ background: 'url(/media/nta/up.png)', backgroundSize: 'cover', width: '30px', height: '30px', border: 'none' }}></button>
                            </div>
                            <div id='footer'>
                              <div className='text-center'>
                                <button type='button' className='btn-nta btn-success-nta m-1 border' onClick={() => { checkAnswer(values, true) }}>SAVE AND NEXT</button>
                                <button type='button' className='btn-nta btn-default-nta m-1 border' onClick={() => { clearAnswer() }}>CLEAR</button>
                                <button type='button' className='btn-nta btn-warning-nta m-1 border' onClick={() => { checkAnswer(values, false, true) }}>SAVE & MARK FOR REVIEW</button>
                                <button type='button' className='btn-nta btn-primary-nta m-1 border' onClick={() => { checkAnswer(values, true, true) }}>MARK FOR REVIEW & NEXT</button>
                              </div>
                              <div className='bg-light p-3 border border-top'>
                                <button type='button' className='btn-nta btn-default-nta m-1 border' disabled={currentSectionNo == 0} onClick={() => { setCurrentSectionNo(currentSectionNo - 1) }}> &lt;&lt; BACK </button>
                                <button type='button' className='btn-nta btn-default-nta m-1 border' disabled={currentSectionNo == (challenge.totalSections - 1)} onClick={() => { setCurrentSectionNo(currentSectionNo + 1) }}>NEXT &gt;&gt;</button>
                                <button type='button' className='btn-nta btn-success-nta m-1 border float-end' onClick={() => { setPageStatus(2) }}>SUBMIT</button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>}
                    </td>
                    <td>
                      <div className="full_screen pull-right" style={{ cursor: 'pointer', backgroundColor: '#000', color: '#fff', padding: '5px' }} onClick={() => { setIsExpanded(!isExpanded) }}>
                        {!isExpanded && <i className="fa fa-angle-right fa-2x"></i>}
                        {isExpanded && <i className="fa fa-angle-left fa-2x"></i>}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div className='col-md-4 col-12'>
            <div className="panel panel-body panel-default mb0 mt10 p-4" style={{ border: 'dotted' }}>
              <div className="table-responsive">
                <table className="table table-borderless mb0">
                  <thead>
                    <tr>
                      <td className="full-width"> <a className="p-2 text-dark" style={{ background: 'url(/media/nta/Logo1.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>87</a></td>
                      <td>Not Visited</td>
                      <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo2.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>31</a> </td>
                      <td>Not Answered</td>
                    </tr>
                    <tr>
                      <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo3.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>23</a>  </td>
                      <td>Answered</td>
                      <td className="full-width"> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo4.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>12</a>  </td>
                      <td>Marked for Review</td>
                    </tr>
                    <tr>
                      <td className='full-width'> <a className="p-2 text-light" style={{ background: 'url(/media/nta/Logo5.png)', backgroundSize: '100% 100%', fontSize: '16px' }}>17</a>  </td>
                      <td colSpan={3}>Answered &amp; Marked for Review (will be considered for evaluation)</td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="panel panel-body panel-default mb0 mt10 p-4" >
              {
                challenge?.attemptSummary?.map((attempt: any, index: number) => {
                  return <button onClick={() => { setCurrentSectionNo(index) }} className={"p-2 m-1 text-" + (attempt == 1 ? 'dark' : 'light')} style={{ width: '40px', height: '40px', backgroundImage: 'url(/media/nta/Logo' + attempt + '.png)', backgroundColor: 'white', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
                  // switch (attempt) {
                  //   case 2:

                  //   case 3:
                  //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo3.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
                  //   case 4:
                  //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo4.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
                  //   case 5:
                  //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-light" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo5.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
                  //   default:
                  //     return <button onClick={()=>{setCurrentSectionNo(index)}} className="p-2 m-1 text-dark" style={{ width: '50px', height: '50px', background: 'url(/media/nta/Logo1.png)', backgroundSize: '100% 100%', fontSize: '16px', border: 'none' }}>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</button>
                  // }
                })
              }
            </div>
          </div>
        </div>
      }
      {
        pageStatus == 2 && <div>
          <div className='col-12 mb-4'>
            <table className='' style={{ fontSize: '16px' }}>
              <tr style={{ height: '30px' }}>
                <td><span className='p-4'>Exam Name</span></td>
                <td> : <span style={{ color: '#f7931e', fontWeight: 'bold' }}>{challenge.heading}</span></td>
              </tr>
              <tr style={{ height: '30px' }}>
                <td><span className='p-4'>Remaining Time</span></td>
                <td> : <span className='p-2' style={{ color: 'white', fontWeight: 'bold', borderRadius: '10%', backgroundColor: '#0098DA' }}>{parseTime(secondsLeft)}</span></td>
              </tr>
            </table>
          </div>
          <div className='m-5 p-5'>
            <h1 className='text-center mb-2'>Exam Summary</h1>
            <div className='m-10'>
              <table className='table table-responsive table-bordered border border-dark' style={{ fontSize: '16px' }}>
                <thead>
                  <tr>
                    <td><b>No of Questions</b></td>
                    <td><b>Answered</b></td>
                    <td><b>Not Answered</b></td>
                    <td><b>Marked for Review</b></td>
                    <td><b>Answered & Marked for Review (will be considered for evaluation)</b></td>
                    <td><b>Not Visited</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='text-center'>{challenge.totalSections}</td>
                    <td className='text-center'>{challenge?.attemptSummary?.filter((attempt: any, index: number) => {
                      return (attempt == 3)
                    }).length}</td>
                    <td className='text-center'>{challenge?.attemptSummary?.filter((attempt: any, index: number) => {
                      return (attempt == 2)
                    }).length}</td>
                    <td className='text-center'>{challenge?.attemptSummary?.filter((attempt: any, index: number) => {
                      return (attempt == 4)
                    }).length}</td>
                    <td className='text-center'>{challenge?.attemptSummary?.filter((attempt: any, index: number) => {
                      return (attempt == 5)
                    }).length}</td>
                    <td className='text-center'>{challenge?.attemptSummary?.filter((attempt: any, index: number) => {
                      return (attempt == 1)
                    }).length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='text-center mt-5'>
              <h2 className='mb-5'>Are you sure you want to submit for final marking?
                <br />
                No changes will be allowed after submission.</h2>
              <button className='btn btn-default-nta me-2' style={{ border: '1px solid black' }} onClick={() => { finishAttempt() }}>YES</button>
              <button className='btn btn-default-nta' onClick={() => { setPageStatus(1) }} style={{ border: '1px solid black' }}>NO</button>
            </div>
          </div>
        </div>
      }
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showAttemptCountModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowAttemptCountModal(false) }}>
        <div className='modal-content'>
          <div className="modal-body">
            <div className='text-center'>
              <i className="fa fa-exclamation-circle text-warning" aria-hidden="true" style={{ fontSize: '50px' }}></i>
            </div>
            <h1 className='m-5 text-center'>Warning!</h1>
            <div className='text-center mb-5' style={{ fontSize: '20px' }}>You can only answer five questions in this section. If you'd like to answer this question, you'll need to clear a previous answer from a different question from the section.</div>
            <div className='text-center'>
              <button className='btn btn-primary' onClick={() => { setShowAttemptCountModal(false) }}>OK</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className='modal fade' id='kt_modal_select_location' data-backdrop='static' tabIndex={-1} role='dialog'
        show={showAcceptInstructionsModal} dialogClassName='modal-xl' aria-hidden='true' onHide={() => { setShowAcceptInstructionsModal(false) }}>
        <div className='modal-content'>
          <div className="modal-body">
            <div className='text-center'>
              <i className="fa fa-exclamation-circle text-warning" aria-hidden="true" style={{ fontSize: '50px' }}></i>
            </div>
            <h1 className='m-5 text-center'>Warning!</h1>
            <div className='text-center mb-5' style={{ fontSize: '20px' }}>Please accept terms and conditions before proceeding.</div>
            <div className='text-center'>
              <button className='btn btn-primary' onClick={() => { setShowAcceptInstructionsModal(false) }}>OK</button>
            </div>
          </div>
        </div>
      </Modal>
    </>

  )
}

export { NTATest }
