import { Field, Form, Formik } from "formik"
import * as Yup from 'yup'
import { ErrorDisplay } from "../../modules/components/common/ErrorDisplay"

export const Registration = (updateRegistrationValues: any) => {
    let validationSchema = Yup.object({
        name: Yup.string().required("This field is required"),
        schoolname: Yup.string().required("This field is required"),
        combination: Yup.string().required("This field is required"),
        mathmarks: Yup.number().required("This field is required"),
        sciencemarks: Yup.number().required("This field is required"),
        fathername: Yup.string().required("This field is required"),
        fathercontact: Yup.string().required("This field is required"),
        mothername: Yup.string().required("This field is required"),
        mothercontact: Yup.string().required("This field is required"),
        address: Yup.string().required("This field is required"),
    })
    return <div >
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
            validationSchema={validationSchema} initialValues={{}} onSubmit={(values)=>{console.log("values");updateRegistrationValues(values)}}>
            {({ errors, touched, values, setFieldValue, setErrors }) => (
                <Form >
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Full Name</label>
                        <Field type='text' name='name' className='form-control form-control mb-1' />
                        {ErrorDisplay('name', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>School Name</label>
                        <Field type='text' name='schoolname' className='form-control form-control mb-1' />
                        {ErrorDisplay('schoolname', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Combination</label>
                        <Field as='select' name='combination' className='form-control form-control mb-1' >
                            <option value={''}>--Select--</option>
                            <option value={'pcmb'}>PCMB</option>
                            <option value={'pcmc'}>PCMC</option>
                            <option value={'pcms'}>PCMS</option>
                        </Field>
                        {ErrorDisplay('combination', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Marks in Maths (Mid Term)</label>
                        <Field type='number' name='mathmarks' className='form-control form-control mb-1' />
                        {ErrorDisplay('mathmarks', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Marks in Science (Mid Term)</label>
                        <Field type='number' name='sciencemarks' className='form-control form-control mb-1' />
                        {ErrorDisplay('sciencemarks', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Father's Name</label>
                        <Field type='text' name='fathername' className='form-control form-control mb-1' />
                        {ErrorDisplay('fathername', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Father's contact no.</label>
                        <Field type='text' name='fathercontact' className='form-control form-control mb-1' />
                        {ErrorDisplay('fathercontact', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Mother's Name</label>
                        <Field type='text' name='mothername' className='form-control form-control mb-1' />
                        {ErrorDisplay('mothername', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Mother's contact no.</label>
                        <Field type='text' name='mothercontact' className='form-control form-control mb-1' />
                        {ErrorDisplay('mothercontact', errors)}
                    </div>
                    <div>
                        <label className='form-label fw-bolder text-dark fs-6'>Address</label>
                        <Field as='textarea' name='address' className='form-control form-control mb-1' />
                        {ErrorDisplay('address', errors)}
                    </div>
                    <button className='btn btn-primary float-end'>Submit</button>
                </Form>)}
        </Formik>
    </div>
}